import { css } from "@emotion/css";
import { useQuery } from "@tanstack/react-query";
import clsx from "clsx";
import { useAtom } from "jotai";
import { cloneDeep } from "lodash";
import { ArrowRight, BookAIcon, CopyIcon, PencilRulerIcon, SearchIcon, XIcon } from "lucide-react";
import Head from "next/head";
import Link from "next/link";
import { useRouter } from "next/router";
import { useCallback, useMemo, useRef, useState } from "react";
import { type z } from "zod";

import { type SingleAppInfoResDTO } from "~/client/types.gen";
import { QUERY_KEYS } from "~/constants/keys";
import { type ZActionItem, type ZTriggerItem } from "~/dataProcessor/api/api";
import { getAppInfo } from "~/dataProcessor/api/apps";
import { UpdateQuery, UpdateQueryBatch } from "~/dataProcessor/hooks/global/router";
import { useApiKey } from "~/dataProcessor/hooks/user";
import { Button } from "~/design-system/atom/button";
import { Input } from "~/design-system/atom/input";
import { Link as ComposioLink } from "~/design-system/atom/link";
import { ToolTipBox } from "~/design-system/atom/tooltip";
import { useGetAllIntegrations } from "~/hooks/query/tools";
import { ErrorBoundary } from "~/ui/components/base/errorBoundary";
import { LoadingIcon } from "~/ui/components/base/loadingIcon";
import { TabBarStrip } from "~/ui/components/custom/TabBarStrip";
import { Table } from "~/ui/components/custom/Table";
import { SimpleDropdown } from "~/ui/components/custom/dropdown/simple";
import { Error, LoadingState } from "~/ui/containers/base/emptyStates";
import { CodeWInstructionModal, CodeWithInstructionBlock } from "~/ui/containers/codeModal";
import { copyText } from "~/utils/dom";
import { getDuration } from "~/utils/duration";
import { If } from "~/utils/reactComponent";
import { formatActionName, upperCaseAll } from "~/utils/string";

import { useGetAppActions, useGetAppTriggers } from "~/hooks/query/apps";
import { useGetAllApps } from "~/hooks/query/tools";
import { useTriggerOrActionFuseSearch } from "../../../dataProcessor/hooks/useTriggerOrActionFuseSearch";
import { RightSideContent } from "../../containers/base/base";
import { CodeButton } from "../integrationPage";
import LiveToolsList from "../integrations/index";
import { TriggerPopup } from "../triggers/setupPopup";
import { openActionAction, openConnectorSetupAtom } from "./modules/createIntegration/atom";
import { E2EFlowWizard } from "./modules/wizard";

const useAppDataFromAllApps = (appId: string, suspense = false) => {
    const {
        data: { items: appsData = [] },
        isLoading,
        isError,
        isSuccess,
    } = useGetAllApps({
        page: 1,
        suspense: suspense,
    });

    const appData = useMemo(() => appsData.find((app) => app.key === appId), [appsData, appId]);

    return {
        appData,
        isLoading,
        isError,
        isSuccess,
    };
};

export const useAppInfo = (key: string) => {
    const {
        data: appInfo,
        isLoading: isAppInfoLoading,
        isError,
    } = useQuery({
        queryKey: QUERY_KEYS.getAppScreenQueryKey(key),
        queryFn: () =>
            getAppInfo({
                appKey: key as string,
            }),
        staleTime: getDuration({
            days: 1,
        }),
    });

    return {
        appInfo,
        isAppInfoLoading,
        isError,
    };
};

export default function AppScreen({ appId }: { appId: string }) {
    const { appData, isLoading, isError } = useAppDataFromAllApps(appId, false);
    const isLocal = Array.isArray(appData?.categories)
        ? appData?.categories.includes("local")
        : (appData?.categories || "")
              .split(",")
              .map((cat) => cat.trim())
              .includes("local");

    const { data: { items = [] } = {} } = useGetAllIntegrations(1, appData?.key);

    const { initialTab } = useRouter().query;
    const sections = ["overview", ...(items.length > 0 ? ["integrations"] : [])];

    const indexToHave = initialTab ? sections.findIndex((section) => section === initialTab) : 0;
    const [selectedSection, setSelectedSection] = useState(sections[indexToHave]);
    const isAppDetailsNotAvailable = appData?.name === undefined;

    if (isLoading)
        return (
            <>
                <RightSideContent>
                    <LoadingState height={window.innerHeight - 200} text="Fetching tool..." />;
                </RightSideContent>
            </>
        );

    if (isError || isAppDetailsNotAvailable) {
        return (
            <RightSideContent>
                <Error
                    height={window.innerHeight - 200}
                    headerText="We couldn't load this app"
                    subText="Please check if you have access to this app or refresh the page"
                />
            </RightSideContent>
        );
    }

    return (
        <>
            <Head>
                <title>{appData?.name}</title>
            </Head>

            <RightSideContent
                header={
                    <div className="w-full">
                        <span className="min-w-[120px]">{appData?.name.charAt(0).toUpperCase() + appData?.name.slice(1)}</span>
                    </div>
                }
            >
                <div className={"w-[calc(100% - 32px)] px-8 py-8 "}>
                    <TopBar
                        appData={appData}
                        onConnect={() => {
                            setSelectedSection("New Integration");
                        }}
                    />
                    <TabBarStrip
                        sections={sections}
                        selectedSection={selectedSection!}
                        setSelectedSection={setSelectedSection}
                        className="mt-6"
                    />

                    {selectedSection === "overview" ? <OverviewSection isLocal={isLocal} appName={appData.key as string} /> : null}
                    {selectedSection === "integrations" ? (
                        <div className="h-full pt-2">
                            <LiveToolsList appName={appData?.key} />
                        </div>
                    ) : null}
                </div>
            </RightSideContent>
        </>
    );
}

export const OverviewSection = ({ appName, isLocal }: { appName: string; isLocal: boolean }) => {
    const { usecase = null } = useRouter().query;
    const {
        actionsData = {
            items: [],
        },
        isActionsLoading,
    } = useGetAppActions(appName, usecase as string);

    const { triggersData = [], isTriggerLoading } = useGetAppTriggers(appName);

    const actions = actionsData.items;
    const { appInfo, isAppInfoLoading } = useAppInfo(appName);
    const isLoading = isActionsLoading || isTriggerLoading;
    const isError = isActionsLoading || isTriggerLoading;
    const isSuccess = !isActionsLoading && !isTriggerLoading;

    const status = isLoading ? "loading" : isError ? "error" : isSuccess ? "success" : "loading";

    const [, setOpenSetup] = useAtom(openConnectorSetupAtom);

    const isNoAuth = appInfo?.no_auth;

    const isCustomApp = appInfo?.categories?.includes("custom");

    const isAuthNotAvailable = appInfo?.auth_schemes?.length === 0 && !isAppInfoLoading;
    return (
        <>
            <div className="mt-7 flex justify-between ">
                <div>
                    <If condition={!isAuthNotAvailable}>
                        <div className="w-[200px] max-w-[600px] ">
                            <div className="text-[14px] font-[600]  text-black-200">Authentication supported</div>
                            <div className="mt-4 flex gap-2  text-[14px] font-[400] text-black-800">
                                {appInfo?.auth_schemes?.map((auth: any, index: number) => (
                                    <Pill
                                        key={index}
                                        status={auth.auth_mode}
                                        text={auth.auth_mode.replaceAll("_", " ").replaceAll("-", " ")}
                                    />
                                ))}
                            </div>
                        </div>
                    </If>
                </div>
                <div className="ml-2 w-full max-w-[400px]">
                    <div className="text-[14px] font-[600] text-black-200">Category</div>
                    <div className="mt-4 flex w-full flex-wrap gap-4">
                        {appInfo?.categories
                            ?.slice(0, 3)
                            .map((category) => <Pill status={category.toLowerCase()} text={category} key={category} />)}
                    </div>
                </div>
                <div>
                    <If condition={!isNoAuth}>
                        <div className="w-[320px] max-w-[320px]">
                            <div
                                className="flex  w-[320px] cursor-pointer items-start justify-between rounded-[14px] border-[2px] border-[#5712a5] bg-[#8a22ff] p-[10px] px-4 text-black-300"
                                onClick={() => {
                                    setOpenSetup(true);
                                }}
                            >
                                <div className="flex gap-2">
                                    <PencilRulerIcon height={14} width={14} color="#fff" strokeWidth={2} className="mt-[6px]" />{" "}
                                    <div>
                                        <div className="font-[600] text-[white]">Setup {formatActionName(appName)} integration</div>
                                        <div className="text-[13px] font-[400] text-[white]">
                                            {/* @ts-ignore */}
                                            Get {actions?.length} actions for connected account
                                        </div>
                                    </div>
                                </div>
                                <ArrowRight height={20} width={20} color="#fff" strokeWidth={2} className="mt-[4px]" />
                            </div>
                        </div>
                    </If>
                </div>
            </div>
            <ErrorBoundary fallback={<div>{`Something went wrong. We'll work on getting this fixed.`}</div>}>
                <ActionTable
                    // @ts-ignore
                    isCustomApp={isCustomApp}
                    isLocal={isLocal}
                    // @ts-ignore
                    actions={actions}
                    triggers={triggersData || []}
                    appInfo={appInfo!}
                    status={status}
                />
            </ErrorBoundary>
        </>
    );
};

export const ActionTable = ({
    isCustomApp = false,
    actions = [],
    triggers = [],
    appInfo = {} as SingleAppInfoResDTO,
    status,
    isLocal,
}: {
    actions: z.infer<typeof ZActionItem>[];
    triggers: z.infer<typeof ZTriggerItem>[];
    appInfo: SingleAppInfoResDTO;
    status: "loading" | "error" | "success";
    isLocal: boolean;
    isCustomApp: boolean;
}) => {
    const router = useRouter();
    const { search, tag, usecase } = router.query;
    const updateQueryFn = UpdateQuery();

    const headerRenderer = useCallback(() => {
        const tableHeaders = ["Name", "Enum", "Description", "type", ""];

        return tableHeaders.map((header, index) => (
            <div
                key={index}
                className={clsx("font-gilroy text-[12px] font-[500]", index === tableHeaders.length - 1 ? "flex justify-end pr-4" : "")}
            >
                {header}
            </div>
        ));
    }, []);

    const rowRenderer = useCallback(
        (row: z.infer<typeof ZActionItem> | z.infer<typeof ZTriggerItem>) => {
            return <TriggerNActionRow row={row} isNoAuth={appInfo?.no_auth || false} isLocal={isLocal} appName={appInfo.key} />;
        },
        [appInfo?.no_auth],
    );

    const axtionNTriggersData = useMemo(() => {
        return [
            ...(usecase ? [] : triggers.map((trigger) => ({ ...trigger, type: "trigger" }))),
            ...actions.map((action) => ({ ...action, type: "action" })),
        ] as (z.infer<typeof ZActionItem> | z.infer<typeof ZTriggerItem>)[];
    }, [actions, triggers]);

    const listOfTags = useMemo(() => {
        if (axtionNTriggersData.length === 0) return [];
        // @ts-ignore
        const tags = [];
        axtionNTriggersData.forEach((item) => {
            // @ts-ignore
            if (item.tags) {
                // @ts-ignore
                for (const tag of item.tags) {
                    tags.push(tag);
                }
            }
        });

        // @ts-ignore
        return [{ name: "All", value: "all" }, ...Array.from(new Set(tags)).map((tag) => ({ name: tag, value: tag }))];
    }, [axtionNTriggersData]);

    const { searchData } = useTriggerOrActionFuseSearch({ dataToSearch: axtionNTriggersData });

    const data = useMemo(() => {
        let data = [...axtionNTriggersData];

        if (search) {
            data = searchData(search as string);
        }

        if (!!tag) {
            // @ts-expect-error
            data = data.filter((item) => item?.tags?.includes(tag));
        }

        return data;
    }, [actions, triggers, search, tag]);

    const [, setOpenAction] = useAtom(openActionAction);

    const [showCode, setShowCode] = useState(false);

    return (
        <div>
            {showCode && <ShowCustomActionCode setOpen={() => setShowCode(false)} appName={appInfo.key} />}
            <div className="mb-[-6px] mt-10  flex w-full items-start justify-between text-black-200">
                <div className="text-[14px] font-[600] ">
                    <div>
                        <div>Actions & triggers - {Number(actions.length) + Number(triggers.length)}</div>
                        <div className="mt-1 text-[14px] font-[400] text-black-700">
                            List of actions and triggers available for this app
                        </div>
                    </div>
                </div>
                <div className={"flex flex-col"}>
                    <div className={clsx("flex  gap-2", listOfTags.length > 0 ? "w-[550px]" : "w-[450px]")}>
                        <CodeButton variant="black" setShowCode={() => setShowCode(true)} text="Add custom action" />

                        <If condition={listOfTags.length > 0}>
                            <SimpleDropdown
                                options={listOfTags}
                                onChange={(value) => {
                                    if (value === "all") {
                                        updateQueryFn("tag", "");
                                    } else {
                                        updateQueryFn("tag", value as string);
                                    }
                                }}
                                value={(tag as unknown as string) || ""}
                                placeholder="Tag"
                            />
                        </If>

                        <SearchBox isCustomApp={isCustomApp} />
                    </div>
                </div>
            </div>
            <Table
                gridCSS={gridBox}
                rows={data}
                state={status}
                noResultText="No actions or triggers found"
                headerRenderer={headerRenderer}
                rowRenderer={rowRenderer}
                rowContainerHeight={window.innerHeight - 274}
                rowHeight={50}
                onRowClick={(row) => {
                    setOpenAction(row.name);
                }}
                noResultAction={() => setShowCode(true)}
            />
        </div>
    );
};

export const SearchBox = ({ isCustomApp = false }: { isCustomApp: boolean }) => {
    const router = useRouter();
    const { search, usecase } = router.query;
    const finalSearch = search || usecase;
    const [tempSearch, setTempSearch] = useState(finalSearch);
    const inputRef = useRef<HTMLInputElement>(null);
    const updateQueryBatch = UpdateQueryBatch();

    return (
        <div className="relative w-full">
            <Input
                ref={inputRef}
                placeholder="Search actions or triggers"
                type="text"
                size={32}
                onChange={(e) => {
                    setTempSearch(e.target.value);
                }}
                onKeyDown={(e) => {
                    if (e.key === "Enter") {
                        if (tempSearch === "") {
                            updateQueryBatch({ search: "", usecase: "" });
                        } else {
                            if (isCustomApp) {
                                updateQueryBatch({ search: tempSearch as string, usecase: "", tag: "" });
                            } else {
                                updateQueryBatch({ search: "", usecase: tempSearch as string, tag: "" });
                            }
                        }
                    }
                }}
                rightIcon={
                    <div className="flex">
                        {!!finalSearch && finalSearch?.length > 0 ? (
                            <XIcon
                                height={16}
                                width={16}
                                color="grey"
                                className="cursor-pointer"
                                onClick={() => {
                                    if (inputRef.current) {
                                        inputRef.current.value = "";
                                    }
                                    updateQueryBatch({ search: "", usecase: "" });
                                }}
                            />
                        ) : null}
                    </div>
                }
                defaultValue={finalSearch || ""}
                icon={<SearchIcon height={14} width={14} color="grey" className="ml-[-3px]" />}
            />
        </div>
    );
};

export const TriggerNActionRow = ({
    row,
    appName,
    isLocal = false,
}: {
    row: z.infer<typeof ZActionItem> | z.infer<typeof ZTriggerItem>;
    isNoAuth: boolean;
    isLocal: boolean;
    appName: string;
}) => {
    const isAction = row.type === "action";
    const [openAction, setOpenAction] = useAtom(openActionAction);
    const [isCopied, setIsCopied] = useState(false);
    const [loadingStatus, setLoadingStatus] = useState(null as unknown as string);
    const isOpen = openAction === row.name;
    return (
        <>
            {isOpen && isAction && row.enum !== "custom_action" && (
                <E2EFlowWizard
                    isLocal={isLocal}
                    initialData={{
                        currentStep: 3,
                        first: {
                            appName: appName,
                        },
                        second: {
                            integrationID: null,
                        },
                        third: {
                            connectedAccountId: null,
                            actionName: row.enum as string,
                        },
                    }}
                    setOpen={() => {
                        setOpenAction(null);
                    }}
                />
            )}

            {isOpen && !isAction && (
                <TriggerPopup
                    setOpen={() => {
                        setOpenAction(null);
                    }}
                    triggerName={row.enum}
                    setLoadingStatus={setLoadingStatus}
                />
            )}
            <div className="h-[20px] max-w-[full] overflow-hidden text-[14px] font-[500] text-black-600" title={row.display_name || ""}>
                {formatActionName(row.display_name || "")}
            </div>
            <div
                className="w-fit text-[13px]"
                onClick={(e) => {
                    e.stopPropagation();
                }}
            >
                <ToolTipBox
                    align="center"
                    side="right"
                    content={isCopied ? "✅ Copied " + upperCaseAll(row.name) : "Copy " + upperCaseAll(row.name)}
                    onOpenChange={(e) => {
                        if (e === false) {
                            setIsCopied(false);
                        }
                    }}
                >
                    <span
                        className="flex cursor-pointer items-center gap-2 text-[12px]"
                        onClick={() => {
                            copyText(upperCaseAll(row?.name)!);
                            setIsCopied(true);
                        }}
                    >
                        Enum
                        <CopyIcon height={12} width={12} strokeWidth={1.6} />
                    </span>
                </ToolTipBox>
            </div>
            <div className="h-[20px] max-w-[full] overflow-hidden text-[13px]" title={row.description || ""}>
                {/* @ts-ignore */}
                {row.description?.length > 72 ? `${row.description?.substring(0, 72)}...` : row.description}
            </div>
            <div>
                {isAction ? (
                    <div className="flex items-center justify-center rounded-[40px] border border-[#9551b5] bg-[#f1d6ff5c] px-[4px] py-[5px] text-[11px] font-[500] leading-none text-[#582470]">
                        Action
                    </div>
                ) : (
                    <div className="flex items-center justify-center rounded-[40px] border border-[#5db551] bg-[#f6fff8] px-[4px] py-[5px] text-[11px] font-[500] leading-none text-[#126f1f]">
                        Trigger
                    </div>
                )}
            </div>
            <div className="flex items-center justify-end gap-1 pr-3">
                <Button
                    size={28}
                    variant={"plain"}
                    className=" mt-[auto] w-[fit-content] min-w-[60px] rounded-[10px] border-[.5px] border-[#D5D5D5] !bg-[#FBFBFB] text-[13px] font-[400] tracking-[.3px] text-black-200"
                >
                    {loadingStatus === "loading" ? (
                        <LoadingIcon height={12} width={12} />
                    ) : (
                        <div
                            className="mt-[2px] text-[13px] font-[500]"
                            onClick={(e) => {
                                e.stopPropagation();
                                setOpenAction(row.name);
                            }}
                        >
                            Open
                        </div>
                    )}
                </Button>
            </div>
        </>
    );
};

export const sampleCodeBlockData = {
    content: {
        python: [
            {
                type: "blockWizard",
                content: {
                    heading: "Code for creating a connection for a user",
                    blocks: [
                        {
                            heading: "Import libraries",
                            text: `import typing as t
from composio_openai import ComposioToolSet, action, Action
from openai import OpenAI

openai_client = OpenAI()
toolset = ComposioToolSet()`,
                            language: "python",
                        },
                        {
                            heading: "Define action",
                            text: `@action(toolname="{{tool_name}}")
def my_custom_action(
    param_1: str,
    param_2: str,
    execute_request: t.Callable,
) -> dict:
    """
    Action Description

    :param param_1: Parameter 1 description
    :param param_2: Parameter 2 description
    :return content: Return content description
    """
    response = execute_request(
        "/user", # or any other endpoint path
        "GET", # or any other method
        None, # request body
        None, # custom authentication parameters
    )
    return response
`,
                            language: "python",
                        },
                        {
                            heading: "Execute Action",
                            text: `tools = toolset.get_tools(actions=[my_custom_action])

task = "Define what you want the agent to do"

response = openai_client.chat.completions.create(
model="gpt-4o",
tools=tools,
messages=
    [
        {"role": "system", "content": "You are a helpful agent."},
        {"role": "user", "content": task},
    ],
)

result = toolset.handle_tool_calls(response)
print(result)
`,
                            language: "python",
                        },
                    ],
                },
            },
            {
                type: "content",
                content: (
                    <div className="mt-6 text-[15px] font-[400]">
                        Visit{" "}
                        <ComposioLink
                            className="text-[15px]"
                            href="https://docs.composio.dev/patterns/tools/build-tools/custom-action-with-auth"
                            isExternal={true}
                        >
                            docs
                        </ComposioLink>{" "}
                        to learn more about building and executing custom actions
                    </div>
                ),
            },
        ],
        javascript: [
            {
                type: "blockWizard",
                content: {
                    heading: "Get integration",
                    blocks: [
                        {
                            heading: "Import libraries",
                            text: `import { OpenAI } from "openai";
import { OpenAIToolSet } from "composio-core";
import { z } from "zod";

const openai_client = new OpenAI();
const openAIToolset = new OpenAIToolSet();
`,
                            language: "javascript",
                        },
                        {
                            heading: "Define action",
                            language: "javascript",
                            text: `const action = await openAIToolset.createAction({
    actionName: "my_custom_action",
    toolName: "{{tool_name}}",
    description: "Action Description",
    inputParams: z.object({
        param1: z.string().describe("Parameter 1 description"),
        param2: z.string().describe("Parameter 2 description"),
    }),
    callback: async (inputParams, authCredentials, executeRequest) => {
        try {
            const res = await executeRequest({
                endpoint: "/data", //or any other endpoint path
                method: "GET", //or any other request method
                parameters: [], //custom authentication parameters
                body: {} //request body
            });
            return res;
        } catch (e) {
            console.error(e);
            return {};
        }
    },
})
                            `,
                        },
                        {
                            heading: "Execute action",
                            language: "javascript",
                            text: `const tools = await openAIToolset.getTools({
    actions: ["my_custom_action"]
});

const task = "Define what you want the agent to do";

const response = await openai_client.chat.completions.create({
    model: "gpt-4o",
    messages: [
        { "role": "system", "content": "You are a helpful agent." },
        { "role": "user", "content": task },
    ],
    tools: tools,
    tool_choice: "auto",
});

const result = await openAIToolset.handleToolCall(response, "default");
console.log(result);
`,
                        },
                    ],
                },
            },
            {
                type: "content",
                content: (
                    <div className="mt-6 text-[15px] font-[400]">
                        Visit{" "}
                        <ComposioLink
                            className="text-[15px]"
                            href="https://docs.composio.dev/patterns/tools/build-tools/custom-action-with-auth"
                            isExternal={true}
                        >
                            docs
                        </ComposioLink>{" "}
                        to learn more about building and executing custom actions
                    </div>
                ),
            },
        ],
    },
};

const useCreateCustomActionCode = (appName: string) => {
    const apiKey = useApiKey();

    const transformedData = useMemo(() => {
        // transform data of sampleCodeBlockData
        const data = cloneDeep(sampleCodeBlockData);

        for (const language of Object.keys(data.content)) {
            // @ts-ignore
            for (const languaeItem of data.content[language]) {
                //replace blocks with api key and integration id
                const blocks = languaeItem.content.blocks;
                if (!blocks) continue;
                for (const block of blocks) {
                    block.text = block.text.replace("{{api_key}}", `"${apiKey}"`);
                    block.text = block.text.replace("{{tool_name}}", appName);
                }
            }
        }
        return data;
    }, [apiKey]);

    return transformedData;
};

export const ShowCustomActionCode = ({ setOpen, appName }: { setOpen: (open: boolean) => void; appName: string }) => {
    const codeBlockData = useCreateCustomActionCode(appName);

    return <CodeWInstructionModal title={`Define custom action for ${appName}`} data={codeBlockData} setOpen={() => setOpen(false)} />;
};

export const NoActionAppCodeInstruction = ({ appName }: { appName: string }) => {
    const codeBlockData = useCreateCustomActionCode(appName);
    return <CodeWithInstructionBlock data={codeBlockData} />;
};

export const gridBox = css`
    display: grid;
    grid-template-columns: 280px 100px 1fr 80px 200px;
    gap: 10px;
    width: 100%;
`;

export const Pill = ({ status, text }: { status: string; text?: string }) => {
    return (
        <div
            className={clsx(
                `flex items-center gap-2 rounded-[16px] border-[1px] border-[#dddddd] bg-white px-3 py-1 pt-[4px] text-[12px] font-[400] text-black-200 text-grey-900`,
            )}
        >
            <div>{text || status}</div>
        </div>
    );
};

export const TopBar = ({ appData }: { appData: any; onConnect: () => void }) => {
    const [openSetup, setOpenSetup] = useAtom(openConnectorSetupAtom);
    const [isCopied, setIsCopied] = useState(false);

    const { data } = useGetAllIntegrations(1, appData?.key);
    useAppInfo(appData?.key);

    // @ts-ignore
    const hasIntegration = data?.items?.length > 0;
    return (
        <div className="flex w-full gap-4">
            {openSetup && (
                <E2EFlowWizard
                    initialData={{
                        currentStep: hasIntegration ? 2 : 1,
                        first: {
                            appName: appData?.key,
                        },
                        second: {
                            // @ts-ignore
                            integrationID: data?.items?.[0]?.id || null,
                        },
                        third: {
                            connectedAccountId: null,
                            actionName: null,
                        },
                    }}
                    setOpen={() => {
                        setOpenSetup(false);
                    }}
                />
            )}
            <div
                className={clsx(
                    "flex h-7 min-h-7 w-7 min-w-7 items-center justify-center",
                    css`
                        background-image: url("${appData?.logo}");
                        background-image: cover;
                    `,
                    roundedImage,
                )}
            ></div>
            <div className="w-full">
                <div className="mt-[-4px]  flex items-center gap-3 text-black-200">
                    <span className="font-avenirn text-[18px] font-[600] ">
                        {appData?.name.charAt(0).toUpperCase() + appData?.name.slice(1)}
                    </span>
                    <ToolTipBox
                        content={isCopied ? "✅ Copied!" : "Copy Enum"}
                        onOpenChange={(e) => {
                            if (e === false) {
                                setIsCopied(false);
                            }
                        }}
                    >
                        <span
                            className="flex cursor-pointer items-center gap-2"
                            onClick={() => {
                                copyText(upperCaseAll(appData?.key)!);
                                setIsCopied(true);
                            }}
                        >
                            Enum: {upperCaseAll(appData?.key)} <CopyIcon height={12} width={12} strokeWidth={1.4} />
                        </span>
                    </ToolTipBox>
                </div>
                <div className="mt-1 w-full text-[14px] font-[400] tracking-[.3px] text-black-800" title={appData?.description}>
                    {appData?.description.length > 100 ? `${appData?.description.substring(0, 100)}...` : appData?.description}
                    {(appData?.categories || []).includes("local") ? (
                        <>
                            {" "}
                            (This tool executes locally, click
                            <b>
                                <a href="https://docs.composio.dev/introduction/foundations/components/local_tools"> here </a>
                            </b>
                            learn to more about local tools)
                        </>
                    ) : (
                        ""
                    )}
                </div>
                {appData?.yaml?.docs && (
                    <div className="font-400 mt-2 flex flex-wrap gap-[20px] pt-2 text-[13px] text-black-300">
                        {/*@ts-ignore*/}
                        {data?.yaml?.docs && (
                            <Link
                                href={`${(appData?.yaml as unknown as Record<string, string>)?.docs}`}
                                target="_blank"
                                className=" flex items-center gap-[4px] underline"
                            >
                                <BookAIcon height={12} width={12} strokeWidth={1.4} /> {appData?.name} docs
                            </Link>
                        )}
                    </div>
                )}
            </div>
        </div>
    );
};

const roundedImage = css`
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
`;
