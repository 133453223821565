import { z } from "zod";
import backend from "~/client/sdk";

export const ZLogSchema = z.object({
    id: z.string(),
    connectionId: z.string().nullish(),
    clientId: z.string(),
    entityId: z.string().nullish().optional(),
    status: z.enum(["success", "error"]),
    appName: z.string(),
    createdAt: z.string(),
    type: z.string(),

    meta: z.object({
        data: z.object({
            id: z.string(),
            request: z.string().optional(),
            response: z.string().nullable().optional(),
            errorRequest: z.string().nullable().optional(),
            type: z.enum(["trigger", "action"]).optional(),
            sessionInfo: z.record(z.string(), z.string()).nullable().optional(),
            createdAt: z.string(),
            updatedAt: z.string(),
            provider: z.string(),
            clientId: z.string(),
            connectionId: z.string(),
            actionName: z.string(),
            triggerProviderPayload: z.string().nullable().optional(),
            triggerClientPayload: z.string().nullable().optional(),
            triggerClientError: z.string().nullable().optional(),
            triggerName: z.string().nullable().optional(),
            triggerClientResponse: z.string().nullable().optional(),
        }),
    }),
});

export const getLogs = ({
    connectorId,
    cursor,
    time = "1w",
    status,
    search,
    connectionId,
    logsType,
    entityId,
    sessionId,
}: {
    connectorId?: string;
    cursor?: string;
    time?: "30m" | "6h" | "1d" | "1w" | "1month" | "1y";
    status?: "all" | "success" | "error";
    search?: string;
    connectionId?: string;
    entityId?: string;
    logsType?: "action" | "trigger";
    sessionId?: string;
}) => {
    return backend.logs
        .getLogs({
            query: {
                time: time,
                connectionId: connectionId,
                integrationId: connectorId,
                cursor: cursor,
                status: status,
                search,
                entityId,
                logsType,
                sessionId,
            },
        })
        .then((res) => res.data);
};
