export type Plan = {
    id: string;
    name: string;
    price: string;
    order: number;
    description: string;
    features: { text: string; included: boolean }[];
    buttonText: string;
    isPopular?: boolean;
    period: string;
    isEnterprise?: boolean;
};

export const PRICING_PLANS: Plan[] = [
    {
        id: "HOBBY",
        name: "Hobby",
        price: "$0",
        order: 1,
        description: "Free forever",
        features: [
            {
                text: "50 connected accounts",
                included: true,
            },
            {
                text: "2K API Calls/month",
                included: true,
            },
            {
                text: "1 Week log retention",
                included: true,
            },
            {
                text: "Basic support",
                included: true,
            },
        ],
        buttonText: "Downgrade to Hobby",
        period: "month",
    },
    {
        id: "GROWTH", // Need to change this to STARTER, hack for now
        name: "Starter",
        price: "$199",
        description: "For solo projects",
        order: 3,
        buttonText: "Upgrade to Starter",
        isPopular: true,
        features: [
            {
                text: "500 connected accounts",
                included: true,
            },
            {
                text: "20K API Calls/month",
                included: true,
            },
            {
                text: "1 Month log retention",
                included: true,
            },
            {
                text: "Priority support",
                included: true,
            },
        ],
        period: "month",
    },
    {
        id: "ENTERPRISE",
        name: "Enterprise",
        price: "Custom",
        description: "For enterprise teams",
        features: [
            {
                text: "Unlimited accounts",
                included: true,
            },
            {
                text: "Unlimited API Calls",
                included: true,
            },
            {
                text: "1 Year log retention",
                included: true,
            },
            {
                text: "24/7 Enterprise support",
                included: true,
            },
        ],
        order: 3,
        isEnterprise: true,
        buttonText: "Contact Sales",
        period: "month",
    },
];
