import { css } from "@emotion/css";
import { useMutation, useQuery } from "@tanstack/react-query";
import clsx from "clsx";
import { AnimatePresence, motion } from "framer-motion";
import { atom, useAtom } from "jotai";
import {
    AlertCircle,
    AppWindow,
    ChevronDownIcon,
    Code2,
    CreditCard,
    LayoutDashboard,
    LayoutGrid,
    Link as LinkIcon,
    Puzzle,
    Rocket,
    ScrollText,
    Settings,
    SparkleIcon,
    Waypoints,
    Workflow,
    Wrench,
    Zap,
} from "lucide-react";
import Link from "next/link";
import { useRouter } from "next/router";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { z } from "zod";
import { switchProjectAction } from "~/bootstap";
import backend from "~/client/sdk";
import { createCheckoutSession, getClientInfo } from "~/dataProcessor/api/api";
import { usePHFeatureFlag } from "~/dataProcessor/hooks/global/posthog";
import { DialogBoxCenter } from "~/design-system/atom/alert-dialog-sheet";
import { DialogDescription, DialogFooter, DialogHeader, DialogSubtitle, DialogTitle } from "~/design-system/atom/dialog";
import { SimpleDropdown } from "~/ui/components/custom/dropdown/simple";
import { usePlainLogs } from "~/ui/screens/action_logs/logs";
import { If } from "~/utils/reactComponent";

import { Input } from "~/design-system/atom/input";
import { Button } from "~/design-system/atom/newButton";
import { useToast } from "~/design-system/atom/use-toast";
import { useProjectsQuery } from "~/hooks/query/dashboard";
import { useTriggerDisabledBanner } from "~/hooks/query/triggers";
import { queryClient } from "~/queryClient";
import { scrollBarStyle } from "~/ui/constants/style/common";
import { PRICING_PLANS } from "~/ui/screens/settings/Constants";
import { LOCAL_STORAGE_KEYS, storage } from "~/utils/localStorage";
import NavFooter from "../Components/NavFooter";

const planConfig = {
    HOBBY: { name: "Hobby Plan", description: "Free tier" },
    GROWTH: { name: "Starter Plan", description: "For growing teams" },
    STARTER: { name: "Starter Plan", description: "For growing teams" },
    STARTUP: { name: "Startup Plan", description: "For growing teams" },
    ENTERPRISE: { name: "Enterprise", description: "Custom solutions" },
};

export const selectProjectAtom = atom<string | null>(null);
export const useProjects = () => {
    const { toast } = useToast();
    const router = useRouter();
    const { data: projectsData, isLoading, isError, isSuccess, refetch } = useProjectsQuery();

    const [selectedProject, setSelectedProject] = useAtom(selectProjectAtom);

    const createProject = useMutation({
        mutationFn: (name: string) => {
            return backend.clientAuthService.addProject({
                body: {
                    name,
                },
            });
        },
    });

    const deleteProject = useMutation({
        mutationFn: (id: string) => {
            return backend.clientAuthService.deleteProject({
                path: {
                    projectId: id,
                },
            });
        },
    });

    const findProjectName = (id: string) => {
        const project = projectsData?.items?.find((project) => project.id === id);
        return project?.name;
    };

    const switchProject = async (id: string) => {
        setSelectedProject(id);
        switchProjectAction(id);
        toast({
            title: "Project switched",
            description: "Current Project switched to " + findProjectName(id),
            variant: "success",
        });
        storage.set(LOCAL_STORAGE_KEYS.CURRENT_PROJECT, id);
        setTimeout(() => {
            router.reload();
        }, 500);
        window.projectId = id;
        const allQueries = queryClient.getQueryCache().findAll();
        queryClient.invalidateQueries({ queryKey: allQueries.map((query) => query.queryKey) });
    };

    useEffect(() => {
        if (!selectedProject && projectsData?.items?.length) {
            // Flow:
            // 1. Check if the current project is set in localStorage
            // 2. If it is, set the selected project to the current project
            // 3. If it is not, select the first oldest project, the list is sorted by createdAt in descending order
            // This is done to ensure if x-composio-project-id is not set, the first created project is selected
            // Otherwise, we would have to wait for all projects to load, which might degrade the UX
            const currentProject = storage.get(LOCAL_STORAGE_KEYS.CURRENT_PROJECT, null);
            if (currentProject) {
                setSelectedProject(currentProject);
                switchProjectAction(currentProject);
                window.projectId = currentProject;
                const allQueries = queryClient.getQueryCache().findAll();
                queryClient.invalidateQueries({ queryKey: allQueries.map((query) => query.queryKey) });
            } else {
                const lastProjectIndex = projectsData?.items?.length - 1;
                const lastProject = projectsData?.items[lastProjectIndex] as { id: string };
                if (!!lastProject) {
                    setSelectedProject(lastProject.id);
                    switchProjectAction(lastProject.id);
                }
            }
        }
    }, [projectsData]);

    return {
        projects: projectsData?.items || [],
        isProjectsLoading: isLoading,
        isProjectsError: isError,
        isProjectsSuccess: isSuccess,
        refetchProjects: refetch,
        createProject,
        deleteProject,
        switchProject,
        selectedProject,
    };
};

export const ProjectBox = () => {
    const { projects, switchProject } = useProjects();
    const [selectedProject] = useAtom(selectProjectAtom);

    const projectOptions = useMemo(() => {
        const options = projects.map((project) => ({
            name: project.name,
            value: project.id,
        }));

        options.push({
            name: "Add new project",
            value: "add_new_project",
        });

        return options;
    }, [projects]);
    const [showAddProjectModal, setShowAddProjectModal] = useState(false);

    const [showSwitchProjectModal, setShowSwitchProjectModal] = useState(false);

    const handleSwitchProject = useCallback(
        (id: string) => {
            setShowSwitchProjectModal(true);
            switchProject(id);
            setTimeout(() => {
                setShowSwitchProjectModal(false);
            }, 1000);
        },
        [switchProject],
    );

    const storedProjectId = storage.get(LOCAL_STORAGE_KEYS.CURRENT_PROJECT, null);
    const dropdownValue = storedProjectId || selectedProject || "";

    return (
        <>
            <SimpleDropdown
                className="w-full rounded-[6px] !h-[30px] rounded-[0px]"
                options={projectOptions}
                placeholder="Project"
                onChange={(value) => {
                    if (value === "add_new_project") {
                        setShowAddProjectModal(true);
                    } else {
                        handleSwitchProject(value as string);
                    }
                }}
                value={dropdownValue}
            />
            <If condition={showAddProjectModal}>
                <AddProjectModal
                    currentProjects={projects}
                    setOpen={() => {
                        setShowAddProjectModal(false);
                    }}
                    onSuccess={(id: string) => {
                        handleSwitchProject(id as string);
                    }}
                />
            </If>
        </>
    );
};

export const AddProjectModal = ({
    setOpen,
    onSuccess: handleSuccess,
    currentProjects,
}: {
    setOpen: (e: boolean) => void;
    onSuccess: (id: string) => void;
    currentProjects: {
        [key: string]: unknown;
    }[];
}) => {
    const { toast } = useToast();

    const { createProject, refetchProjects, switchProject } = useProjects();

    const [projectName, setProjectName] = useState("");

    const router = useRouter();
    const handleAddProject = () => {
        const projectNameSchema = z
            .string()
            .min(3, { message: "Project name cannot be shorter than 3 characters" })
            .regex(/^[a-zA-Z0-9_-]+$/, { message: "Project name should only contain alphabets, numbers, underscore and hyphen" })
            .max(50, { message: "Project name cannot be longer than 50 characters" })
            .refine((name) => !currentProjects.some((project) => project.name === name), {
                message: "Project with this name already exists",
            });

        const validationResult = projectNameSchema.safeParse(projectName);

        if (!validationResult.success) {
            toast({
                title: "Error",
                description: validationResult.error.errors[0]?.message || "Validation error",
                variant: "destructive",
            });
            return;
        }
        createProject.mutate(projectName, {
            onSuccess: (project) => {
                setOpen(false);
                toast({
                    title: "Project added",
                    description: "Project created successfully. Switching to the new project...",
                    variant: "success",
                });
                refetchProjects();
                router.push("/dashboard");

                //@ts-ignore
                handleSuccess(project.data.id);
            },
            onError: (error) => {
                const errorMessage = (error as any)?.response?.data?.message || "Something went wrong";
                toast({
                    title: "Error",
                    description: errorMessage,
                });
            },
        });
    };
    return (
        <DialogBoxCenter
            open={true}
            setOpen={setOpen}
            className={clsx("max-h-[90vh] w-[500px] overflow-hidden bg-[#fff] px-6 py-7 pt-5 rounded-[12px]", scrollBarStyle)}
        >
            <DialogHeader>
                <DialogTitle className="flex items-center gap-2">
                    <span className="text-lg font-medium text-gray-900">Add new project</span>
                </DialogTitle>
                <DialogSubtitle>Add a new project to get started</DialogSubtitle>
            </DialogHeader>
            <DialogDescription>
                <div className="flex flex-col gap-2">
                    <label htmlFor="project-name" className="text-sm font-medium text-gray-700">
                        Project Name
                    </label>
                    <Input
                        id="project-name"
                        className="h-[36px] w-[300px] flex-auto rounded-[6px]"
                        placeholder="Enter Project name"
                        value={projectName}
                        onChange={(e) => setProjectName(e.target.value)}
                    />
                </div>
            </DialogDescription>
            <DialogFooter>
                <div className="flex gap-2">
                    <Button variant="white" size={40} className="h-[36px]" onClick={() => setOpen(false)}>
                        Cancel
                    </Button>
                    <Button
                        disabled={createProject.isLoading || !projectName}
                        variant="default"
                        size={40}
                        className="ml-0 h-[36px] bg-purple-600"
                        onClick={handleAddProject}
                    >
                        Add and Switch
                    </Button>
                </div>
            </DialogFooter>
        </DialogBoxCenter>
    );
};

export const Sidebar = React.memo(({ actionable = true }: { actionable?: boolean }) => {
    const { showTriggerDisabledBanner } = useTriggerDisabledBanner();
    const { logsData } = usePlainLogs();
    const showPlayground = usePHFeatureFlag({
        key: "onboarding-v2",
        defaultValue: {
            isEnabled: false,
            payload: null,
        },
    });

    const isConnectorAndLogsLogsEmpty = useMemo(() => {
        return logsData?.length === 0;
    }, [logsData]);

    const sidebarStyle = clsx(
        `fixed flex max-h-[100vh] flex-col bg-[#fbfbfb] overflow-hidden`,
        css`
            width: 264px;
            transition: width 0.1s ease-in-out;
            ${showTriggerDisabledBanner ? "height: calc(100vh - 48px);" : "height: 100vh;"}

            .menu span,
            .hidden-text {
                visibility: visible;
                transition: visibility 10s cubic-bezier(0, 0, 1);
            }
        `,
        "sidebar",
    );

    const isLogsNotEmpty = useMemo(() => {
        return logsData?.length > 0;
    }, [logsData]);

    const links = useMemo(() => {
        return [
            ...(showPlayground.isEnabled
                ? [
                      {
                          link: "/playground",
                          icon: <SparkleIcon height={13} width={13} strokeWidth={1.5} />,
                          text: "Playground",
                      },
                  ]
                : []),
            {
                link: "/dashboard",
                icon: <LayoutDashboard className="w-5 h-5" />,
                text: "Dashboard",
            },
            ...(isLogsNotEmpty
                ? [
                      {
                          link: isConnectorAndLogsLogsEmpty ? "/dashboard" : "/sdk_guide",
                          icon: <Code2 className="w-5 h-5" />,
                          text: isConnectorAndLogsLogsEmpty ? "Get started" : "SDK Guide",
                      },
                  ]
                : []),
            {
                link: "/apps",
                icon: <AppWindow className="w-5 h-5" />,
                text: "Apps",
                children: [
                    {
                        link: "/apps",
                        icon: <LayoutGrid className="w-4 h-4" />,
                        text: "All Apps",
                        disabled: isConnectorAndLogsLogsEmpty,
                    },
                    {
                        link: "/integrations",
                        icon: <Puzzle className="w-4 h-4" />,
                        text: "Integrations",
                        disabled: isConnectorAndLogsLogsEmpty,
                    },
                    {
                        link: "/connections",
                        icon: <LinkIcon className="w-4 h-4" />,
                        text: "Connected accounts",
                        disabled: isConnectorAndLogsLogsEmpty,
                    },
                    {
                        link: "/active_triggers?showDisabled=true",
                        icon: <Workflow className="w-4 h-4" />,
                        text: "Active triggers",
                        disabled: isConnectorAndLogsLogsEmpty,
                    },
                ],
            },
            {
                link: "/logs",
                icon: <ScrollText className="w-5 h-5" />,
                text: "Logs",
                disabled: isConnectorAndLogsLogsEmpty,
                children: [
                    ,
                    {
                        link: "/logs",
                        icon: <AlertCircle className="w-4 h-4" />,
                        text: "Action logs",
                    },
                    {
                        link: "/trigger_logs",
                        icon: <Waypoints className="w-4 h-4" />,
                        text: "Trigger logs",
                    },
                ],
            },
            {
                link: "/developers",
                icon: <Settings className="w-5 h-5" />,
                text: "Settings",
                children: [
                    {
                        link: "/developers",
                        icon: <Wrench className="w-5 h-5" />,
                        text: "Developers",
                    },
                    {
                        link: "/triggers",
                        icon: <Zap className="w-4 h-4" />,
                        text: "Events and Triggers",
                    },
                    {
                        link: "/billing",
                        icon: <CreditCard className="w-4 h-4" />,
                        text: "Billing",
                    },
                ],
            },
        ];
    }, [isLogsNotEmpty]);

    return (
        <div className={sidebarStyle}>
            <div className="px-7 pt-7">
                <div className="flex w-full items-center justify-between  text-[#000]">
                    <img src="/assets/icon/Composio-text.svg" alt="Composio Logo" className="w-[90px]" />
                </div>
            </div>
            <div className="flex h-full flex-col pb-2 pt-6 relative">
                <div className="px-[14px] overflow-y-auto flex-1">
                    <MenuSection
                        // @ts-ignore
                        links={links}
                        actionable={actionable}
                    />
                </div>

                <div className="flex flex-col gap-2 border-t border-gray-200 pt-6 mt-auto">
                    <div className="px-[14px] mb-[10px]">
                        <PlanUpgrade />
                    </div>

                    <div>
                        <NavFooter />
                    </div>
                </div>
            </div>
        </div>
    );
});

export const PlanUpgrade = () => {
    const {
        data: clientInfo,
        isLoading,
        isError,
        isSuccess,
    } = useQuery({
        queryKey: ["clientInfo"],
        queryFn: getClientInfo,
        retry: 3,
        refetchOnMount: true,
        staleTime: Infinity,
    });

    const plan = clientInfo?.data?.client?.plan || "HOBBY";
    const router = useRouter();

    if (isLoading) {
        return (
            <div role="status" className="max-w-sm animate-pulse">
                <div className="h-2.5 bg-gray-200 rounded-[6px] dark:bg-gray-700 w-48 mb-4"></div>
                <div className="h-2.5 bg-gray-200 rounded-[6px] dark:bg-gray-700 w-48 mb-4"></div>
            </div>
        );
    }
    const chosenPlan = planConfig[plan as keyof typeof planConfig] ?? planConfig.HOBBY;
    const { name, description } = chosenPlan;
    const onStartupPlanClick = async () => {
        try {
            const session = await createCheckoutSession({
                plan: PRICING_PLANS[1]?.id ?? "HOBBY",
                applyCoupon: true,
            });

            if (session) {
                window.location.href = session.data.checkoutUrl;
            }
        } catch {}
    };
    return (
        <div className="bg-gray-100 rounded-lg p-4">
            <div className="flex flex-col">
                <div className="flex items-center justify-between">
                    <div>
                        <p className="text-sm mb-0 font-medium text-gray-900">Current Plan</p>
                        <p className="text-xs mb-0 text-gray-500">{name}</p>
                    </div>
                    <div className="px-2 py-[2px] bg-gray-200 rounded-[4px] text-[12px] mb-0 text-[#000000] font-[500]">
                        {plan === "HOBBY" ? "Free Tier" : "Premium"}
                    </div>
                </div>
                <div className="w-full mt-3">
                    {plan === "HOBBY" && (
                        <button
                            className=" flex gap-4 px-4 py-2 w-full bg-purple-600 text-white text-[14px] font-[600] rounded-lg hover:bg-purple-700 transition-colors items-center"
                            onClick={onStartupPlanClick}
                        >
                            <Rocket className="w-4 h-4 text-white" /> Activate Startup Plan
                        </button>
                    )}
                    {(plan == "GROWTH" || plan == "STARTER" || plan == "STARTUP") && (
                        <button
                            className=" flex gap-2 px-4 py-2 w-full bg-purple-600 text-white text-[14px] font-[600] rounded-lg hover:bg-purple-700 transition-colors items-center justify-center"
                            onClick={() => {
                                router.push("/billing");
                            }}
                        >
                            <Rocket className="w-4 h-4 text-white" /> Upgrade
                        </button>
                    )}
                </div>
            </div>
        </div>
    );
};

const openMenuAtom = atom("");

const MenuSection = ({
    links,
}: {
    links: {
        link: string;
        icon: React.ReactNode;
        text: string;
        disabled?: boolean;
        children?: {
            link: string;
            icon: React.ReactNode;
            text: string;
            disabled?: boolean;
        }[];
    }[];
    actionable: boolean;
}) => {
    const [openMenu, setOpenMenu] = useAtom(openMenuAtom);
    const router = useRouter();

    useEffect(() => {
        const matchedLink = links.find(
            (link) => link.link === router.pathname || link.children?.some((child) => child.link === router.pathname),
        );

        if (matchedLink) {
            setOpenMenu(matchedLink.link);
        }
    }, [router.asPath, links]);

    return (
        <div className="mb-3 flex flex-col gap-[8px] z-40">
            {links.map(({ link, icon, text, disabled, children }) => {
                return (
                    // @ts-ignore
                    <MenuBlock
                        {...{
                            link,
                            icon,
                            text,
                            disabled,
                            childItems: children,
                            openMenu,
                        }}
                        key={link}
                    />
                );
            })}
        </div>
    );
};

const MenuBlock = ({
    link,
    icon,
    text,
    disabled,
    childItems,
    openMenu,
}: {
    link: string;
    icon: React.ReactNode;
    text: string;
    disabled: boolean;
    childItems: {
        link: string;
        icon: React.ReactNode;
        text: string;
        disabled: boolean;
    }[];
    openMenu: string;
}) => {
    const hasMenuChildren = childItems && childItems.length > 0;
    const isMenuOpen = openMenu === link;

    return (
        <React.Fragment key={link}>
            <AnimatePresence mode="wait">
                <div key={link}>
                    <MenuLink
                        hasMenuChildren={!!hasMenuChildren}
                        isMenuOpen={isMenuOpen && !!hasMenuChildren}
                        key={link}
                        {...{
                            link,
                            icon,
                            text,
                            disabled,
                        }}
                    />
                    <AnimatePresence>
                        {hasMenuChildren && isMenuOpen && (
                            <motion.div
                                className="flex flex-col gap-[10px] mt-2"
                                key={`${link}-children`}
                                initial={{
                                    opacity: 0,
                                    height: 0,
                                    y: -10,
                                }}
                                animate={{
                                    opacity: 1,
                                    height: "auto",
                                    y: 0,
                                }}
                                exit={{
                                    opacity: 0,
                                    height: 0,
                                    y: -10,
                                }}
                                transition={{
                                    duration: 0.2,
                                }}
                            >
                                {childItems.map(({ link, icon, text, disabled }) => {
                                    return (
                                        <MenuLink
                                            isChild={true}
                                            hasMenuChildren={false}
                                            isMenuOpen={isMenuOpen}
                                            key={link}
                                            {...{
                                                link,
                                                icon,
                                                text,
                                                disabled,
                                            }}
                                        />
                                    );
                                })}
                            </motion.div>
                        )}
                    </AnimatePresence>
                </div>
            </AnimatePresence>
        </React.Fragment>
    );
};

const MenuLink = ({
    hasMenuChildren,
    isMenuOpen,
    text,
    icon,
    link,
    onClick,
    isChild,
}: {
    hasMenuChildren: boolean;
    isMenuOpen: boolean;
    text: string;
    icon: React.ReactNode;
    link?: string;
    onClick?: () => void;
    isChild?: boolean;
}) => {
    const { pathname } = useRouter();
    const isSelected = pathname === link && !hasMenuChildren;

    return (
        <Link href={link || "#"} className={clsx("cursor-pointer")} onClick={onClick}>
            <div
                className={`w-full flex items-center px-4 py-3 text-sm transition-colors duration-150
                ${isChild ? "mx-4 !w-[90%]" : "px-4"}
                ${isSelected ? "bg-gray-100 text-gray-900" : "text-gray-600 hover:bg-gray-100 hover:text-gray-900"}
                rounded-[6px] group`}
            >
                <span className="inline-flex items-center justify-center w-4 h-4 mr-3">{icon}</span>
                <span className="flex-grow text-left text-[14px] text-black font-[500]">{text}</span>
                {hasMenuChildren && (
                    <motion.div
                        initial={{
                            rotate: 0,
                            marginLeft: "auto",
                        }}
                        animate={{
                            rotate: !isMenuOpen ? -0 : -180,
                            marginLeft: "auto",
                        }}
                        exit={{
                            rotate: 0,
                            marginLeft: "auto",
                        }}
                        style={{
                            marginLeft: "auto",
                        }}
                        transition={{
                            duration: 0.2,
                        }}
                    >
                        <ChevronDownIcon height={15} width={15} className={clsx("ml-auto transition-transform duration-100")} />
                    </motion.div>
                )}
            </div>
        </Link>
    );
};
