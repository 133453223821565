import { ToolTipBox } from "~/design-system/atom/tooltip";
import { getGravatarUrl } from "~/utils/user";

const noop = () => {};

export const ProfileGravatar = ({
    member,
    direction = "top",
}: {
    member: {
        email?: string | undefined;
        name?: string | undefined;
    };
    direction?: "top" | "bottom";
}) => {
    return (
        <ToolTipBox align="center" side={direction} content={<>{member?.email}</>} className="w-fit" onOpenChange={noop}>
            <img src={getGravatarUrl(member?.email)} alt={member?.email! || undefined} className="mr-2 h-6 w-6 rounded-full" />
        </ToolTipBox>
    );
};

export default ProfileGravatar;
