import { css } from "@emotion/css";
import clsx from "clsx";
import { MoveDown } from "lucide-react";
import React from "react";
import { AppsFooter } from "./Marquee";

export const NonAuthScreenBackdrop = ({ children }: { children: React.ReactNode }) => {
    return (
        <div
            className={clsx(
                `relative  h-[100vh] w-[100vw]  overflow-hidden`,
                css`
                    background: #f8f8f8;
                    background-size: cover;
                `,
            )}
        >
            <img src="/assets/images/global/illustration/curly_braces.svg" className="absolute left-[-4px] top-[-4px] z-10 h-[57px]" />
            <img src="/assets/images/global/illustration/boxy_website.svg" className="absolute bottom-[0px] left-[-12px] z-10 h-[112px]" />
            <img
                src="/assets/images/global/illustration/colors_website_box.svg"
                className="absolute right-[-2px] top-[-8px] z-10 h-[156px]"
            />
            {children}
            <div className="absolute bottom-[20px] left-0 right-0 lg:block md:hidden z-10">
                <div className="text-center text-[16px] font-gilroy font-[400] text-[#000000] mb-[14px]">
                    Give your agents access to real world tools
                </div>
                <MoveDown className="mx-auto mb-[30px] text-[#575757] " strokeWidth={1.2} />
                <AppsFooter />
            </div>
        </div>
    );
};
