import sdk from "~/client/sdk";
import { VerifyMagicLinkDataDTO } from "~/client/types.gen";

type VerifyOauth2LoginResDTO = {
    message: string;
    data: {
        isValid: boolean;
        jwtToken?: string;
        email?: string;
    };
};

export const sendMagicLink = (email: string, redirectURIClient: string) => {
    return sdk.clientAuthService
        .sendMagicLink({
            body: {
                email,
                verifyHost: redirectURIClient,
            },
        })
        .then((res) => res.data);
};

export const verifyToken = (token: string): Promise<VerifyMagicLinkDataDTO> => {
    return sdk.clientAuthService
        .verifyMagicLink({
            body: {
                token,
            },
        })
        .then((res) => res.data?.data!);
};

export const verifyProviderRequest = ({
    appName,
    queryParams,
}: {
    appName: string;
    queryParams: Record<string, string>;
}): Promise<VerifyOauth2LoginResDTO> => {
    return sdk.appLogin
        .verifyOauth2Login({
            path: {
                appName,
            },
            query: queryParams,
        })
        .then((res) => res.data as VerifyOauth2LoginResDTO);
};

export const getMetadata = async (email: string): Promise<unknown> => {
    return sdk.metadata
        .getMetadata({
            query: {
                email,
            },
        })
        .then((res) => res.data)
        .catch(() => {
            throw new Error("Failed to fetch metadata");
        });
};

export const updateMetadata = async (email: string, metadata: Record<string, unknown>): Promise<unknown> => {
    return sdk.metadata
        .updateMetadata({
            body: {
                email,
                metadata,
            },
        })
        .then((res) => res.data)
        .catch(() => {
            throw new Error("Failed to update metadata");
        });
};
