import { useQuery } from "@tanstack/react-query";
import { getLogs } from "~/dataProcessor/api/logs";

type useGetLogsProps = {
    cursor: string | null;
    connectorId?: string | string[];
    time?: string | string[];
    status?: string | string[];
    search?: string | string[];
    entityId?: string | string[];
    type: "action" | "trigger";
    sessionId?: string | string[];
    selectedProject: string | null;
    enabled: boolean;
};

export const QUERY_KEYS = {
    getLogsQueryKey: (
        cursor: string | null,
        connectorId?: string | string[],
        time?: string | string[],
        status?: string | string[],
        search?: string | string[],
        entityId?: string | string[],
        type?: string,
        sessionId?: string | string[],
        selectedProject?: string | null,
    ) => ["getLogs", cursor, connectorId, time, status, search, entityId, type, sessionId, selectedProject],
};

type Time = "30m" | "6h" | "1d" | "1w" | "1month" | "1y";
type Status = "all" | "success" | "error";

export const useGetLogs = ({
    cursor,
    connectorId,
    time,
    status,
    search,
    entityId,
    type,
    sessionId,
    selectedProject,
    enabled,
}: useGetLogsProps) => {
    const {
        data: logsData,
        isLoading,
        isError,
        isSuccess,
        refetch,
        isRefetching,
    } = useQuery({
        queryKey: QUERY_KEYS.getLogsQueryKey(cursor, connectorId, time, status, search, entityId, type, sessionId, selectedProject),
        queryFn: () =>
            getLogs({
                connectorId: (connectorId as string) || undefined,
                cursor: cursor || undefined,
                time: time as Time,
                status: status as Status,
                search: search as string,
                entityId: entityId as string,
                logsType: type,
                sessionId: sessionId as string,
            }),
        staleTime: 100 * 1000,
        retry: false,
        enabled: enabled,
    });

    return {
        logsData,
        isLoading,
        isError,
        isSuccess,
        refetch,
        isRefetching,
    };
};
