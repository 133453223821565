import Marquee from "react-fast-marquee";
import { apps } from "./constants";

export const FooterElement = ({ icon, title, subtitle }: { icon: string; title: string; subtitle: string }) => {
    return (
        <div className="flex gap-2 border border-gray-200 rounded-[12px] px-[14px] py-[14px] min-w-[200px] bg-white">
            <img src={icon} alt={title} className="w-5 h-5" />
            <div className="flex flex-col">
                <span className="text-gray-600 font-[600] text-[14px]">{title}</span>
                <span className="text-gray-600 font-[500] text-[12px]">{subtitle}</span>
            </div>
        </div>
    );
};

export const AppsFooter = () => {
    return (
        <Marquee>
            <div className="flex items-center gap-6 px-3">
                {apps.map((app) => (
                    <FooterElement key={app.title} icon={app.icon} title={app.title} subtitle={app.subtitle} />
                ))}
            </div>
        </Marquee>
    );
};
