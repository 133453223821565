import { CopyIcon, UploadCloudIcon, XIcon } from "lucide-react";
import React, { useCallback, useEffect, useState } from "react";
import { Input } from "~/design-system/atom/input";
import { Textarea } from "~/design-system/atom/textarea";
import { ToolTipBox } from "~/design-system/atom/tooltip";
import { useToast } from "~/design-system/atom/use-toast";
import { SimpleDropdown } from "~/ui/components/custom/dropdown/simple";
import { isNilOrEmpty } from "~/utils/js";
import { If } from "~/utils/reactComponent";

export const useJSONSchemeForm = ({
    form,
    initData,
    validationType = "onBlur",
    customFields = {},
    setFormData,
    onChange,
    onBlur,
}: {
    form: FormSchema;
    initData: Record<string, unknown>;
    validationType?: "onBlur" | "onChange";
    customFields?: Record<string, React.ComponentType<any>>;
    setFormData?: (formData: Record<string, unknown>) => void;
    onChange?: (value: Record<string, unknown>) => void;
    onBlur?: (name: string) => void;
}) => {
    const [formState, setFormState] = useState<FormState>(() => initializeFormState(form, initData));

    useEffect(() => {
        setFormData?.(formState);
    }, [formState, setFormData]);

    useEffect(() => {
        setFormState(initializeFormState(form, initData));
    }, [form, initData]);

    const validateField = useCallback(
        (name: string, value: any): string[] => {
            const fieldSchema = form.properties[name];

            if (!fieldSchema) return [];

            const errors: string[] = [];

            const isRequired = form.required?.includes(name) || false;
            if (isRequired && !value) {
                errors.push("This field is required");
            }

            if (fieldSchema.type && isRequired) {
                if (fieldSchema.type === "array" && !Array.isArray(value)) {
                    errors.push("Expected an array");
                } else if (fieldSchema.type === "object" && (typeof value !== "object" || value === null)) {
                    errors.push("Expected an object");
                } else if (
                    fieldSchema.type !== "array" &&
                    fieldSchema.type !== "object" &&
                    fieldSchema.type !== typeof value &&
                    fieldSchema.type !== "integer"
                ) {
                    errors.push(`Expected type ${fieldSchema.type}`);
                }
            }

            if (fieldSchema.type === "array" && Array.isArray(value)) {
                if (fieldSchema.minItems && value.length < fieldSchema.minItems) {
                    errors.push(`Array must have at least ${fieldSchema.minItems} items`);
                }

                if (fieldSchema.maxItems && value.length > fieldSchema.maxItems) {
                    errors.push(`Array must have no more than ${fieldSchema.maxItems} items`);
                }
            }

            if (fieldSchema.type === "object" && typeof value === "object" && value !== null && fieldSchema.required) {
                for (const requiredProp of fieldSchema.required) {
                    if (!(requiredProp in value)) {
                        errors.push(`Missing required property: ${requiredProp}`);
                    }
                }
            }

            if (fieldSchema.validation && typeof fieldSchema.validation === "function") {
                const isValid = fieldSchema.validation(value);

                if (!isValid) {
                    errors.push("Invalid value");
                }
            }

            return errors;
        },
        [form],
    );

    const validateForm = useCallback((): boolean => {
        const updatedFormState = Object.entries(form.properties).reduce((acc, [name]) => {
            const value = formState[name]?.value;
            const errors = validateField(name, value);

            // @ts-ignore
            acc[name] = { ...formState[name], errors, value };
            return acc;
        }, {} as FormState);

        const isValid = Object.values(updatedFormState).every((field) => field.errors.length === 0);

        setFormState(updatedFormState);
        return isValid;
    }, [formState, form.properties, validateField]);

    const handleChange = useCallback(
        (name: string, value: any) => {
            setFormState((prev) => ({
                ...prev,
                [name]: {
                    ...prev[name],
                    value,
                    errors: validationType === "onChange" ? validateField(name, value) : prev[name]?.errors || [],
                    touched: true,
                },
            }));
        },
        [validateField, validationType],
    );

    const handleBlur = useCallback(
        (name: string) => {
            if (validationType === "onBlur") {
                setFormState((prev) => ({
                    ...prev,
                    [name]: {
                        ...prev[name],
                        errors: validateField(name, prev[name]?.value),
                        touched: true,
                        value: prev[name]?.value,
                    },
                }));
            }

            onBlur?.(name);
        },
        [onBlur, validateField, validationType],
    );

    useEffect(() => {
        const formValue = Object.entries(formState).reduce(
            (acc, [key, { value }]) => {
                acc[key] = value;
                return acc;
            },
            {} as Record<string, unknown>,
        );

        onChange?.(formValue);
    }, [formState, onChange]);

    const isFieldRequired = (name: string): boolean => {
        return form.required?.includes(name) || false;
    };

    return {
        validateField,
        validateForm,
        setFormData: (newFormData: Record<string, unknown>) => {
            setFormState((prev) =>
                Object.entries(newFormData).reduce(
                    (acc, [key, value]) => {
                        // @ts-ignore
                        acc[key] = { ...prev[key], value };
                        return acc;
                    },
                    { ...prev },
                ),
            );
        },
        setItem: handleChange,
        formState,
        handleChange,
        handleBlur,
        isFieldRequired,
        customFields,
    };
};

const initializeFormState = (form: FormSchema, initData: Record<string, unknown>): FormState => {
    return Object.entries(form.properties || {}).reduce((acc, [key, schema]) => {
        acc[key] = {
            value: initData[key] ?? schema.default ?? "",
            errors: [],
            touched: false,
        };
        return acc;
    }, {} as FormState);
};

// Types and Interfaces
interface FormField {
    value: any;
    errors: string[];
    touched: boolean;
}

type FormState = Record<string, FormField>;

interface FormSchema {
    type: string;
    properties: Record<
        string,
        | {
              type: string;
              title?: string;
              description?: string;
              default?: any;
              validation?: (value: any) => boolean;
          }
        | any
    >;
    required?: string[];
}

interface CustomFormProps {
    form: FormSchema;
    initData?: Record<string, unknown>;
    formData?: Record<string, unknown>;
    onChange?: (value: Record<string, unknown>) => void;
    onBlur?: (name: string) => void;
    customFields?: Record<string, React.ComponentType<any>>;
    validationType?: "onBlur" | "onChange";
    setFormData?: (formData: Record<string, unknown>) => void;
}

type FieldProps = {
    name: string;
    schema: any;
    value: any;
    errors: string[];
    touched: boolean;
    onChange: (name: string, value: any) => void;
    onBlur: (name: string) => void;
};

// Helper Functions
export const validateSingleField = (name: string, value: any, schema: any): string[] => {
    const fieldSchema = schema.properties[name];

    if (!fieldSchema) return [];

    const errors: string[] = [];

    if (schema.required?.includes(name) && !value) {
        errors.push("This field is required");
    }

    return errors;
};

// Components
const FormField: React.FC<FieldProps> = ({ name, schema, value, errors, touched, onChange, onBlur }) => {
    const props = {
        name,
        schema,
        value,
        errors,
        touched,
        onChange,
        onBlur,
    };
    const hasError = touched && errors.length > 0;
    const errorStyle = hasError
        ? {
              border: "1px solid red",
          }
        : {};

    if (schema.title === "file") {
        return <CustomFileUpload {...props} />;
    }

    switch (schema.type) {
        case "string":
            return (
                <Input
                    type="text"
                    value={value}
                    size={32}
                    onChange={(e) => onChange(name, e.target.value)}
                    onBlur={() => onBlur(name)}
                    style={errorStyle}
                />
            );

        case "number":
        case "integer":
            return (
                <Input
                    type="number"
                    value={value}
                    size={40}
                    className="!leading-none"
                    onChange={(e) => onChange(name, Number(e.target.value))}
                    onBlur={() => onBlur(name)}
                    style={errorStyle}
                />
            );

        case "boolean":
            const isValueEmpty = isNilOrEmpty(value);
            return (
                <SimpleDropdown
                    options={[
                        {
                            name: "Not selected",
                            value: "not-selected",
                        },
                        {
                            name: "Yes",
                            value: true,
                        },
                        {
                            name: "No",
                            value: false,
                        },
                    ]}
                    onChange={(value) => {
                        onChange(name, value === "not-selected" ? "" : value);
                    }}
                    value={isValueEmpty ? "not-selected" : value}
                    placeholder="Select value"
                />
            );

        case "object":
        case "array":
            return (
                <Textarea
                    value={typeof value === "string" ? value : JSON.stringify(value, null, 2)}
                    onChange={(e) => onChange(name, e.target.value)}
                    className="min-h-[100px]"
                    onBlur={(e) => {
                        try {
                            const parsedValue = JSON.parse(e.target.value);
                            onChange(name, parsedValue);
                        } catch {
                            // Handle invalid JSON here if needed
                        }

                        onBlur(name);
                    }}
                    style={errorStyle}
                />
            );

        default:
            return null;
    }
};

const CustomFileUpload: React.FC<FieldProps> = (props) => {
    const { toast } = useToast();
    const { name, onChange } = props;
    const [hasUploadedFileName, setHasUploadedFileName] = useState("");

    return (
        <>
            <p className="mb-[16px] text-[13px] text-gray-500">Select the file to upload</p>
            <div>
                <label htmlFor="file-upload" className="custom-file-upload">
                    <UploadCloudIcon height={12} width={12} /> Upload {name}
                </label>
                <input
                    id="file-upload"
                    type="file"
                    onChange={(e) => {
                        if (e.target.files && e.target.files.length === 1) {
                            const file = e.target.files[0]!;
                            const reader = new FileReader();

                            reader.onload = (event) => {
                                if (event.target?.result) {
                                    const content = btoa(event.target.result as string);

                                    onChange(props.name, {
                                        name: file.name,
                                        content: content,
                                        type: file.type,
                                        size: file.size,
                                    });
                                    setHasUploadedFileName(file.name);
                                }
                            };
                            reader.readAsBinaryString(file);
                        } else {
                            toast({
                                title: "Error",
                                description: "Select only one valid file",
                                variant: "destructive",
                            });
                        }
                    }}
                />
                {!!hasUploadedFileName && (
                    <div className="flex items-center gap-2">
                        <div>{hasUploadedFileName}</div>
                        <div>
                            <XIcon
                                height={12}
                                width={12}
                                onClick={() => {
                                    setHasUploadedFileName("");
                                    onChange(name, null);
                                }}
                            />
                        </div>
                    </div>
                )}
            </div>
        </>
    );
};

export const JSONSchemForm: React.FC<CustomFormProps> = ({
    form,
    onChange,
    onBlur,
    customFields = {},
    validationType = "onBlur",
    setFormData,
    initData,
}) => {
    const { formState, handleChange, handleBlur, isFieldRequired } = useJSONSchemeForm({
        form,
        // @ts-ignore
        initData: initData,
        onChange,
        onBlur,
        customFields,
        validationType,
        setFormData,
    });

    const { toast } = useToast();

    return (
        <div className="!py-[8px]">
            {Object.entries(form.properties || {})
                .sort(([nameA], [nameB]) => {
                    const isRequiredA = form.required?.includes(nameA) || false;
                    const isRequiredB = form.required?.includes(nameB) || false;

                    return isRequiredB ? 1 : isRequiredA ? -1 : 0;
                })
                .map(([name, schema]: [string, any]) => (
                    <div key={name} className="">
                        <div className="flex items-center justify-between">
                            <div className="!mb-[6px] block text-[14px] font-medium text-black-500">
                                {schema.title || name}
                                {isFieldRequired(name) && <span className="text-red-500">*</span>} {schema.type && `: ${schema.type}`}
                            </div>
                            <div className="!mb-[6px] flex items-center gap-2 text-black-800">
                                key: {name}{" "}
                                <CopyIcon
                                    height={10}
                                    width={10}
                                    className="cursor-pointer"
                                    onClick={() => {
                                        navigator.clipboard.writeText(name);
                                        toast({
                                            title: "Copied to clipboard",
                                            description: "Key copied to clipboard",
                                            variant: "default",
                                        });
                                    }}
                                />
                            </div>
                        </div>
                        {schema.description && (
                            <ToolTipBox
                                content={<div className="max-w-[600px] text-[13px] leading-[170%] text-left">{schema.description}</div>}
                            >
                                <div className="!mb-[20px] text-[12px] mt-[3px] text-grey-900 text-left">
                                    {schema.description.length > 60 ? schema.description.substring(0, 60) + "..." : schema.description}
                                    <If condition={schema.description.length > 60}>
                                        <span className="underline ml-[2px]">Read more</span>
                                    </If>
                                </div>
                            </ToolTipBox>
                        )}
                        <div className="mb-[20px]">
                            {customFields[name] ? ( // @ts-ignore
                                React.createElement(customFields[name], {
                                    value: formState[name]?.value,
                                    onChange: (newValue: any) => handleChange(name, newValue),
                                    onBlur: () => handleBlur(name),
                                    errors: formState[name]?.touched ? formState[name]?.errors : [],
                                })
                            ) : (
                                <FormField
                                    name={name}
                                    schema={schema}
                                    value={formState[name]?.value}
                                    errors={formState[name]?.errors || []}
                                    touched={formState[name]?.touched || false}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                />
                            )}
                            {formState[name]?.touched &&
                                formState[name]?.errors.map((error, index) => (
                                    <p key={index} className="mt-1 text-sm text-red-600">
                                        {error}
                                    </p>
                                ))}
                        </div>
                    </div>
                ))}
        </div>
    );
};

export default JSONSchemForm;
