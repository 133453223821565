import { BookOpen, HelpCircle } from "lucide-react";
import Link from "next/link";
import React from "react";
import { DISCORD_LINK } from "~/constants/content";

const NavFooter: React.FC = () => (
    <div className="pt-2 border-t border-gray-200 ">
        <div className="flex mx-2">
            <Link
                href="https://docs.composio.dev"
                target="_blank"
                className="flex-1 flex items-center justify-center px-2 py-2 text-sm text-gray-600 hover:bg-gray-100 hover:text-gray-900 rounded-lg transition-colors"
            >
                <BookOpen className="w-4 h-4 mr-2" />
                <span className="text-[14px] text-black font-[500]">Docs</span>
            </Link>
            <Link
                href={DISCORD_LINK}
                target="_blank"
                className="flex-1 flex items-center justify-center px-2 py-2 text-sm text-gray-600 hover:bg-gray-100 hover:text-gray-900 rounded-lg transition-colors"
            >
                <HelpCircle className="w-4 h-4 mr-2" />
                <span className="text-[14px] text-black font-[500]">Support</span>
            </Link>
        </div>
    </div>
);

export default NavFooter;
