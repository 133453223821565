import { format } from "date-fns";

export const formatDate = (date: string, formatType = "standard") => {
    if (formatType === "standard") {
        return format(new Date(date), new Date().getFullYear() === new Date(date).getFullYear() ? "MM/dd-HH:mm:ss" : "MM/dd/yy-HH:mm:ss");
    }
    if (formatType === "full") {
        return format(new Date(date), "MMM dd, yy - HH:mm:ss");
    }

    return format(new Date(date), "MMM dd, yy - HH:mm:ss");
};
