import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { FullScreenLoader } from "~/ui/components/base/FullScreenLoader";
import { getCookie } from "~/utils/cookie";

export function withAuth(WrappedComponent: React.ComponentType<unknown>) {
    return (props: unknown) => {
        const router = useRouter();
        const [isLoading, setIsLoading] = useState(true);

        useEffect(() => {
            const isLoggedIn = getCookie("isLoggedIn");
            if (isLoggedIn !== "true") {
                window.location.href = "/";
            } else {
                setIsLoading(false);
            }
        }, [router]);

        if (isLoading) {
            return <FullScreenLoader showLoader={true} showMessage={false} />;
        }

        return <WrappedComponent {...(props as object)} />;
    };
}
