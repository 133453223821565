import { css } from "@emotion/css";
import clsx from "clsx";
import { BookAIcon, HelpCircle, MinusIcon, PlusIcon } from "lucide-react";
import React from "react";
import { DISCORD_LINK } from "~/constants/content";

export const FAQOptions = () => {
    const [openIndex, setOpenIndex] = React.useState<number | null>(null);

    const faqItems = [
        {
            title: "What is an Integration?",
            description: "An integration allows external apps to connect with Composio, enabling seamless interactions between them.",
        },
        {
            title: "How to manage Connections for multiple Users/Tenants?",
            description:
                "You can manage multiple connections by assigning each user a unique entity ID, which can represent multiple connected accounts.",
        },
        {
            title: "How to White Label an Integration?",
            description:
                "You can create a custom user interface on top of Composio and set up a custom redirect URL to deliver a white-labeled experience.",
        },
        {
            title: "How do I modify the permissions requested by an Integration?",
            description:
                "You can customize the access levels by defining specific scopes when setting up the integration, ensuring it only requests necessary permissions.",
        },
    ];

    return (
        <div className="flex flex-col gap-6 px-3">
            <div>
                <div className="mb-2 mt-3 font-cera text-[13px] leading-none text-black-500">Frequently Asked Questions</div>
                <div className="flex flex-col gap-[4px] text-black-400">
                    {faqItems.map((item, index) => (
                        <FAQItem
                            key={index}
                            title={item.title}
                            description={item.description}
                            isOpen={openIndex === index}
                            onClick={() => setOpenIndex(openIndex === index ? null : index)}
                        />
                    ))}
                </div>
            </div>
            <div className="grid grid-cols-2 gap-2 ">
                <SupportCard
                    title="Need Help?"
                    description="Reach out to in discord"
                    icon={<HelpCircle className="h-4 w-4" />}
                    theme="cyan"
                    onClick={() => {
                        window.open(DISCORD_LINK, "_blank");
                    }}
                />
                <SupportCard
                    title="View Docs"
                    description="SDK reference and code examples"
                    icon={<BookAIcon className="h-4 w-4" />}
                    theme="green"
                    onClick={() => {
                        window.open("https://docs.composio.dev", "_blank");
                    }}
                />
            </div>
        </div>
    );
};

export const SupportCard = ({
    title,
    description,
    icon,
    onClick,
    theme,
}: {
    title: string;
    description: string;
    onClick: () => void;
    theme: "cyan" | "green";
    icon: React.ReactNode;
}) => {
    return (
        <div
            className={clsx(
                "flex flex-col gap-1 px-3 py-3",
                css`
                    border-radius: 12px;
                    border: 0.5px solid ${theme === "cyan" ? "#2980B9" : "#4CAF50"};
                    background: ${"#fff"};
                    //box-shadow: 0px 0px 0px 2px rgba(0, 0, 0, 0.04);
                    color: ${theme === "cyan" ? "#264287" : "#3D500D"};

                    cursor: pointer;
                    :hover {
                        filter: brightness(1.1);
                    }
                `,
            )}
            onClick={onClick}
        >
            <div className="flex items-center justify-between gap-1">
                <div className="font-cera font-cera text-[13.5px] font-[500]  leading-none">{title}</div>
                {icon}
            </div>
            <div className=" flex flex-col gap-[4px] text-[12px]  font-[400] leading-[1.5] tracking-[0.2px] opacity-80">{description}</div>
        </div>
    );
};

export const FAQItem = ({
    title,
    description,
    isOpen,
    onClick,
}: {
    title: string;
    description: string;
    isOpen: boolean;
    onClick: () => void;
}) => {
    return (
        <div className="border-b-[0.5px] border-gray-200 py-4">
            <button className="flex w-full items-center justify-between text-left" onClick={onClick}>
                <span className="font-cera text-[13px] text-black-500 ">{title}</span>
                <span className="ml-6 flex items-center">
                    {isOpen ? (
                        <MinusIcon className="h-4 w-4" strokeWidth={1} aria-hidden="true" />
                    ) : (
                        <PlusIcon className="h-4 w-4" strokeWidth={1} aria-hidden="true" />
                    )}
                </span>
            </button>
            {isOpen && (
                <div className="mt-3">
                    <p className="text-[12px] leading-[2] tracking-[0.2px] text-gray-600">{description}</p>
                </div>
            )}
        </div>
    );
};
