import clsx from "clsx";
import { cloneDeep } from "lodash";
import { AlertTriangleIcon, CheckIcon, CopyIcon, ExternalLinkIcon, XIcon } from "lucide-react";
import Link from "next/link";
import { useRouter } from "next/router";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { z } from "zod";
import { QUERY_KEYS } from "~/constants/keys";
import { ZConnectionInfoData, toggleConnectionStatus } from "~/dataProcessor/api/api";
import { useApiKey } from "~/dataProcessor/hooks/user";
import { Button } from "~/design-system/atom/button";
import { Button as NewButton } from "~/design-system/atom/newButton";
import { Switch } from "~/design-system/atom/switch";
import { ToolTipBox } from "~/design-system/atom/tooltip";
import { useToast } from "~/design-system/atom/use-toast";
import { queryClient } from "~/queryClient";
import { LoadingIcon } from "~/ui/components/base/loadingIcon";
import { CodeWInstructionModal } from "~/ui/containers/codeModal";
import { copyText } from "~/utils/dom";
import { formatUnderScoreString, upperCaseAll } from "~/utils/string";
import { formatDate } from "~/utils/time";
import { ProxyActionPlayground } from "../actions/proxyAction";
import { getConnectionStatus } from "../appScreen/modules/addConnectedAccount";
import { CodeButton } from "../integrationPage";

export const removeKeyFromObject = (obj: Record<string, unknown>, key: string) => {
    const { [key]: _, ...rest } = obj;
    return rest;
};

export const Row = ({
    label,
    data,
    onCopy,
    leftIcon,
}: {
    label: string;
    data: string | React.ReactNode;
    onCopy?: () => void;
    leftIcon?: () => React.ReactNode;
}) => {
    const isCopyable = onCopy !== undefined;
    const [isCopied, setIsCopied] = useState(false);

    return (
        <div className={clsx("text-black-4000 border-grey-00 grid grid-cols-2 border-b-[1px] px-6 py-4 pb-4 text-[14px]")}>
            <div className="break-words flex items-center gap-2">{label}</div>
            <div className="flex items-center justify-end">
                {typeof leftIcon === "function" ? leftIcon() : leftIcon}
                {isCopyable ? (
                    <ToolTipBox
                        side={"right"}
                        onOpenChange={() => {
                            setIsCopied(false);
                        }}
                        content={<div>{isCopied ? "Copied" : "Copy to clipboard"}</div>}
                    >
                        <div
                            className="flex items-center justify-end gap-2 font-[500] text-black-400"
                            onClick={(e) => {
                                e.stopPropagation();
                                setIsCopied(true);
                                onCopy();
                            }}
                        >
                            {data}
                            <CopyIcon height={15} width={15} className="min-h-[15px] min-w-[15px]" strokeWidth={1.2} />
                        </div>{" "}
                    </ToolTipBox>
                ) : (
                    <div className="flex justify-end font-[500] text-black-400">{data}</div>
                )}
            </div>
        </div>
    );
};

export const ConnectionInfoBlock = ({
    connectionParams,
    connectionId,
    connectionInfo,
}: {
    connectionParams: any;
    connectionId: string | string[];
    connectionInfo: z.infer<typeof ZConnectionInfoData>;
}) => {
    const { toast } = useToast();
    const isActive = connectionInfo?.status === "ACTIVE";

    const [isChecked, setIsChecked] = useState(false);

    useEffect(() => {
        setIsChecked(connectionInfo?.enabled || false);
    }, [connectionInfo]);

    const [showPlayground, setShowPlayground] = useState(false);
    const apiReqCounterRef = useRef(0);
    const { query } = useRouter();

    const connectionStatusIcon = () => {
        switch (connectionInfo?.status) {
            case "ACTIVE": {
                const icon = (
                    <div className="flex h-4 w-4 items-center justify-center rounded-full bg-green-600 mt-[-4px] mr-1">
                        <CheckIcon height={10} width={10} className="text-green-600" strokeWidth={2} color={"#fff"} />
                    </div>
                );
                return connectionInfo?.statusReason ? (
                    <ToolTipBox content={<div>{connectionInfo.statusReason}</div>} side={"bottom"} align="center">
                        {icon}
                    </ToolTipBox>
                ) : (
                    icon
                );
            }
            case "EXPIRED": {
                const icon = (
                    <div className="flex h-5 w-5 items-center justify-center mr-1">
                        <AlertTriangleIcon height={16} width={16} className="text-yellow-600 mt-[-4px]" strokeWidth={2} />
                    </div>
                );
                return (
                    <ToolTipBox
                        content={
                            <div className="w-[300px]">{`${connectionInfo?.statusReason || "Connection expired"}. Please re-authenticate to continue using this connection.`}</div>
                        }
                        side={"bottom"}
                        align="center"
                    >
                        {icon}
                    </ToolTipBox>
                );
            }
            case "FAILED": {
                const icon = (
                    <div className="flex h-5 w-5 items-center justify-center mr-1">
                        <div className="flex h-4 w-4 items-center justify-center rounded-full bg-red-600 mt-[-4px]">
                            <XIcon height={10} width={10} className="text-white" strokeWidth={2} color={"#fff"} />
                        </div>
                    </div>
                );
                return connectionInfo?.statusReason ? (
                    <ToolTipBox content={<div className="w-[300px]">{connectionInfo.statusReason}</div>} side={"bottom"} align="center">
                        {icon}
                    </ToolTipBox>
                ) : (
                    icon
                );
            }
            case "INITIATED": {
                return (
                    <div className="flex h-5 w-5 items-center justify-center mt-[-4px] mr-1">
                        <LoadingIcon height={16} width={16} className="text-grey-400" strokeWidth={2} />
                    </div>
                );
            }
            default:
                return null;
        }
    };

    const handleReauthenticate = () => {
        const windowObj = window.open(connectionParams.redirectUrl, "_blank");
        const interval = setInterval(() => {
            getConnectionStatus(connectionId as string).then((res) => {
                if (!["INITIATED", "FAILED", "EXPIRED"].includes(res.status)) {
                    clearInterval(interval);
                    windowObj?.close();
                    window.location.reload();
                    // Revalidate the connected accounts
                    queryClient.invalidateQueries(QUERY_KEYS.getConnectionsQueryKey(query));
                }
            });

            apiReqCounterRef.current += 1;

            if (apiReqCounterRef.current > 60) {
                // setStateInfo({ index: 1, data: { status: "failed" } });
                clearInterval(interval);
            }
        }, 2000);
    };

    return (
        <>
            {showPlayground && (
                <ProxyActionPlayground
                    setOpen={setShowPlayground}
                    connectionInfo={connectionInfo as unknown as z.infer<typeof ZConnectionInfoData>}
                />
            )}
            <ShowConnectionCode connectionId={connectionId as string} />

            <div className={clsx(" my-6 min-w-[540px] max-w-[720px] rounded-[12px] border-[1px] border-grey-200 bg-[#fff]  text-[13px]")}>
                {connectionInfo?.status === "EXPIRED" && connectionParams.redirectUrl && (
                    <div className="mb-4 rounded-lg border border-yellow-200 bg-yellow-50 p-4">
                        <div className="flex items-center gap-2">
                            <AlertTriangleIcon height={16} width={16} className="text-yellow-600" strokeWidth={2} />
                            <div className="font-medium text-yellow-800">Connection needs re-authorization</div>
                        </div>
                        <p className="mt-2 text-sm text-yellow-700">
                            This connection has expired and requires re-authorization. Please re-authenticate to continue using the
                            integration's features and ensure uninterrupted service.
                        </p>
                        <div className="mt-4 flex justify-start">
                            <NewButton onClick={handleReauthenticate} size={32} className="w-[140px] font-[500] text-[#fff] text-[13px]">
                                Re-authenticate
                            </NewButton>
                        </div>
                    </div>
                )}
                {/* <div className="grid grid-cols-2 border-b-[1px] border-grey-200 px-6  py-3 pb-[7px] text-[13px] font-[500] text-black-400">
                    <div>Label</div>
                    <div className="flex justify-end">Data</div>
                </div> */}
                <Row label="Last Updated At" data={formatDate(connectionInfo.updatedAt, "full")} />

                <Row
                    label="App name"
                    data={upperCaseAll(connectionInfo?.appUniqueId! as string)}
                    onCopy={() => {
                        copyText(upperCaseAll(connectionInfo?.appUniqueId! as string)!);
                    }}
                />
                <Row
                    label="Connection Id"
                    data={connectionId as string}
                    onCopy={() => {
                        copyText(connectionId as string);
                    }}
                />
                <Row
                    label="Entity"
                    data={connectionInfo.clientUniqueUserId as string}
                    onCopy={() => {
                        copyText(connectionInfo.clientUniqueUserId as string);
                    }}
                />
                {connectionInfo.labels?.length! > 0 && (
                    <Row
                        label="Labels"
                        // @ts-expect-error
                        data={connectionInfo.labels.join(",") as string}
                        onCopy={() => {
                            // @ts-expect-error
                            copyText(connectionInfo.labels as string);
                        }}
                    />
                )}
                <Row
                    label="Integration Id"
                    data={
                        <Link
                            href={`/connector/${connectionInfo?.integrationId}`}
                            target="_blank"
                            className="flex items-center gap-[4px] text-purple-900 underline"
                        >
                            {connectionInfo?.integrationId as string}
                            <ExternalLinkIcon height={12} width={12} strokeWidth={1.4} />
                        </Link>
                    }
                />
                <Row label="Status" data={connectionInfo?.status} leftIcon={connectionStatusIcon} />
                {isActive && (
                    <Row
                        label="Perform actions"
                        data={
                            <Button
                                size={32}
                                variant="default"
                                onClick={() => {
                                    setShowPlayground(true);
                                }}
                                className="ml-2 max-w-[120px]"
                            >
                                Execute actions
                            </Button>
                        }
                    />
                )}
                <Row
                    label="Enabled"
                    data={
                        <Switch
                            checked={isChecked}
                            id="enabled-mode"
                            onCheckedChange={(e) => {
                                setIsChecked(e);
                                toggleConnectionStatus({
                                    connectionID: connectionId as unknown as string,
                                    isEnabled: e,
                                })
                                    .then(() => {
                                        toast({
                                            title: "Status updated",
                                            description: `Connector ${e ? "enabled" : "disabled"}`,
                                        });
                                    })
                                    .catch(() => {
                                        setIsChecked(!e);
                                        toast({
                                            title: "Error",
                                            description: `Error in updating status`,
                                            variant: "destructive",
                                        });
                                    });
                            }}
                        />
                    }
                />
                {/* <Row label="Delete" data={<DeleteConnectionButton connectionId={connectionId as string} />} /> */}
                {Object.keys(connectionParams).map((key) => {
                    const value = connectionParams[key];

                    const isHidden =
                        value === "*".repeat(value.length) || (typeof value === "object" && value && Object.keys(value).length === 0);
                    if (isHidden) {
                        return null;
                    }

                    const valueToCopy =
                        typeof value === "string" ? value : !!value ? JSON.stringify(removeKeyFromObject(value, "x-request-id")) : "";

                    const fomattedValue = valueToCopy?.length > 50 ? valueToCopy.slice(0, 50) + "..." : valueToCopy;
                    return (
                        <Row
                            key={key}
                            label={formatUnderScoreString(key)}
                            data={
                                <div className="flex h-[20px] overflow-hidden whitespace-nowrap text-[13px] font-[400] tracking-[.35px]">
                                    {fomattedValue}
                                </div>
                            }
                            onCopy={() => {
                                copyText(valueToCopy);
                            }}
                        />
                    );
                })}
            </div>
        </>
    );
};

export const sampleCodeBlockData = {
    content: {
        python: [
            {
                type: "blockWizard",
                content: {
                    heading: "Code for creating a connection for a user",
                    blocks: [
                        {
                            heading: "Get integration",
                            text: `from composio import ComposioToolSet, App
# Example usage
toolset = ComposioToolSet(api_key={{api_key}})
connection = toolset.get_connected_account({{connection_id}})
print(connection)`,
                            language: "python",
                        },
                    ],
                },
            },
        ],
        javascript: [
            {
                type: "blockWizard",
                content: {
                    heading: "Get integration",
                    blocks: [
                        {
                            heading: "Get integration",
                            text: `import {Composio} from "composio-core";
// get composio client
const composio = new Composio({apiKey: {{api_key}}});

const connection = await composio.connectedAccounts.get({
    connectedAccountId: {{connection_id}}
})
`,
                            language: "javascript",
                        },
                    ],
                },
            },
        ],
    },
};

const ShowConnectionCode = ({ connectionId }: { connectionId: string }) => {
    const [showCode, setShowCode] = useState(false);

    const apiKey = useApiKey();

    const transformedData = useMemo(() => {
        // transform data of sampleCodeBlockData
        const data = cloneDeep(sampleCodeBlockData);

        for (const language of Object.keys(data.content)) {
            // @ts-ignore
            for (const languaeItem of data.content[language]) {
                //replace blocks with api key and integration id
                const { blocks } = languaeItem.content;

                if (!blocks) continue;

                for (const block of blocks) {
                    block.text = block.text.replace("{{api_key}}", `"${apiKey}"`).replace("{{connection_id}}", `"${connectionId}"`);
                }
            }
        }

        return data;
    }, [apiKey, connectionId]);

    return (
        <div>
            <div className="mb-3 mt-3 flex min-w-[540px] max-w-[720px] items-center justify-between">
                <div className="text-black-4000 text-[14px] font-[500]">Get connection details using code</div>
                <CodeButton setShowCode={setShowCode} />
            </div>
            {showCode && <CodeWInstructionModal title="Get connection code" data={transformedData} setOpen={() => setShowCode(false)} />}
        </div>
    );
};
