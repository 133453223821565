import { css } from "@emotion/css";
import { useQuery } from "@tanstack/react-query";
import clsx from "clsx";
import { ExternalLinkIcon, FileSearch2Icon, XIcon } from "lucide-react";
import { useRouter } from "next/router";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { z } from "zod";
import sdk from "~/client/sdk";
import { QUERY_KEYS } from "~/constants/keys";
import { ZLogSchema, getLogs } from "~/dataProcessor/api/logs";
import { UpdateQueryBatch } from "~/dataProcessor/hooks/global/router";
import { Button } from "~/design-system/atom/newButton";
import { ToolTipBox } from "~/design-system/atom/tooltip";
import { useGetLogs } from "~/hooks/query/logs";
import { ErrorBoundary } from "~/ui/components/base/errorBoundary";
import { useProjects } from "~/ui/containers/base/Sidebar";
import { ActionNTriggerFilterBar, STATUS_VALUES } from "~/ui/containers/common/triggerActionFilterBar";
import { getCookie } from "~/utils/cookie";
import { If } from "~/utils/reactComponent";
import { prettifyString } from "~/utils/strapi";
import { formatDate } from "~/utils/time";
import { Table } from "../../components/custom/Table";
import { RightSideContent } from "../../containers/base/base";
import { LogSliderInfo } from "./slider";

export const useMetadata = () => {
    const {
        data: metadata,
        isLoading: isLoadingMetadata,
        refetch,
    } = useQuery({
        queryKey: QUERY_KEYS.USER_METADATA,
        queryFn: () =>
            sdk.metadata
                .getMetadata({
                    query: {
                        email: getCookie("composio_email")!,
                    },
                })
                .then((res) => res.data),
    });

    const updateMetadata = useCallback((newMetadata: any) => {
        sdk.metadata
            .updateMetadata({
                body: {
                    email: getCookie("composio_email")!,
                    metadata: newMetadata,
                },
            })
            .then(async (res) => {
                await refetch();
                return res.data;
            });
    }, []);

    return {
        metadata,
        isLoadingMetadata,
        updateMetadata,
        refetch,
    };
};

export const usePlainLogs = () => {
    const {
        data: logsData,
        isLoading,
        isError,
        isSuccess,
        refetch,
        isRefetching,
    } = useQuery({
        queryKey: QUERY_KEYS.PLAIN_LOGS_QUERY_KEY,
        queryFn: () =>
            getLogs({
                time: "1month",
            }),
        staleTime: 100 * 100000,
        retry: false,
        enabled: true,
    });

    const { metadata, isLoadingMetadata } = useMetadata();

    const isOnboardingCompleted = useMemo(() => {
        //@ts-ignore
        return metadata?.has_completed_onboarding || false;
    }, [metadata]);

    return {
        logsData: logsData?.data || [],
        isLoading: isLoading || isLoadingMetadata,
        isError,
        isSuccess,
        refetch,
        isRefetching,
        isOnboardingCompleted,
        //@ts-ignore
        hasExecutedAction: logsData?.data?.length > 0 || false,
    };
};

export const useLogs = ({ type, enabled }: { type: "action" | "trigger"; enabled: boolean }) => {
    const { query } = useRouter();
    const [finalLogsLists, setFinalLogsLists] = useState<z.infer<typeof ZLogSchema>[]>([]);
    const [cursor, setCursor] = useState<string | null>(null);

    const { selectedProject } = useProjects();
    const { time, status, search, connectorId, entityId, sessionId } = query;

    const { logsData, isLoading, isError, isSuccess, refetch, isRefetching } = useGetLogs({
        cursor,
        connectorId,
        time,
        status,
        search,
        entityId,
        type,
        sessionId,
        selectedProject,
        enabled,
    });

    useEffect(() => {
        //@ts-ignore
        setFinalLogsLists((prevLogs) => [...prevLogs, ...(logsData?.data || [])]);
    }, [logsData]);

    const loadMoreLogs = useCallback(() => {
        if (logsData?.nextCursor) {
            setCursor(logsData.nextCursor);
        }
    }, [logsData?.nextCursor]);

    useEffect(() => {
        const resetLogs = (shouldRefetch = true) => {
            setCursor(null);
            setFinalLogsLists([]);
            if (shouldRefetch) {
                refetch();
            }
        };

        resetLogs(true);
        return () => {
            resetLogs(false);
        };
    }, [time, status, search, entityId, type, sessionId, refetch]);
    return {
        logsData: finalLogsLists,
        isLoading: isLoading && finalLogsLists.length === 0,
        isError,
        isSuccess,
        loadMoreLogs,
        isRefetching,
        cursor: logsData?.nextCursor,
        refetch,
    };
};

export const doesLogValueExist = (status: string) => {
    return STATUS_VALUES.find((item) => item.value === status);
};

export const LogsRenderer = () => {
    const { type } = React.useContext(LogsContext);
    const router = useRouter();
    const updateQueryBatch = UpdateQueryBatch();
    const { isReady } = router;

    useEffect(() => {
        if (isReady) {
            const status = router.query.status as string;
            const statusValue = doesLogValueExist(status) ? status : "all";
            updateQueryBatch({
                status: statusValue,
                page: (router.query.page as string) || "1",

                time: (router.query.time as string) || "1w",
            });
        }
    }, [isReady]);

    const { logsData, isLoading, isError, isSuccess, loadMoreLogs, cursor } = useLogs({
        type,
        enabled: isReady,
    });

    const tableHeaders = ["time", "Name", "type", "Entity", "request", "response/error"];

    const headerRenderer = useCallback(() => {
        return tableHeaders.map((header, index) => (
            <div key={index} className="font-gilroy text-[12px] font-[500]">
                {header}
            </div>
        ));
    }, [tableHeaders]);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const rowRenderer = useCallback((row: any) => {
        return <LogRow row={row} />;
    }, []);

    const [selectedRow, setSelectedRow] = useState<z.infer<typeof ZLogSchema> | null>(null);

    return (
        <>
            {!!selectedRow && (
                <LogSliderInfo
                    open={true}
                    setOpen={() => {
                        setSelectedRow(null);
                    }}
                    logData={selectedRow}
                    actionName={selectedRow.meta.data.actionName}
                />
            )}
            <div className="pt-2">
                <ActionNTriggerFilterBar />
            </div>
            <If condition={!!router.query.sessionId}>
                <div className="mt-6 flex items-center gap-1 text-[14px] text-black-400">
                    Showing logs for session: <span className="font-[600]">{router.query.sessionId}</span>
                    <Button
                        variant="white"
                        onClick={() => {
                            updateQueryBatch({
                                sessionId: null,
                            });
                        }}
                        size={32}
                    >
                        Clear Session <XIcon height={14} width={14} />
                    </Button>
                </div>
            </If>
            <Table
                gridCSS={gridBox}
                rows={logsData || []}
                state={isLoading ? "loading" : isError ? "error" : isSuccess ? "success" : "none"}
                noResultText="No logs found"
                headerRenderer={headerRenderer}
                rowRenderer={rowRenderer}
                onRowClick={(row) => {
                    setSelectedRow(row);
                }}
                isMoreItems={!!cursor}
                // @ts-ignore
                loadMoreItems={() => {
                    loadMoreLogs();
                }}
                rowContainerHeight={window.innerHeight - 174}
                containerClass="rounded-bl-none rounded-br-none !rounded-[10px]"
            />
        </>
    );
};

const LogRow = ({ row }: { row: z.infer<typeof ZLogSchema> }) => {
    const logMeta = row?.meta;

    // Created variable logMeta for row.meta
    const isTrigger = row?.type === "trigger";

    // @ts-ignore
    const actionName = logMeta?.data?.actionName || logMeta?.data?.triggerName || "";

    // @ts-ignore
    const { sessionId = null } = logMeta?.data?.sessionInfo || {};

    const requestScheme = useMemo(() => {
        if (isTrigger) {
            return logMeta?.data?.triggerClientPayload;
        }

        if (logMeta?.data?.request || logMeta?.data?.triggerProviderPayload) {
            const lastObj = !isTrigger ? logMeta.data.request : logMeta.data.triggerProviderPayload;

            // @ts-ignore
            delete lastObj.properties;
            // @ts-ignore
            delete lastObj.type;
            return lastObj || "{}";
        }

        return "{}";
    }, [logMeta?.data]);

    const isError = useMemo(() => {
        if (isTrigger && logMeta?.data?.triggerClientResponse) {
            try {
                const obj = JSON.parse(logMeta.data.triggerClientResponse);
                return obj.sdkTriggerInvocation.status === "ERROR" && obj.webhookInvocationResponse.status === "ERROR";
            } catch {
                return false;
            }
        }

        return !!logMeta?.data?.errorRequest;
    }, [logMeta?.data?.triggerClientResponse, logMeta?.data?.errorRequest]);

    const response = useMemo(() => {
        if (isTrigger) {
            if (logMeta?.data?.triggerClientResponse) {
                try {
                    const obj = JSON.parse(logMeta.data.triggerClientResponse);

                    if (obj.sdkTriggerInvocation.status === "ERROR" && obj.webhookInvocationResponse.status === "ERROR") {
                        return "❌ Both SDK and Webhook invocation were not successful";
                    }
                } catch {
                    return logMeta.data.triggerClientError || logMeta.data.triggerClientPayload;
                }
            }

            return logMeta.data.triggerClientError || logMeta.data.triggerClientPayload;
        }

        if (isError) {
            try {
                const obj = JSON.parse(logMeta.data.errorRequest!);
                return obj.error || (typeof obj.response_data === "string" ? obj.response_data : JSON.stringify(obj.response_data));
            } catch {
                return JSON.stringify(logMeta.data.errorRequest) || "Error while processing the request";
            }
        }

        return logMeta?.data?.response || {};
    }, [logMeta?.data, isError, isTrigger]);

    if (!logMeta) {
        return (
            <>
                <div className="flex gap-2 text-[12.5px]">Loading</div>
            </>
        );
    }

    const updateQueryBatch = UpdateQueryBatch();

    // @ts-ignore
    const { entityId } = row;

    return (
        <>
            <div className="flex gap-2 text-[12.5px]">
                <div className={clsx(isError ? "bg-[#ce341c]" : "bg-[#289728]", "mr-2 h-[20px] min-w-[3px] rounded-[3px]")}></div>
                <div className="pt-[2px] text-[12.5px] font-[500] text-black-600">{formatDate(row.createdAt, "standard")}</div>
                <If condition={!!sessionId}>
                    <ToolTipBox
                        side="right"
                        content={
                            <div
                                onClick={(e) => {
                                    e.stopPropagation();
                                    updateQueryBatch({
                                        sessionId: sessionId,
                                    });
                                }}
                            >
                                This logs generated from sessionId: <span className="font-[600]">{sessionId}</span>
                                <br /> Click to view session
                            </div>
                        }
                    >
                        <FileSearch2Icon
                            height={12}
                            width={12}
                            onClick={(e) => {
                                e.stopPropagation();
                                updateQueryBatch({
                                    sessionId: sessionId,
                                });
                            }}
                            color="#701EF5"
                        />
                    </ToolTipBox>
                </If>
            </div>
            <div className={clsx("flex cursor-pointer items-center gap-1 text-[12.5px]")}>
                {actionName ? prettifyString(actionName)!.substr(0, 26) + "..." : "N/A"}
            </div>
            <div className="text-[12.5px]">{row.type.charAt(0).toUpperCase() + row.type.slice(1)}</div>
            <div className="flex items-center items-center gap-1 text-[12.5px]">
                <If condition={!!entityId}>
                    <>
                        <If condition={entityId?.length! > 10}>{entityId?.slice(0, 10)}...</If>
                        <If condition={entityId?.length! <= 10}>{entityId}</If>
                        <div
                            className="flex cursor-pointer items-center gap-1 text-[12.5px] hover:underline"
                            onClick={(e) => {
                                e.stopPropagation();
                            }}
                        >
                            <ExternalLinkIcon height={10} width={10} />
                        </div>
                    </>
                </If>
                <If condition={!entityId}>
                    <div>N/A</div>
                </If>
            </div>
            <div className="max-h-[16px] overflow-hidden whitespace-nowrap text-[12px] text-black-400">{requestScheme?.substr(0, 48)}</div>
            <div className={`text-[12px] ${isError ? "text-red-500" : "text-black-400"}  max-h-[16px] overflow-hidden whitespace-nowrap`}>
                {response && typeof response === "string"
                    ? response.substr(0, 56)
                    : isError
                      ? "Error while processing the request"
                      : "No response"}
            </div>
        </>
    );
};

export const gridBox = css`
    display: grid;
    grid-template-columns: 148px 200px 72px 120px 2fr 3fr;
    gap: 10px;
    width: 100%;
`;
export const TagStatus = ({ status, text }: { status: "SUCCESS" | "ERROR"; text?: string }) => {
    const statusColors = {
        SUCCESS: {
            bg: "#63ff6613",
            border: "#63ff6672",
        },
        ERROR: {
            bg: "#ff639213",
            border: "#ff638070",
        },
    };

    const colorScheme = statusColors[status || "SUCCESS"];

    return (
        <div
            className={clsx(
                `flex h-[28px] items-center gap-2 rounded-[16px] px-3 py-1 pt-[4px] text-[12px] font-[600] text-[#ffffff]`,
                css`
                    background-color: ${colorScheme?.bg};
                    border: 1px solid ${colorScheme?.border};
                `,
            )}
        >
            <div className={` text-[#fff]`}>{text || status}</div>
        </div>
    );
};

const LogsContext = React.createContext<{ type: "action" | "trigger" }>({
    type: "action",
});

export default function LogsScreen({ type }: { type: "action" | "trigger" }) {
    return (
        <LogsContext.Provider
            value={{
                type,
            }}
        >
            <RightSideContent
                header={
                    <div className="w-full">
                        <div>
                            <span className="min-w-[208px]">Action logs</span>
                        </div>
                    </div>
                }
            >
                <div className="px-6">
                    <ErrorBoundary fallback={<div>{`Something went wrong. We'll work on getting this fixed.`}</div>}>
                        <LogsRenderer />
                    </ErrorBoundary>
                </div>
            </RightSideContent>
        </LogsContext.Provider>
    );
}
