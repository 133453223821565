import { useQuery } from "@tanstack/react-query";
import { NextRouter } from "next/router";
import sdk from "~/client/sdk";
import { timePeriodString } from "~/ui/screens/dashboard/modules/analytics/analytics";

export const QUERY_KEYS = {
    GET_ANALYTICS: (timePeriod: timePeriodString) => ["analytics", timePeriod],
    GET_PROJECTS: "GET_PROJECTS",
};

export function useAnalyticsDashboardQuery(router: NextRouter) {
    return useQuery({
        queryKey: QUERY_KEYS.GET_ANALYTICS(router.query.timePeriod as timePeriodString),
        queryFn: () =>
            sdk.analytics
                .getAnalytics({
                    query: {
                        lastTimePeriod: router.query.timePeriod as timePeriodString,
                    },
                })
                .then((res) => res.data),
        refetchOnMount: true,
        refetchOnWindowFocus: true,
        cacheTime: 60 ** 2 * 1000,
        staleTime: 60 ** 2 * 1000,
        enabled: !!router.query.timePeriod,
    });
}

export function useProjectsQuery() {
    return useQuery({
        queryKey: [QUERY_KEYS.GET_PROJECTS],
        queryFn: () =>
            sdk.clientAuthService.getProjects().then((res) => {
                return res.data;
            }),
        staleTime: 100 * 100000,
    });
}
