import { z } from "zod";
import sdk from "~/client/sdk";
import { AppListResDTO } from "~/client/types.gen";

export const ZAppSchema = z.object({
    items: z.array(
        z.object({
            key: z.string(),
            name: z.string(),
            description: z.string(),
            logo: z.string().optional().nullish(),
            categories: z.array(z.string()).optional(),
            docsURL: z.string().optional(),
            platformDocsURL: z.string().optional(),
            enabled: z.boolean().optional(),
            meta: z.object({
                triggersCount: z.number(),
                actionsCount: z.number(),
            }),
        }),
    ),
    totalPages: z.number().optional(),
});

export const getApps = ({ category }: { category?: string | null }): Promise<AppListResDTO> => {
    return sdk.app
        .getApps({
            query: {
                category: category as string | undefined,
                includeLocal: "true", // TODO: Make configurable
                additionalFields: "auth_schemes",
                // TODO: Generate this from BE
                sortBy: "usage",
            },
        })
        .then((res) => res.data as unknown as AppListResDTO);
};
export const ZAppInfoSchema = z.object({
    appId: z.string(),
    key: z.string(),
    name: z.string(),
    description: z.string(),
    logo: z.string().optional().nullish(),
    categories: z.array(z.string()).optional(),
    path: z.string().optional(),
    docs: z.string().optional(),
    configuration_docs_text: z.string().optional(),
    status: z.string().optional(),
    documentation_doc_text: z.string().optional(),
    testConnectors: z.array(z.any()).optional(),
    no_auth: z.boolean().optional(),
    auth_schemes: z
        .array(
            z.object({
                scheme_name: z.string(),
                auth_mode: z.string(),
                isSelect: z.boolean().optional(),
                fields: z.array(
                    z.object({
                        name: z.string(),
                        displayName: z.string().optional(),
                        description: z.string(),
                        type: z.string().optional(),
                        required: z.boolean().optional(),
                        default: z.string().optional(),
                        value: z.string().optional(),
                        expected_from_customer: z.boolean().optional(),
                        choices: z
                            .array(
                                z.object({
                                    value: z.string(),
                                    label: z.string(),
                                }),
                            )
                            .optional(),
                    }),
                ),
            }),
        )
        .optional(),
    yaml: z.any(),
    // Common abstraction
    meta: z
        .object({
            triggersCount: z.number().optional(),
            actionsCount: z.number().optional(),
            is_custom_app: z.boolean().optional(),
        })
        .optional(),
});

export const getAppInfo = ({ appKey }: { appKey: string }) => {
    return sdk.app
        .getApp({
            path: {
                appName: appKey,
            },
            throwOnError: true,
        })
        .then((res) => res.data!);
};
