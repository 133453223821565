import { useQuery } from "@tanstack/react-query";
import { getClientIntegrations } from "~/dataProcessor/api/api";
import { getApps } from "~/dataProcessor/api/apps";
import { Duration } from "~/utils/duration";

export const QUERY_KEYS = {
    GET_TOOLS: "GET_TOOLS",
    getAppListQueryKey: (page: number, category?: string | null) => ["GET_TOOLS", page, category],
    GET_INTEGRATIONS: "GET_INTEGRATIONS",
    getIntegrationQueryKey: (appName: string | null = null) => ["GET_INTEGRATIONS", appName],
};

// All Tools Page

export const useGetAllApps = ({ page, category, suspense = false }: { page: number; category?: string | null; suspense?: boolean }) => {
    const {
        data: { items: appsData = [], totalPages = 1 } = {},
        isLoading,
        isError,
        isSuccess,
        refetch,
    } = useQuery({
        queryKey: QUERY_KEYS.getAppListQueryKey(page, category),
        queryFn: () =>
            getApps({
                category,
            }),
        retry: false,
        staleTime: Duration({ min: 5 }).toMS(),
        suspense: suspense,
    });

    return {
        data: {
            items: appsData,
            totalPages,
        },
        isLoading,
        isError,
        isSuccess,
        refetch,
    };
};

// Integrations Page

export const useGetAllIntegrations = (page = 1, appName: string | null = null) => {
    const { data, isLoading, isError, isSuccess, refetch } = useQuery({
        queryKey: QUERY_KEYS.getIntegrationQueryKey(appName),
        queryFn: () => getClientIntegrations(page, appName!, true),
        staleTime: Duration({ d: 1 }).toMS(),
        retry: true,
        retryOnMount: false,
        refetchOnMount: false,
    });

    return {
        data,
        isLoading,
        isError,
        isSuccess,
        refetch,
    };
};
