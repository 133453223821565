import { css } from "@emotion/css";
import { useMutation } from "@tanstack/react-query";
import clsx from "clsx";
import { cloneDeep } from "lodash";
import { CopyIcon, Trash2Icon } from "lucide-react";
import { useRouter } from "next/router";
import { useCallback, useMemo, useState } from "react";
import { type z } from "zod";

import { deleteConnection, toggleConnectionStatus, ZIntegrationItemSchema, type ZConnectionItem } from "~/dataProcessor/api/api";
import { UpdateQuery } from "~/dataProcessor/hooks/global/router";
import { useApiKey } from "~/dataProcessor/hooks/user";
import { Button } from "~/design-system/atom/button";
import { Link as ComposioLink } from "~/design-system/atom/link";
import { Switch } from "~/design-system/atom/switch";
import { ToolTipBox } from "~/design-system/atom/tooltip";
import { useToast } from "~/design-system/atom/use-toast";
import { useGetAllIntegrations } from "~/hooks/query/tools";
import { useGetConnectorInfo } from "~/pages/connector/[connectorId]";
import { ErrorBoundary } from "~/ui/components/base/errorBoundary";
import { SimpleDropdown } from "~/ui/components/custom/dropdown/simple";
import { PaginationComponent } from "~/ui/components/custom/PagninationComponent";
import { Table } from "~/ui/components/custom/Table";
import { RightSideContent } from "~/ui/containers/base/base";
import { CodeWInstructionModal } from "~/ui/containers/codeModal";
import { DeletePopup } from "~/ui/containers/common/ActionButton";
import { AddAccountButton } from "~/ui/containers/common/AddAccountButton";
import { EntityIdFilter } from "~/ui/containers/common/triggerActionFilterBar";
import { copyText } from "~/utils/dom";
import { formatActionName, showFirstAndLastNLetters } from "~/utils/string";
import { formatDate } from "~/utils/time";

import Link from "next/link";
import { useGetAllConnections } from "~/hooks/query/connections";
import { CodeButton } from "../integrationPage";

export const ConnectionFilter = ({ totalPage = "" }: { totalPage: string | number }) => {
    const router = useRouter();
    const { query } = router;
    const updateQuery = UpdateQuery();

    const { data: { items: connectors } = {} } = useGetAllIntegrations();
    const isConnectorPage = router.pathname === "/connector/[connectorId]";

    return (
        <div className="mt-4 flex items-center justify-between text-[13px] text-black-400">
            <div className="flex items-center gap-2">
                Filter by
                <EntityIdFilter />
            </div>
            <div className="flex items-center gap-2">
                <ShowConnectionCode />
                {!isConnectorPage && (
                    <SimpleDropdown
                        options={
                            [
                                {
                                    name: "All",
                                    value: "ALL",
                                },
                                ...(connectors?.map((connector) => ({
                                    name: `${connector.name}`,
                                    value: connector.id,
                                })) || []),
                            ] as {
                                name: string;
                                value: string;
                            }[]
                        }
                        onChange={(value) => {
                            updateQuery("connectorId", value === "ALL" ? undefined : (value as string));
                        }}
                        value={query?.connectorId ? (query.connectorId as string) : "ALL"}
                        size={32}
                        placeholder="Tool"
                        className="w-[72px]"
                    />
                )}

                <SimpleDropdown
                    options={[
                        { name: "All", value: "ALL" },
                        { name: "Active", value: "ACTIVE" },
                        { name: "Initiated", value: "INITIATED" },
                        { name: "Failed", value: "FAILED" },
                        { name: "Expired", value: "EXPIRED" },
                    ]}
                    onChange={(value) => {
                        updateQuery("status", value as string);
                    }}
                    value={query.status as string}
                    size={32}
                    placeholder="Status"
                    className="w-[80px]"
                />
                <PaginationComponent
                    currentPage={Number(query.page) || 1}
                    totalPages={Number(totalPage) || 1}
                    onChange={(page) => updateQuery("page", page.toString())}
                    height={32}
                />
            </div>
        </div>
    );
};

export const ConnectedAccountsScreen = () => {
    return (
        <RightSideContent
            header={
                <div className="w-full">
                    <div>
                        <span className="min-w-[120px]">Connected accounts</span>
                    </div>
                </div>
            }
        >
            <div className="w-full px-8 py-8 pt-3">
                <ErrorBoundary fallback={<div>{`Something went wrong. We'll work on getting this fixed.`}</div>}>
                    <ConnectedAccountsRenderer />
                </ErrorBoundary>
            </div>
        </RightSideContent>
    );
};

export const ConnectedAccountsRenderer = () => {
    const router = useRouter();
    const { connections, isLoading, isError, isSuccess } = useGetAllConnections();

    const headerRenderer = useCallback(() => {
        return ["Tool", "Entity ID", "Account ID", "Created", "Status", ""].map((header, index) => (
            <div key={index} className={clsx("font-gilroy text-[12px] font-[500]", index === 5 ? "flex justify-end pr-4" : "")}>
                {header}
            </div>
        ));
    }, []);

    const rowRenderer = useCallback(
        (row: z.infer<typeof ZConnectionItem>) => {
            return <Row row={row} />;
        },
        [router],
    );

    const isStatusSelected = useMemo(() => {
        return !!router.query.status;
    }, [router.query.status]);

    return (
        <div className="h-full w-full">
            <ConnectionFilter totalPage={connections?.totalPages || ""} />
            <Table
                gridCSS={gridBox}
                rows={connections?.items || []}
                state={isLoading ? "loading" : isError ? "error" : isSuccess ? "success" : "none"}
                noResultText={
                    <div className="flex flex-col items-center justify-center gap-2 pb-20">
                        <div className="mb-3 flex flex-col items-center gap-2">
                            {isStatusSelected ? (
                                <>
                                    <div className="text-[16px] font-[600] text-black-400">
                                        No connections found for the selected status
                                    </div>
                                    <div className="text-[14px] font-[400] text-black-800">Change the status to try again</div>
                                </>
                            ) : (
                                <>
                                    <div className="text-[16px] font-[600] text-black-400">No connected account is available</div>
                                    <div className="text-center text-[14px] font-[400] text-black-800">
                                        <Link href="/apps" className="text-[#9C4EFF] underline underline-offset-2 hover:text-[#7A3ECC]">
                                            Click here
                                        </Link>{" "}
                                        to connect our first account
                                        <br />
                                        <div className="mt-2">
                                            This will give your agent access to that particular tool (e.g Github, Google Calendar)
                                        </div>
                                    </div>
                                </>
                            )}
                        </div>
                        <AddAccountButtonComp />
                    </div>
                }
                headerRenderer={headerRenderer}
                rowRenderer={rowRenderer}
                onRowClick={(row) => router.push(`/connection/${row.id}`)}
                emptyStateClass="pb-10"
                rowContainerHeight={window.innerHeight - 174}
                rowHeight={50}
            />
        </div>
    );
};

export const Row = ({ row }: { row: z.infer<typeof ZConnectionItem> }) => {
    const [isCopied, setIsCopied] = useState(false);
    const router = useRouter();
    const [isChecked, setIsChecked] = useState(!!!row.isDisabled);
    const entityId = useMemo(() => {
        return row?.clientUniqueUserId;
    }, [row.clientUniqueUserId]);
    return (
        <>
            <div className="flex items-center gap-2 text-[12px] font-[500] text-black-600 ">
                <img src={row.logo} alt={row.appName} className="h-[25px] w-[25px]" />
                {formatActionName(row.appName)}
            </div>

            <div className="w-fit whitespace-nowrap text-[12px] font-[400] text-black-600">
                <ToolTipBox
                    side="right"
                    align="center"
                    content={isCopied ? "✅ Copied!" : "Copy ID"}
                    onOpenChange={(e) => {
                        if (e === false) {
                            setIsCopied(false);
                        }
                    }}
                >
                    <div
                        className="flex items-center gap-1"
                        onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            copyText(row?.clientUniqueUserId || "default");
                            setIsCopied(true);
                        }}
                    >
                        <span className="text-overflow-ellipsis flex max-w-full cursor-pointer items-center gap-2 overflow-hidden whitespace-nowrap">
                            {showFirstAndLastNLetters(entityId || "default", 8)}
                        </span>
                        <CopyIcon height={12} width={12} strokeWidth={1.4} className="min-w-[12px]" />
                    </div>
                </ToolTipBox>
            </div>

            <div className="w-fit text-[12px] font-[400] text-black-600">
                <ToolTipBox
                    side="right"
                    align="center"
                    content={isCopied ? "✅ Copied!" : "Copy ID"}
                    onOpenChange={(e) => {
                        if (e === false) {
                            setIsCopied(false);
                        }
                    }}
                >
                    <span
                        className="flex cursor-pointer items-center gap-2"
                        onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            copyText(row.id);
                            setIsCopied(true);
                        }}
                    >
                        {showFirstAndLastNLetters(row.id, 8)}
                        <CopyIcon height={12} width={12} strokeWidth={1.4} />
                    </span>
                </ToolTipBox>
            </div>

            <div className="text-[12px]">{formatDate(row.createdAt, "standard")}</div>
            <div className="text-[12px] font-[600] text-black-600">
                <div className="max-w-[72px]">
                    <TagStatus status={row.status} />
                </div>
            </div>

            <div
                className="flex items-center justify-end gap-3 pr-3"
                onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                }}
            >
                {!row.integrationIsDisabled ? (
                    <Switch
                        checked={isChecked}
                        id="enabled-mode"
                        onCheckedChange={(e) => {
                            setIsChecked(e);
                            toggleConnectionStatus({
                                connectionID: row.id,
                                isEnabled: e,
                            }).catch(() => {
                                setIsChecked(!e);
                            });
                        }}
                    />
                ) : (
                    <Switch
                        checked={!row.integrationIsDisabled}
                        id="enabled-mode"
                        title={row.integrationDisabledReason ? `${row.integrationDisabledReason}` : ""}
                        onCheckedChange={(e) => {
                            setIsChecked(e);
                            toggleConnectionStatus({
                                connectionID: row.id,
                                isEnabled: e,
                            }).catch(() => {
                                setIsChecked(!e);
                            });
                        }}
                        disabled={row.integrationIsDisabled === undefined}
                    />
                )}
                <Button
                    size={28}
                    variant={"plain"}
                    onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        router.push(`/connection/${row.id}`);
                    }}
                    className="mt-[auto] w-[fit-content] rounded-[10px] border-[.5px] border-[#D5D5D5] !bg-[#FBFBFB] text-[13px] font-[400] tracking-[.3px] text-black-200"
                >
                    <div className="mt-[2px] text-[13px] font-[500]">Open</div>
                </Button>
                <DeleteConnectionButton connectionId={row.id} />
            </div>
        </>
    );
};

export const DeleteConnectionButton = ({ connectionId }: { connectionId: string }) => {
    const [open, setOpen] = useState(false);
    const { refetch } = useGetAllConnections();
    const { toast } = useToast();

    const handleDeleteion = useMutation(deleteConnection, {
        onSuccess: (data, variables) => {
            toast({
                title: "Success",
                description: "Connection was deleted successfully",
            });
            setOpen(false);
            refetch();
        },
        onError: (error: Error) => {
            toast({
                title: "Error",
                description: error?.message,
                variant: "destructive",
            });
            console.error("Failed to delete connection:", error);
        },
    });

    const handleDelete = () => {
        handleDeleteion.mutate({ connectionID: connectionId });
    };

    return (
        <>
            {open && (
                <DeletePopup
                    handleDelete={handleDelete}
                    setOpen={(e) => {
                        setOpen(false);
                    }}
                />
            )}
            <div
                onClick={() => {
                    setOpen(true);
                }}
                className="cursor-pointer px-[4px]"
            >
                <Trash2Icon height={14} width={14} />
            </div>
        </>
    );
};

export const DeleteConnectionButtonV2 = ({ connectionId }: { connectionId: string }) => {
    const [open, setOpen] = useState(false);
    const { refetch } = useGetAllConnections();
    const { toast } = useToast();

    const handleDeletion = useMutation(deleteConnection, {
        onSuccess: (data, variables) => {
            toast({
                title: "Success",
                description: "Connection was deleted successfully",
            });
            setOpen(false);
            refetch();
        },
        onError: (error: Error) => {
            toast({
                title: "Error",
                description: error?.message,
                variant: "destructive",
            });
            console.error("Failed to delete connection:", error);
        },
    });

    const handleDelete = () => {
        handleDeletion.mutate({ connectionID: connectionId });
    };

    return (
        <>
            {open && (
                <DeletePopup
                    handleDelete={handleDelete}
                    setOpen={(e) => {
                        setOpen(false);
                    }}
                />
            )}
            <Button
                onClick={() => {
                    setOpen(true);
                }}
                className="mt-[auto] h-8 w-[fit-content] rounded-[10px] border-[.5px] border-[#D5D5D5] !bg-[#FBFBFB] text-[13px] font-[400] tracking-[.3px] text-black-200"
            >
                <Trash2Icon height={14} width={14} className="mr-2" />
                Delete
            </Button>
        </>
    );
};

export const AddAccountButtonComp = () => {
    const { query } = useRouter();
    const { data: connectorInfo } = useGetConnectorInfo(query.connectorId as string);
    if (!query.connectorId || !connectorInfo) return null;

    return <AddAccountButton buttonVariant="default" connectorData={ZIntegrationItemSchema.parse(connectorInfo)} />;
};

export const gridBox = css`
    display: grid;
    grid-template-columns: 120px 140px 195px 160px 1fr 1fr;
    gap: 16px;
    width: 100%;
`;

const TagStatus = ({ status }: { status: "ACTIVE" | "INITIATED" | "SUCCESS" }) => {
    const statusColors = {
        ACTIVE: { bg: "#63ff6608", border: "#26c129" },
        SUCCESS: { bg: "#63ff6608", border: "#26c129" },
        INITIATED: { bg: "#9797971", border: "#97979770" },
    };

    const colorScheme = !!statusColors[status] ? statusColors[status] : statusColors.INITIATED;

    return (
        <div
            className={clsx(
                `flex h-[24px] items-center justify-center gap-2 rounded-[16px] px-2  py-[2px] pt-[3px] text-center text-[12px] font-[500] text-black-900`,
                css`
                    background-color: ${colorScheme?.bg};
                    border: 1px solid ${colorScheme?.border};
                `,
            )}
        >
            {status}
        </div>
    );
};

export const sampleCodeBlockData = {
    content: {
        python: [
            {
                type: "blockWizard",
                content: {
                    heading: "Code for getting all apps",
                    blocks: [
                        {
                            heading: "Fetch connections",
                            text: `from composio import ComposioToolSet, App

toolset = ComposioToolSet(api_key={{api_key}})
connections = toolset.get_connected_accounts(##Insert your code here##)
print(connections)
`,
                            language: "python",
                        },
                    ],
                },
            },
            {
                type: "content",
                content: (
                    <div className="mt-6 text-[15px] font-[400]">
                        Visit{" "}
                        <ComposioLink
                            className="text-[15px]"
                            href="https://docs.composio.dev/patterns/Auth/connected_account"
                            isExternal={true}
                        >
                            docs
                        </ComposioLink>{" "}
                        to learn more about connected accounts
                    </div>
                ),
            },
        ],
        javascript: [
            {
                type: "blockWizard",
                content: {
                    heading: "Code for getting all apps",
                    blocks: [
                        {
                            heading: "Fetch connections",
                            text: `import {Composio} from "composio-core";
// get composio client
const composio = new Composio({apiKey: {{api_key}}});

const connections = await composio.connectedAccounts.list({##Insert your code here##})
`,
                            language: "javascript",
                        },
                    ],
                },
            },
            {
                type: "content",
                content: (
                    <div className="mt-6 text-[15px] font-[400]">
                        Visit{" "}
                        <ComposioLink
                            className="text-[15px]"
                            href="https://docs.composio.dev/patterns/Auth/connected_account"
                            isExternal={true}
                        >
                            docs
                        </ComposioLink>{" "}
                        to learn more about connected accounts
                    </div>
                ),
            },
        ],
    },
};

const ShowConnectionCode = () => {
    const [showCode, setShowCode] = useState(false);

    const apiKey = useApiKey();

    const { query } = useRouter();

    const connectorId = query.connectorId as string;
    const transformedData = useMemo(() => {
        // transform data of sampleCodeBlockData
        const data = cloneDeep(sampleCodeBlockData);

        for (const language of Object.keys(data.content)) {
            // @ts-expect-error
            for (const languaeItem of data.content[language]) {
                //replace blocks with api key and integration id
                const blocks = languaeItem.content.blocks;
                if (!blocks) continue;
                for (const block of blocks) {
                    block.text = block.text.replace("{{api_key}}", `"${apiKey}"`);
                    let insertContent = ``;
                    if (language === "python") {
                        // if(connectorId) insertContent += `\n  connector_id="${connectorId}",`
                        // if(query.entityId) insertContent += `\n  entityId="${query.entityId}",`
                    } else {
                        if (connectorId) insertContent += `\n  integrationId: "${connectorId}",`;
                        if (query.entityId) insertContent += `\n  entityId: "${query.entityId}",`;
                    }
                    block.text = block.text.replace("##Insert your code here##", insertContent.length > 0 ? `${insertContent}\n` : "");
                }
            }
        }
        return data;
    }, [apiKey, connectorId, query]);

    return (
        <div>
            <CodeButton setShowCode={setShowCode} />

            {showCode && (
                <CodeWInstructionModal title="Fetch connections with Our SDKs" data={transformedData} setOpen={() => setShowCode(false)} />
            )}
        </div>
    );
};
