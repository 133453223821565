import { clsx } from "clsx";

//@ts-ignore
import { css } from "@emotion/css";
import NoSSR from "react-no-ssr";
import { useTriggerDisabledBanner } from "~/hooks/query/triggers";
import { plainBlackBackgroundCSS, scrollBarStyle } from "../constants/style/common";
import { Sidebar } from "../containers/base/Sidebar";

export const SidebarLayoutWrapper = ({ children, isNonSidebarPage }: { children: React.ReactNode; isNonSidebarPage: boolean }) => {
    const { showTriggerDisabledBanner } = useTriggerDisabledBanner();
    return isNonSidebarPage ? (
        <>{children}</>
    ) : (
        <div
            className={clsx(
                "flex overflow-x-hidden text-white",
                plainBlackBackgroundCSS,
                scrollBarStyle,
                css`
                    min-height: calc(100vh - ${showTriggerDisabledBanner ? "60px" : "10px"});
                `,
            )}
        >
            <Sidebar />
            {children}
        </div>
    );
};

export const SSRWrapper = ({ children, isSEOPath }: { children: React.ReactNode; isSEOPath: boolean }) =>
    isSEOPath ? <>{children}</> : <NoSSR>{children}</NoSSR>;
