import * as Sentry from "@sentry/nextjs";
import whyDidYouRender from "@welldone-software/why-did-you-render";
import axios from "axios";
import posthog from "posthog-js";
import React from "react";
import { client } from "~/client/services.gen";
import { getCookie } from "~/utils/cookie";
import { axiosInstance } from "./dataProcessor/api/global";
import { BACKEND_URL } from "./utils/baseUrl";
import { LOCAL_STORAGE_KEYS, storage } from "./utils/localStorage";

export const switchProjectAction = (projectId: string | null) => {
    projectId = storage.get(LOCAL_STORAGE_KEYS.CURRENT_PROJECT, null) || projectId;

    client.setConfig({
        baseURL: BACKEND_URL,
        withCredentials: true,
        headers: projectId
            ? {
                  "x-composio-project-id": projectId,
              }
            : undefined,
    });

    axiosInstance.defaults.headers.common["x-composio-project-id"] = projectId;
    axios.defaults.headers.common["x-composio-project-id"] = projectId;
};
export async function bootstrap() {
    const projectId = storage.get(LOCAL_STORAGE_KEYS.CURRENT_PROJECT, null);
    client.setConfig({
        baseURL: BACKEND_URL,
        withCredentials: true,
        headers: projectId
            ? {
                  "x-composio-project-id": projectId,
              }
            : undefined,
    });

    if (projectId) {
        axiosInstance.defaults.headers.common["x-composio-project-id"] = projectId;
        axios.defaults.headers.common["x-composio-project-id"] = projectId;
    }

    const IS_DEV = process.env.NODE_ENV === "development";

    if (IS_DEV) {
        whyDidYouRender(React, {
            trackAllPureComponents: true,
        });
    }

    if (typeof window !== "undefined") {
        const posthogKey = process.env.NEXT_PUBLIC_POSTHOG_KEY;
        if (!posthogKey) {
            return;
        }

        posthog.init(posthogKey, {
            api_host: `${window.location.origin}/ingest`,
            ui_host: "https://app.posthog.com",

            segment: window?.analytics, // Pass window.analytics here - NOTE: `window.` is important
            capture_pageview: false, // You want this false if you are going to use segment's `analytics.page()` for pageviews
            // When the posthog library has loaded, call `analytics.page()` explicitly.

            loaded: (posthog) => {
                if (process.env.NODE_ENV === "development") posthog.debug();

                return window?.analytics?.page();
            },
            persistence: "cookie",
        });

        const user = {
            email: getCookie("composio_email") as string | undefined,
        };

        Sentry.setUser(user);

        Sentry.init({
            dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
            tracesSampleRate: 1.0,
        });
    }
}
