import { css } from "@emotion/css";
import clsx from "clsx";
import React, { useCallback, useMemo } from "react";
import { FixedSizeList as List } from "react-window";
// @ts-ignore
import InfiniteLoader from "react-window-infinite-loader";
import { Error, LoadingState, NoResult } from "~/ui/containers/base/emptyStates";
import { If } from "~/utils/reactComponent";

export const Table = ({
    rows,
    state,
    headerRenderer,
    rowRenderer,
    onRowClick,
    loadMoreItems = () => Promise.resolve(),
    isMoreItems = false,
    rowContainerHeight = 400,
    rowHeight = 50,
    noResultText,
    gridCSS,
    containerClass,
    emptyStateClass,
    noResultAction = () => {},
}: {
    /* Required props */
    // Array of data rows to be rendered in the table
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    rows: any[];

    // Current state of the table data (loading, error, success, or none)
    state: "loading" | "error" | "success" | "none";

    // Text to display when there are no results
    noResultText: string | React.ReactNode;

    // Function to render the table header
    headerRenderer: () => React.ReactNode;

    // Function to render each row, includes loading state
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    rowRenderer: (row: any, isLoading?: boolean) => React.ReactNode;
    rowContainerHeight?: number;
    rowHeight?: number;
    isMoreItems?: boolean;

    /* Optional props*/
    // Optional click handler for rows
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onRowClick?: (row: any) => void;

    // Function to load more items, used for infinite loading
    loadMoreItems?: (startIndex?: number, stopIndex?: number) => Promise<unknown>;

    // Function to load more items, used for infinite loading
    // Optional CSS for grid layout
    gridCSS?: string;

    // Optional CSS class for the container
    containerClass?: string;

    // Optional CSS class for the empty state display
    emptyStateClass?: string;

    // Function to call when no results are found
    noResultAction?: () => void | undefined;
}) => {
    const isEmptyState = useMemo(
        () => state === "error" || state === "loading" || (state === "success" && rows?.length === 0),
        [state, rows],
    );

    const itemCount = useMemo(() => (rows?.length || 0) + (isMoreItems ? 1 : 0), [rows, isMoreItems]);
    const isItemLoaded = useCallback(
        (index: number) => {
            return index < rows?.length;
        },
        [rows],
    );

    const Row = useCallback(
        ({ index, style }: { index: number; style: React.CSSProperties }) => {
            const row = rows[index];

            return (
                <div
                    style={style}
                    className={clsx(gridCSS, "items-center border-b border-[#E8E8E8] px-5 py-[10px] text-gray-700 hover:bg-gray-50")}
                    onClick={() => {
                        onRowClick?.(row);
                    }}
                >
                    {rowRenderer(row, isItemLoaded(index))}
                </div>
            );
        },
        [rows, gridCSS, onRowClick, rowRenderer, isItemLoaded],
    );

    const scrollbarStyles = useMemo(
        () => ({
            "&::WebkitScrollbar": {
                width: "8px",
            },
            "&::WebkitScrollbarTrack": {
                background: "#f1f1f1",
            },
            "&::WebkitScrollbarThumb": {
                background: "#888",
                borderRadius: "4px",
            },
            "&::WebkitScrollbarThumb:hover": {
                background: "#555",
            },
            scrollbarWidth: "thin",
            scrollbarColor: "#888 #f1f1f1",
        }),
        [],
    );

    return (
        <div
            className={clsx(
                "relative mt-6 max-w-full overflow-hidden rounded-[20px] border border-[#dfdfdf] border-[.8p] bg-[#fff] shadow-sm",
                containerClass,
                css`
                    height: ${rowContainerHeight + 41}px !important;
                `,
            )}
        >
            <div className="sticky top-0 z-10 bg-[#f8f8f8]">
                <div
                    className={clsx(
                        gridCSS,
                        "border-b border-gray-200 px-5 py-[12px] text-[14px] text-sm font-[500] font-[500] uppercase  text-gray-700",
                    )}
                >
                    {headerRenderer()}
                </div>
            </div>
            <If condition={isEmptyState}>
                <div className={emptyStateClass}>
                    {state === "error" && <Error height={rowContainerHeight} />}
                    {state === "loading" && <LoadingState height={rowContainerHeight} text="Fetching data from the server..." />}
                    {state === "success" && rows?.length === 0 && (
                        <NoResult text={noResultText} height={rowContainerHeight} noResultAction={noResultAction} />
                    )}
                </div>
            </If>
            <If condition={!isEmptyState}>
                <InfiniteLoader isItemLoaded={isItemLoaded} itemCount={itemCount} loadMoreItems={loadMoreItems}>
                    {({
                        onItemsRendered,
                        ref,
                    }: {
                        onItemsRendered: (startIndex: number, stopIndex: number) => void;
                        ref: React.RefObject<HTMLDivElement>;
                    }) => (
                        // @ts-expect-error
                        <List
                            height={rowContainerHeight}
                            itemCount={itemCount}
                            itemSize={rowHeight}
                            onItemsRendered={onItemsRendered}
                            ref={ref}
                            width={"100%"}
                            style={scrollbarStyles}
                        >
                            {Row}
                        </List>
                    )}
                </InfiniteLoader>
            </If>
        </div>
    );
};
