import { useQuery } from "@tanstack/react-query";
import { useRouter } from "next/router";
import { QUERY_KEYS } from "~/constants/keys";
import { getConnections } from "~/dataProcessor/api/api";
import { getDuration } from "~/utils/duration";

export const useGetAllConnections = () => {
    const { query } = useRouter();
    const { page = "1", connectorId, status = "", showDisabled = "true", entityId } = query as Record<string, string>;

    const normalizedStatus = status.toLowerCase() === "all" ? "" : status;

    const {
        data: connections,
        isLoading,
        isError,
        isSuccess,
        refetch,
    } = useQuery({
        queryKey: QUERY_KEYS.getConnectionsQueryKey(query),
        queryFn: () =>
            getConnections({
                page: Number(page),
                connectorId: connectorId || undefined,
                status: normalizedStatus,
                showDisabled: showDisabled === "true",
                user_uuid: entityId,
            }),
        staleTime: getDuration({ minutes: 5 }),
        retry: false,
    });

    return { connections, isLoading, isError, isSuccess, refetch };
};
