import { useQuery } from "@tanstack/react-query";
import { SearchIcon } from "lucide-react";
import router, { useRouter } from "next/router";
import { useEffect, useMemo, useState } from "react";
import { getTopEntities } from "~/dataProcessor/api/api";
import { UpdateQuery, UpdateQueryBatch } from "~/dataProcessor/hooks/global/router";
import { Combobox } from "~/design-system/atom/combo-box";
import { Input } from "~/design-system/atom/input";
import { SimpleDropdown } from "~/ui/components/custom/dropdown/simple";

// import { useQuery } from "@tanstack/react-query";
export const TIME_VALUES = [
    {
        name: "30 min",
        value: "30m",
    },
    {
        name: "6 hours",
        value: "6h",
    },
    {
        name: "1 day",
        value: "1d",
    },
    {
        name: "1 week",
        value: "1w",
    },
    {
        name: "1 month",
        value: "1month",
    },
    {
        name: "1 year",
        value: "1y",
    },
];

export const STATUS_VALUES = [
    {
        name: "All",
        value: "all",
    },
    {
        name: "Execution success",
        value: "success",
    },
    {
        name: "Execution error",
        value: "error",
    },
];

type FilterChangeParams = {
    status?: string;
    app?: string;
    search?: string;
    time?: string;
};

export const ActionNTriggerFilterBar = ({ onChange }: { onChange?: (params: FilterChangeParams) => void }) => {
    const router = useRouter();

    const updateQuery = UpdateQuery();
    const updateQueryBatch = UpdateQueryBatch();

    const { time, status, search } = router.query;

    useEffect(() => {
        onChange?.({
            status: status as string | undefined,
            search: search as string | undefined,
            time: time as string,
        });
        if ((router.isReady && !time) || !status) {
            updateQueryBatch({
                time: (time as string) || TIME_VALUES[3]!.value,
                status: (status as string) || STATUS_VALUES[0]!.value,
            });
        }
    }, [router.isReady]);

    return (
        <div className="mt-4 flex items-center justify-between text-[13px] text-black-300">
            <div className="flex items-center gap-3">
                <div className="mr-1 min-w-[fit-content] text-[13px]">Filter: </div>
                <div className="flex gap-4">
                    <SimpleDropdown
                        options={TIME_VALUES}
                        onChange={(value) => {
                            updateQueryBatch({
                                time: value as string,
                            });
                        }}
                        value={time as string}
                        placeholder="Select time"
                        className="min-w-[110px] "
                    />
                    <SimpleDropdown
                        options={STATUS_VALUES}
                        onChange={(value) => {
                            updateQueryBatch({
                                status: value as string,
                            });
                        }}
                        value={status as string}
                        placeholder="Select execution status"
                        className=" min-w-[172px] "
                    />
                    {/* //TODO: add entity input */}
                    <EntityIdFilter />
                </div>
            </div>
            <div>
                <Input
                    className="min-w-[300px]"
                    placeholder="Search by name, app name, connection"
                    type="text"
                    size={32}
                    onKeyDown={(e) => {
                        if (e.key === "Enter") {
                            updateQuery("search", (e.target as HTMLInputElement).value);
                        }
                    }}
                    defaultValue={search || ""}
                    icon={<SearchIcon height={14} width={14} color="grey" />}
                />
            </div>
        </div>
    );
};

export const EntityIdFilter = () => {
    const updateQueryBatch = UpdateQueryBatch();
    const [inputValue, setInputValue] = useState("");

    const entityIdFromQuery = router.query.entityId as string;

    // Add support for app or connectorId
    const {
        data: entities,
        isLoading,
        isFetched,
    } = useQuery({
        queryKey: ["entities", inputValue],
        queryFn: () => getTopEntities(inputValue),
        staleTime: 1000 * 60 * 60 * 24 * 7,
    });

    const setEntityId = (value: string) => {
        updateQueryBatch({
            entityId: value,
        });
    };

    const enttiesToShow = useMemo(() => {
        const e =
            // @ts-ignore
            entities?.data
                // @ts-ignore
                ?.filter((entity) => entity !== "undefined")
                // @ts-ignore
                .map((entity) => ({
                    value: entity,
                    label: entity || "Not defined",
                })) || [];

        return e;
    }, [entities, entityIdFromQuery]);

    // @ts-ignore
    if (!isLoading && entities?.data?.length === 0 && !inputValue) return null;

    return (
        <Combobox
            data={enttiesToShow}
            onInputChange={setInputValue}
            onChange={setEntityId}
            selectedValue={entityIdFromQuery}
            isLoading={!isFetched}
            content={{
                emptyText: "No results found",
                loadingText: "Loading...",
                placeholder: "Entity",
            }}
        />
    );
};
