import { useMutation, useQuery } from "@tanstack/react-query";
import clsx from "clsx";
import { useAtom } from "jotai";
import { ChevronDownIcon, ChevronUpIcon, InfoIcon, PlusIcon, SendHorizontalIcon } from "lucide-react";
import { useRouter } from "next/router";
import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
// @ts-expect-error
import { ShimmerTitle } from "react-shimmer-effects";
import JsonView from "react18-json-view";
import backend from "src/client/sdk";
import { z } from "zod";
import { getActionInfo, getConnections, getExampleActionInput, ZActionItem } from "~/dataProcessor/api/api";
import { useApiKey } from "~/dataProcessor/hooks/user";
import { Button } from "~/design-system/atom/button";
import { CodeBlockComponent } from "~/design-system/atom/CodeBlockComponent";
import { Link } from "~/design-system/atom/link";
import { Textarea } from "~/design-system/atom/textarea";
import { useToast } from "~/design-system/atom/use-toast";
import { useGetAppActions } from "~/hooks/query/apps";
import { useGetAllIntegrations } from "~/hooks/query/tools";
import { useGetConnectorInfo } from "~/pages/connector/[connectorId]";
import { ErrorBoundary } from "~/ui/components/base/errorBoundary";
import { LoadingIcon } from "~/ui/components/base/loadingIcon";
import { SimpleDropdown } from "~/ui/components/custom/dropdown/simple";
import { isNilOrEmpty } from "~/utils/js";
import { If } from "~/utils/reactComponent";
import { formatActionName } from "~/utils/string";
import { JSONSchemForm } from "../../../../containers/form_new/form";
import { NoActionAppCodeInstruction, useAppInfo } from "../../appScreen";
import { SelectorButton } from "../addConnectedAccount";
import { updateWizardFlowAtom, wizardFlowAtoms } from "../wizard";

export const executeAction = async ({
    actionName,
    connectionID,
    input,
    appName,
}: {
    actionName: string;
    input: Record<string, unknown>;
    connectionID?: string;
    appName?: string;
}) => {
    const response = await backend.actionsV2.executeActionV2({
        body: {
            appName,
            connectedAccountId: connectionID,
            input: input,
        },
        path: {
            actionId: actionName,
        },
    });

    if (response?.error) {
        throw response?.error;
    }

    return response;
};

export const RunActionPopup = ({
    actionName,
    appName,
    isLocal = false,
    connectedAccountId,
}: {
    actionName?: string;
    appName: string;
    isLocal: boolean;
    connectedAccountId: string;
}) => {
    // State
    const [selectedAction, setSelectedAction] = useState(actionName);
    const [formData, setFormData] = useState("");

    // Data fetching
    const { actionsData: appActions, isActionsLoading } = useGetAppActions(appName, "");

    const { appInfo } = useAppInfo(appName);
    const {
        data: actionData,
        isLoading: isActionLoading,
        isError: isActionError,
        error: actionError,
    } = useQuery(["actionInfo", selectedAction], () => getActionInfo(selectedAction!), {
        enabled: !!selectedAction,
        retry: 2,
        onError: (error) => {
            console.error("Failed to fetch action info:", error);
        },
        refetchOnWindowFocus: false,
    });

    // Derived state
    const isLoading = isActionsLoading || isActionLoading;
    const isNoActionApp = !isActionsLoading && (appActions?.items === undefined || appActions?.items?.length === 0);

    // Effects
    useEffect(() => {
        // Set initial selected action
        if (appActions?.items && appActions.items.length > 0 && !actionName) {
            const importantAction = appActions.items.find((action) => action.tags?.includes("important"));
            setSelectedAction(importantAction?.name || appActions.items[0]?.name);
        }
    }, [appActions, actionName]);

    // Loading state
    if (isLoading && !isNoActionApp) {
        console.log("isLoading", isLoading);
        return (
            <div className="grid grid-cols-7 gap-10 px-10 py-5">
                <div className="col-span-3">
                    <ShimmerTitle line={7} gap={10} variant={"secondary"} />
                </div>
                <div className="col-span-4">
                    <ShimmerTitle line={7} gap={10} variant={"secondary"} />
                </div>
            </div>
        );
    }

    // Error state
    if (isActionError) {
        return <div className="px-10 py-5">Error loading action information: {(actionError as Error)?.message || "Please try again."}</div>;
    }

    // No actions state
    if (isNoActionApp) {
        return (
            <div className="px-10 py-5 max-w-[70%]">
                <div className="text-[15px] font-[500] text-black-300 mb-4">
                    This app has no actions. You can add a custom action to continue.
                </div>
                <NoActionAppCodeInstruction appName={appName} />
            </div>
        );
    }

    // Success state
    return (
        <React.Fragment>
            <div className="grid min-h-[calc(100%-100px)] grid-cols-7 gap-10 border-t-[#64646434] py-[0px] font-gilroy text-[13px] text-[#ecececa2]">
                <div className="col-span-3 pb-8 pl-11 pt-5">
                    <ActionPlayground
                        appName={appName}
                        actionData={actionData as any}
                        isNoAuthAction={appInfo?.no_auth}
                        formData={formData}
                        setFormData={setFormData}
                        isLocal={isLocal}
                        connectedAccountId={connectedAccountId}
                        setSelectedAction={setSelectedAction}
                    />
                </div>
                <div className="col-span-4 px-4 pt-5 text-black-300">
                    <ActionDetails actionData={actionData as any} />
                </div>
            </div>
        </React.Fragment>
    );
};

export const Accordion = ({
    title,
    children,
    initialOpen = false,
}: {
    title: string;
    children: React.ReactNode;
    initialOpen?: boolean;
}) => {
    const [isOpen, setIsOpen] = useState(initialOpen);

    return (
        <div className={`accordion border-b border-[#eaeaea]`}>
            <div
                onClick={() => setIsOpen(!isOpen)}
                className="  flex cursor-pointer items-start justify-between gap-[12px] pb-[12px] pt-[18px] text-[15px] font-[500] text-[#000] md:text-[13px] "
            >
                <div>{title}</div>
                <div>
                    {!isOpen ? (
                        <ChevronDownIcon height={16} width={16} color="#000" />
                    ) : (
                        <ChevronUpIcon height={16} width={16} color="#000" />
                    )}
                </div>
            </div>
            {isOpen && <div className="accordion-content pb-[18px]">{children}</div>}
        </div>
    );
};

// blocks
const getCodeByLang = (action_id: string, lang: string, framework: string, apiKey: string) => {
    if (lang === "Python") {
        if (framework === "OpenAI") {
            return {
                heading: "Use OpenAI Assistant API with Composio",
                blocks: [
                    {
                        heading: "🚀 Importing Libraries",
                        text: `from openai import OpenAI
from composio_openai import ComposioToolSet, App`,
                        language: "python",
                        isCollapsed: true,
                    },
                    {
                        heading: "🛠️ Setting up Assistant and Thread",
                        text: `openai_client = OpenAI()

# Initialise the Composio Tool Set
composio_toolset = ComposioToolSet(api_key="${apiKey}")
actions = composio_toolset.get_tools(actions=['${action_id}'])

## Add your task description here
my_task = "your task description here"

assistant_instruction = "You are a super intelligent personal assistant"
assistant = openai_client.beta.assistants.create(
  name="AI Assistant",
  instructions=assistant_instruction,
  model="gpt-4-turbo-preview",
  tools=actions, # type: ignore
)

thread = openai_client.beta.threads.create()
message = openai_client.beta.threads.messages.create(thread_id=thread.id,role="user",content=my_task)
run = openai_client.beta.threads.runs.create(thread_id=thread.id,assistant_id=assistant.id)`,
                        language: "python",
                        isCollapsed: false,
                    },
                    {
                        heading: "🔧 Handling Tool Calls",
                        text: `response_after_tool_calls = composio_toolset.wait_and_handle_assistant_tool_calls(
    client=openai_client,
    run=run,
    thread=thread,
)

print(response_after_tool_calls)`,
                        language: "python",
                        isCollapsed: false,
                    },
                ],
            };
        }

        if (framework === "CrewAI") {
            return {
                heading: "Use CrewAI with Composio",
                blocks: [
                    {
                        heading: "🚀 Importing Libraries and Setting Up",
                        text: `from crewai import Agent, Task, Crew
from langchain_openai import ChatOpenAI
from composio_crewai import ComposioToolSet, Action, App`,
                        language: "python",
                        isCollapsed: true,
                    },
                    {
                        heading: "🤖 Defining Agent and Task",
                        text: `composio_toolset = ComposioToolSet(api_key="${apiKey}")
tools = composio_toolset.get_tools(actions=['${action_id}'])

# Define agent
crewai_agent = Agent(
    role="Sample Agent",
    goal="""You are an AI agent that is responsible for taking actions based on the tools you have""",
    backstory=(
        "You are AI agent that is responsible for taking actions based on the tools you have"
    ),
    verbose=True,
    tools=tools,
    llm=ChatOpenAI(),
)
task = Task(
    description="your task description here",
    agent=crewai_agent,
    expected_output=""
)`,
                        language: "python",
                        isCollapsed: false,
                    },
                    {
                        heading: "🚀 Creating Crew and Executing Task",
                        text: `my_crew = Crew(agents=[crewai_agent], tasks=[task])

result = my_crew.kickoff()
print(result)`,
                        language: "python",
                        isCollapsed: false,
                    },
                ],
            };
        }

        if (framework === "Letta") {
            return {
                heading: "Use Letta with Composio",
                blocks: [
                    {
                        heading: "🚀 Importing Libraries and Setting Up",
                        text: `from letta import EmbeddingConfig, LLMConfig, create_client
from composio_langchain import Action
## Set OPENAI_API_KEY and COMPOSIO_API_KEY in env variables
`,
                        language: "python",
                        isCollapsed: true,
                    },
                    {
                        heading: "🤖 Fetching Tool with Composio and Creating Agent",
                        text: `client = create_client()

# set automatic defaults for LLM/embedding config
client.set_default_llm_config(LLMConfig.default_config(model_name="gpt-4"))
client.set_default_embedding_config(EmbeddingConfig.default_config(model_name="text-embedding-ada-002"))

composio_tool = client.load_composio_tool(Action.${action_id.toUpperCase()})
agent_state = client.create_agent()

client.add_tool_to_agent(
    agent_id=agent_state.id,
    tool_id=composio_tool.id
)   
`,
                        language: "python",
                        isCollapsed: false,
                    },
                    {
                        heading: "🚀 Sending message to Agent",
                        text: `response = client.send_message(agent_id=agent_state.id, role="user", message="your task description here")
print("Usage", response.usage)
print("Agent messages", response.messages)
`,
                        language: "python",
                        isCollapsed: false,
                    },
                ],
            };
        }

        if (framework === "LlamaIndex") {
            return {
                heading: "Use LlamaIndex with Composio",
                blocks: [
                    {
                        heading: "🚀 Importing Libraries and Setting Up",
                        text: `import dotenv
from llama_index.llms.openai import OpenAI
from llama_index.core.llms import ChatMessage
from llama_index.core.agent import FunctionCallingAgentWorker
from composio_llamaindex import Action, ComposioToolSet`,
                        language: "python",
                        isCollapsed: true,
                    },
                    {
                        heading: "🤖 Configuring Agent",
                        text: `dotenv.load_dotenv()

llm = OpenAI(model="gpt-4o")

composio_toolset = ComposioToolSet(api_key="${apiKey}")
tools = composio_toolset.get_tools(actions=['${action_id}'])

prefix_messages = [
    ChatMessage(
        role="system",
        content=(
            "You are now a integration agent, and what  ever you are requested, you will try to execute utilizing your tools."
        ),
    )
]

agent = FunctionCallingAgentWorker(
    tools=tools,
    llm=llm,
    prefix_messages=prefix_messages,
    max_function_calls=10,
    allow_parallel_tool_calls=False,
    verbose=True,
).as_agent()`,
                        language: "python",
                        isCollapsed: false,
                    },
                    {
                        heading: "🚀 Executing Task",
                        text: `response = agent.chat("your task description here")
print("Response:", response)`,
                        language: "python",
                        isCollapsed: false,
                    },
                ],
            };
        }

        if (framework === "LangChain") {
            return {
                heading: "Use LangChain with Composio",
                blocks: [
                    {
                        heading: "🚀 Importing Libraries and Setting Up",
                        text: `from langchain.agents import create_openai_functions_agent, AgentExecutor
from langchain import hub
from langchain_openai import ChatOpenAI
from composio_langchain import ComposioToolSet, Action, App`,
                        language: "python",
                        isCollapsed: true,
                    },
                    {
                        heading: "🤖 Creating Agent and Executor",
                        text: `llm = ChatOpenAI()
prompt = hub.pull("hwchase17/openai-functions-agent")

composio_toolset = ComposioToolSet(api_key="${apiKey}")
tools = composio_toolset.get_tools(actions=['${action_id}'])

agent = create_openai_functions_agent(llm, tools, prompt)
agent_executor = AgentExecutor(agent=agent, tools=tools, verbose=True)`,
                        language: "python",
                        isCollapsed: false,
                    },
                    {
                        heading: "🚀 Executing Task",
                        text: `task = "your task description here"
result = agent_executor.invoke({"input": task})
print(result)`,
                        language: "python",
                        isCollapsed: false,
                    },
                ],
            };
        }
    }

    if (lang === "JS") {
        if (framework === "OpenAI") {
            return {
                heading: "Use OpenAI Assistant API with Composio",
                blocks: [
                    {
                        heading: "🚀 Importing Libraries and Setting Up",
                        text: `import { OpenAI } from "openai";
import { OpenAIToolSet } from "composio-core";`,
                        language: "javascript",
                        isCollapsed: true,
                    },
                    {
                        heading: "🛠️ Creating Assistant and Thread",
                        text: `const openai_client = new OpenAI({apiKey: process.env.OPENAI_API_KEY});
const composio_toolset = new OpenAIToolSet({
    apiKey: "${apiKey}"
});

const tools = await composio_toolset.getTools({
    actions: ["${action_id}"]
});


const instruction = "your task description here";

// Creating a chat completion request to the OpenAI model
const response = await openai_client.chat.completions.create({
    model: "gpt-4-turbo",
    messages: [{ role: "user", content: instruction }],
    tools: tools,
    tool_choice: "auto",
});

const tool_response = await composio_toolset.handleToolCall(response);

console.log(tool_response);

`,
                        language: "javascript",
                        isCollapsed: false,
                    },
                ],
            };
        }

        if (framework === "LangChain") {
            return {
                heading: "Use LangChain with Composio",
                blocks: [
                    {
                        heading: "🚀 Importing Libraries and Setting Up",
                        text: `import { ChatOpenAI } from "@langchain/openai";
import { createOpenAIFunctionsAgent, AgentExecutor } from "langchain/agents";
import { LangchainToolSet } from "composio-core";
import { pull } from "langchain/hub";`,
                        language: "javascript",
                        isCollapsed: true,
                    },
                    {
                        heading: "🤖 Creating Agent and Tools",
                        text: `const llm = new ChatOpenAI({
  model: "gpt-4-turbo",
  temperature: 0,
});

const prompt = await pull("hwchase17/openai-functions-agent");

const toolset = new LangchainToolSet({ apiKey: "${apiKey}" });
const tools = await toolset.getTools({ actions: ["${action_id}"] });

const agent = await createOpenAIFunctionsAgent({llm, tools, prompt});`,
                        language: "javascript",
                        isCollapsed: false,
                    },
                    {
                        heading: "🚀 Executing Task",
                        text: `const agentExecutor = new AgentExecutor({ agent, tools, verbose: true });

const response = await agentExecutor.invoke({ input: "your task description here" });
console.log(response);`,
                        language: "javascript",
                        isCollapsed: false,
                    },
                ],
            };
        }
    }

    return [];
};

const LanguageAndFrameworkSelector = memo(
    ({
        selectedLanguage,
        selectedFramework,
        setSelectedLanguage,
        setSelectedFramework,
    }: {
        selectedLanguage: string;
        selectedFramework: string;
        setSelectedLanguage: (lang: string) => void;
        setSelectedFramework: (framework: string) => void;
    }) => {
        const language = useMemo(() => ["JS", "Python"], []);

        const framework = useMemo(
            () => ({
                JS: ["LangChain", "OpenAI"],
                Python: ["CrewAI", "LangChain", "Letta", "LlamaIndex", "OpenAI", "Langchain-OpenAI"],
            }),
            [],
        );

        useEffect(() => {
            if (selectedLanguage === "JS") {
                setSelectedFramework("LangChain");
            }

            if (selectedLanguage === "Python") {
                setSelectedFramework("CrewAI");
            }
        }, [selectedLanguage]);

        const frameworkList = framework[selectedLanguage as keyof typeof framework] || [];
        const [showAllFrameworks] = useState(false);
        const displayedFrameworks = showAllFrameworks ? frameworkList : frameworkList.slice(0, 4);

        return (
            <div className="mb-[32px] flex items-center justify-between md:flex-col md:items-start md:gap-[20px]">
                <div>
                    <div className="font-cera text-[13px] font-[500] text-[#000] md:text-[13px]">Language</div>
                    <div className="mt-[12px] flex gap-[8px]">
                        {language?.map((lang) => (
                            <SelectorButton
                                key={lang}
                                isSelected={lang === selectedLanguage}
                                setSelected={setSelectedLanguage}
                                name={lang}
                                logo={lang === "Python" ? "/assets/icon/python.svg" : "/assets/icon/js.svg"}
                                logoSize={lang === "python" ? 28 : 14}
                            />
                        ))}
                    </div>
                </div>
                {!!frameworkList && frameworkList.length > 0 && (
                    <div>
                        <div className="font-cera text-[13px] font-[500] text-[#000] md:text-[13px]">Framework</div>
                        <div className="mt-[12px] flex flex-wrap gap-[8px]">
                            {displayedFrameworks.slice(0, 3).map((framework) => (
                                <SelectorButton
                                    key={framework}
                                    isSelected={framework === selectedFramework}
                                    setSelected={setSelectedFramework}
                                    name={framework}
                                />
                            ))}
                            {frameworkList.length > 3 && !showAllFrameworks && (
                                <SimpleDropdown
                                    options={displayedFrameworks.slice(3, 100).map((framework) => ({
                                        name: framework,
                                        value: framework,
                                    }))}
                                    value={selectedFramework}
                                    onChange={(e) => setSelectedFramework(e as string)}
                                    placeholder="+ More"
                                    className="h-[30px] w-[128px] rounded-[32px]"
                                />
                            )}
                        </div>
                    </div>
                )}
            </div>
        );
    },
);

LanguageAndFrameworkSelector.displayName = "LanguageAndFrameworkSelector";

export const CodeBlock = React.memo(({ unique_id }: { unique_id: string }) => {
    const [selectedLanguage, setSelectedLanguage] = useState("Python");
    const [selectedFramework, setSelectedFramework] = useState("LangChain");

    useEffect(() => {
        setSelectedFramework("LangChain");
    }, [selectedLanguage]);

    const apiKey = useApiKey();

    // @ts-ignore
    const codeBlocks = getCodeByLang(unique_id, selectedLanguage, selectedFramework, apiKey).blocks || [];

    // @ts-ignore
    const heading = getCodeByLang(unique_id, selectedLanguage, selectedFramework, apiKey).heading || "";

    return (
        <>
            <LanguageAndFrameworkSelector
                selectedLanguage={selectedLanguage}
                selectedFramework={selectedFramework}
                setSelectedLanguage={setSelectedLanguage}
                setSelectedFramework={setSelectedFramework}
            />
            <CodeBlockComponent blocks={codeBlocks} topBarContent={heading} language={selectedLanguage} />
        </>
    );
});

CodeBlock.displayName = "CodeBlock";
export const ActionDetails = ({ actionData }: { actionData: z.infer<typeof ZActionItem> }) => {
    return (
        <>
            <CodeBlock unique_id={actionData.name} />
        </>
    );
};

export const ActionPlayground = React.memo(
    ({
        actionData,
        isNoAuthAction,
        isLocal = false,
        appName,
        setSelectedAction,
        connectedAccountId,
    }: {
        actionData: z.infer<typeof ZActionItem>;
        isNoAuthAction?: boolean;
        formData: string;
        setFormData: (input: string) => void;
        isLocal: boolean;
        appName: string;
        setSelectedAction: (action: string) => void;
        connectedAccountId: string;
    }) => {
        const [wizardFlowState] = useAtom(wizardFlowAtoms);
        const { data: integrationsData } = useGetAllIntegrations(1, appName);
        const [, setWizardFlow] = useAtom(updateWizardFlowAtom);
        const { actionsData: appActions } = useGetAppActions(appName, "");

        const formSchema = useMemo(() => {
            return actionData.parameters;
        }, [actionData]);

        const { query } = useRouter();

        const [formData, setFormData] = useState({});
        const { toast } = useToast();

        const { connectorId } = query;

        const openActionData = useCallback(() => {
            const input = JSON.stringify(actionData?.parameters || "{}", null, 2);
            const output = JSON.stringify(actionData?.response || "{}", null, 2);
            const description = actionData?.description || "";

            const data = `
##Description:

${description}

##Input:

${input}

##Output:

${output}
`;

            // open in new tab
            const newWindow = window.open("", "_blank");
            if (newWindow) {
                newWindow.document.write(`<pre>${data}</pre>`);
                newWindow.document.close();
            }
        }, [actionData]);

        //@ts-ignore
        const { data, isLoading: isLoadingConnections } = useQuery(["actions", "connection", actionData.appName], () =>
            getConnections({
                ...(typeof connectorId === "string" && !!connectorId
                    ? {
                          connectorId,
                      }
                    : {
                          appName: actionData.appName,
                      }),
                status: "ACTIVE",
            }),
        );

        const [selectedConnectedId, setSelectedConnectedId] = useState(connectedAccountId || data?.items?.[0]?.id);

        useEffect(() => {
            if ((data?.items?.length ?? 0) > 0) {
                setSelectedConnectedId(connectedAccountId || data?.items?.[0]?.id);
            }
        }, [data, connectedAccountId]);

        const [response, setResponse] = useState("");

        const { mutate: sendActionRequest, isLoading: isLoadingTestRequest } = useMutation({
            mutationFn: executeAction,
            onSuccess: (response) => {
                setResponse(JSON.stringify(response.data, null, 2));
            },
            onError: (err: any) => {
                toast({
                    title: "Error",
                    description: JSON.stringify(err?.message),
                    variant: "destructive",
                });
                const errPayload = {
                    message: JSON.stringify(err?.message),
                    response: {
                        headers: err.config?.headers,
                        data: err.config?.data,
                        url: err.config?.url,
                        method: err.config?.method,
                    },
                };

                setResponse(JSON.stringify(errPayload, null, 2));
            },
        });

        const router = useRouter();

        const connectionExists = (data?.items?.length || 0) > 0;

        const isConnectedAccountPage = !!router.query.connectionId;
        const isConnectorPage = !!router.query.connectorId;
        const { data: connectorData } = useGetConnectorInfo(router.query.connectorId as string);

        const onAddAccount = useCallback(() => {
            window.open(`/connector/${connectorData?.id}?addConnectorWizard=true`, "_blank");
        }, [connectorData?.id]);

        const [textareaValue, setTextareaValue] = useState("");

        const [initData, setInitData] = useState({});
        const { mutate: generateInput, isLoading } = useMutation({
            mutationFn: (input: string) => getExampleActionInput(actionData.name, input),
            onSuccess: (response) => {
                setInitData({
                    ...response.arguments,
                });
            },
            onError: (err: any) => {
                toast({
                    title: "Error",
                    description: JSON.stringify(err?.message),
                    variant: "destructive",
                });
            },
        });

        const doesWizardFlowHaveIntegrationId = (integrationsData?.items?.length ?? 0) > 0;

        const haveZeroPayload = useMemo(() => Object.keys(Object.keys(formSchema?.properties || {})).length === 0, [formSchema]);

        const firstIntegration = integrationsData?.items?.[0]?.id;

        const handleWizardFlowUpdate = useCallback(() => {
            if (doesWizardFlowHaveIntegrationId) {
                setWizardFlow({
                    step: 2,
                    data: {
                        second: {
                            integrationID: wizardFlowState.second.integrationID || (firstIntegration as string),
                        },
                    },
                });
            } else {
                setWizardFlow({
                    step: 1,
                    data: {
                        first: {
                            appName: appName,
                        },
                    },
                });
            }
        }, [doesWizardFlowHaveIntegrationId, wizardFlowState.second.integrationID, firstIntegration, appName, setWizardFlow]);

        const handleActionExecution = useCallback(() => {
            if (!selectedConnectedId && !isNoAuthAction) {
                toast({
                    title: "Error",
                    description: "Please select a connection",
                    variant: "destructive",
                });
                return;
            }

            const required = formSchema?.required;
            const parsedInput = Object.entries(formData).reduce(
                (acc, [key, value]) => {
                    //@ts-ignore
                    if (value.value !== undefined && value.value !== null && value.value !== "") {
                        //@ts-ignore
                        acc[key] = value.value;
                    }
                    return acc;
                },
                {} as Record<string, unknown>,
            );

            const missingKeys =
                required?.filter((key: string) => {
                    const value = parsedInput[key];
                    const isValueEmpty = isNilOrEmpty(value);
                    return isValueEmpty;
                }) || [];

            if (missingKeys.length > 0) {
                toast({
                    title: "Error",
                    description: `${missingKeys.join(", ")} is required`,
                    variant: "destructive",
                });
                return;
            }

            sendActionRequest({
                actionName: actionData.name || "",
                input: parsedInput,
                appName: actionData.appKey,
                ...(!isNoAuthAction && {
                    connectionID: selectedConnectedId,
                }),
            });
        }, [selectedConnectedId, isNoAuthAction, formSchema, formData, actionData, sendActionRequest, toast]);

        return (
            <>
                <If condition={isLoadingConnections}>
                    <div className="">
                        <ShimmerTitle line={7} gap={10} variant={"secondary"} />
                    </div>
                </If>
                <div className="mt-2 flex items-center justify-between">
                    <div className="flex gap-1 font-cera text-[15px] font-[400] leading-none text-black-300">Action</div>
                    <div className="flex items-center gap-2">
                        <SimpleDropdown
                            className="w-[280px]" // @ts-ignore
                            options={appActions.items?.map((item) => ({
                                name: formatActionName(item.name),
                                value: item.name,
                            }))}
                            value={actionData?.name}
                            onChange={(e) => {
                                // @ts-ignore
                                setSelectedAction?.(e);
                            }}
                        />
                        <Link onClick={openActionData} isExternal={true} className="mt-[1px]">
                            Schema
                        </Link>
                    </div>
                </div>
                <If condition={!isLoadingConnections}>
                    {!connectionExists && !isNoAuthAction && isConnectorPage && (
                        <div className="text-black-3000 my-7 flex w-full  flex-col items-start gap-[12px] rounded-[12px] text-center">
                            <div className="mb-[12px] text-left text-[15px] font-[500] leading-[170%] text-black-400">
                                No connected account for this Integrations.
                                <br /> Please add a new connected account.
                            </div>
                            <Button variant="default" onClick={onAddAccount} className="max-w-[fit-content]">
                                Add account
                            </Button>
                        </div>
                    )}
                    {!connectionExists && !isNoAuthAction && !isConnectorPage && (
                        <div className="mt-[24px] min-w-full overflow-hidden rounded-[16px] border-[0.5px] border-[#216dc4] bg-[#5ec7ff03] px-5 py-4 text-[#0c5973]">
                            <div className="mb-2 text-[16px] font-[600]">
                                You need to setup a {doesWizardFlowHaveIntegrationId ? "connection" : "integration"} to perform actions.
                            </div>
                            <div className=" mb-4 font-cera text-[14px]  font-[400] text-black-300">
                                {doesWizardFlowHaveIntegrationId
                                    ? "No connection for this app exist. Please setup connection to perform actions."
                                    : "No integration for this app exist. Please setup integration to perform actions."}
                            </div>
                            <Button size={36} variant={"default"} onClick={handleWizardFlowUpdate} className="flex max-w-[fit-content]">
                                <PlusIcon className="mr-1" height={18} width={18} strokeWidth={1.2} />
                                {doesWizardFlowHaveIntegrationId ? "Add connection" : "Add integration"}
                            </Button>
                        </div>
                    )}
                    {!isConnectedAccountPage && connectionExists && !isNoAuthAction && (
                        <div className="flex items-center justify-between pt-4">
                            <div className="font-cera text-[14px] font-[500] leading-none text-black-300">Select Account</div>
                            <SimpleDropdown
                                className="w-[320px]"
                                options={data?.items?.map((item: { id: string; name: string }) => ({
                                    name: item.id,
                                    value: item.id,
                                }))}
                                value={selectedConnectedId}
                                onChange={(e) => {
                                    setSelectedConnectedId(e);
                                }}
                                placeholder="Select account"
                            />
                        </div>
                    )}
                    <If condition={(!!connectionExists || !!isNoAuthAction) && !haveZeroPayload && !isLocal}>
                        <div className="mt-6  flex justify-between">
                            <div className="min-w-[200px]">
                                <div className="text-[15px] font-[600] leading-[170%] text-black-300">🪄 Natural Language Execution </div>
                                <div className="mb-3 text-[13px] font-[400] leading-[170%] text-black-800">
                                    Generate inputs using function calling
                                </div>
                            </div>
                            <Button
                                onClick={() => {
                                    generateInput(textareaValue);
                                }}
                                variant="black"
                                disabled={textareaValue.length === 0 || isLoading}
                                size={36}
                                className="ml-[auto] mt-2 flex  h-[28px] w-[fit-content] gap-1 rounded-[10px]  px-2 text-[13px] text-white"
                            >
                                Generate <SendHorizontalIcon className="ml-1" height={14} width={14} />
                            </Button>
                        </div>
                        <div className="flex items-center justify-end gap-4 leading-none">
                            <Textarea
                                className="mb-3 h-[120px] text-black-300"
                                value={textareaValue}
                                disabled={isLoading}
                                onChange={(e) => setTextareaValue(e.target.value)}
                            />
                        </div>
                        <hr className="my-4" />
                    </If>
                    <If condition={isLoading}>
                        <div className="mb-4 flex items-center gap-2 text-black-500 ">
                            <div>
                                <LoadingIcon height={16} width={16} className="animate-spin" />
                            </div>
                            <div className="text-[14px]">Generating parameters for action...</div>
                        </div>
                    </If>
                    {response?.length > 0 && (
                        <div className={clsx(" 2 mb-2 w-full items-center justify-end gap-4 text-[13px] leading-none text-black-500")}>
                            <div className="mb-6 mt-2 font-cera text-[14px] font-[500] leading-[170%] text-black-300">
                                Response from {formatActionName(actionData.name)} action
                            </div>
                            <JsonView
                                src={JSON.parse(response || "{}")}
                                className=" max-w-[520px] overflow-hidden rounded-[16px] border-[1px] border-grey-300 !bg-[#fff] p-4"
                            />
                        </div>
                    )}
                    {isLoadingTestRequest && (
                        <div className="mb-4 flex items-center gap-2 text-black-500 ">
                            <div>
                                <LoadingIcon height={16} width={16} className="animate-spin" />
                            </div>
                            <div className="text-[14px]">Running action...</div>
                        </div>
                    )}
                    {isLocal && (
                        <div className="flex items-center gap-2 mt-5 mb-5 rounded-md border border-orange-400 bg-orange-50 p-2 text-orange-800">
                            <InfoIcon className="h-4 w-4" />
                            <div>A local action cannot be executed from action playground</div>
                        </div>
                    )}
                    <If condition={!!connectionExists || !!isNoAuthAction}>
                        <div className={clsx("mb-3 flex items-center justify-between", haveZeroPayload && "mt-6")}>
                            <div className="mt-2 min-w-[fit-content]">
                                <div className="text-[15px] font-[600] leading-[170%] text-black-300">📝 Action Parameters</div>
                                <div className="mt-1 text-[13px] font-[400] leading-[150%] text-black-800">
                                    Specify the required inputs for this action
                                </div>
                            </div>
                            <div className="flex w-full items-center justify-end gap-4 leading-none">
                                {isLocal ? null : (
                                    <Button
                                        className="ml-[auto] mt-2 flex  h-[28px] w-[fit-content] gap-1 rounded-[10px]  px-4 text-[13px] text-white"
                                        variant={"default"}
                                        disabled={isLoadingTestRequest}
                                        onClick={handleActionExecution}
                                    >
                                        {isLoadingTestRequest ? "Running..." : "Run"}
                                    </Button>
                                )}
                            </div>
                        </div>
                        <div className={clsx("mb-6  mt-2 w-full items-center justify-end gap-6 text-left leading-none text-black-400")}>
                            {Object.keys(Object.keys(formSchema?.properties || {})).length === 0 ? (
                                <div className="pt-2 text-[14px]">No parameters are required for this action</div>
                            ) : null}
                            <If condition={Object.keys(formSchema?.properties || {}).length > 0}>
                                <ErrorBoundary fallback={<div>{`Something went wrong. We'll work on getting this fixed.`}</div>}>
                                    <JSONSchemForm
                                        initData={initData} // @ts-ignore
                                        form={formSchema}
                                        formData={formData}
                                        setFormData={setFormData}
                                        disabled={true}
                                    />
                                </ErrorBoundary>
                            </If>
                        </div>
                    </If>
                </If>
            </>
        );
    },
);

ActionPlayground.displayName = "ActionPlayground";
