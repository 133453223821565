import { QueryClientProvider } from "@tanstack/react-query";
import { AlertTriangleIcon } from "lucide-react";
import { AppType } from "next/app";
import Head from "next/head";
import Link from "next/link";
import Script from "next/script";
import posthog from "posthog-js";
import { PostHogProvider } from "posthog-js/react";
import { Suspense } from "react";
import { bootstrap } from "~/bootstap";
import { useAppInfo, useAppRouterAnalytics, useCliKey } from "~/dataProcessor/hooks/app";
import { Toaster } from "~/design-system/atom/toaster";
import { useTriggerDisabledBanner } from "~/hooks/query/triggers";
import { queryClient } from "~/queryClient";
import { mobileModeCSS } from "~/styles/emotions/global";
import "~/styles/globals.css";
import { FullScreenLoader } from "~/ui/components/base/FullScreenLoader";
import { ErrorBoundary } from "~/ui/components/base/errorBoundary";
import { MobileNotAvailableBanner } from "~/ui/components/base/not_available_on_mobile";
import { SSRWrapper, SidebarLayoutWrapper } from "~/ui/layout/base";
import { INTERCOM_SCRIPT } from "~/utils/scriptUtil";
import { setThemeHTMLFunction } from "~/utils/theme_utils";
import "../styles/github.css";
bootstrap();

const TriggerDisabledBanner = () => {
    const { showTriggerDisabledBanner } = useTriggerDisabledBanner();
    return (
        <div
            className={`relative w-full bg-yellow-100 border-b border-yellow-300 px-4 py-3 text-sm text-yellow-800 shadow-sm transition-all duration-300 ease-in-out ${showTriggerDisabledBanner ? "block" : "hidden"}`}
        >
            <div className="flex items-center justify-center">
                <AlertTriangleIcon className="w-4 h-4 mr-2" />
                <span>
                    Your triggers are currently disabled. To continue receiving events, please re-enable them
                    <Link href="/triggers" className="underline ml-1">
                        here
                    </Link>
                </span>
            </div>
        </div>
    );
};

const MyApp: AppType = ({ Component, pageProps }) => {
    const { isRootPath, isMobileMode, isSEOPath, isNonSidebarPage } = useAppInfo();
    useCliKey();
    useAppRouterAnalytics();

    return (
        <SSRWrapper isSEOPath={isSEOPath}>
            <Head>
                <CommonHead isRootPath={isRootPath} isSEOPath={isSEOPath} />
            </Head>
            {isMobileMode && !isSEOPath && (
                <div className={mobileModeCSS}>
                    <MobileNotAvailableBanner showLoader />
                </div>
            )}
            <ErrorBoundary fallback={<div>Error loading component</div>}>
                <Suspense fallback={<FullScreenLoader showLoader />}>
                    <PostHogProvider client={posthog}>
                        <QueryClientProvider client={queryClient}>
                            <div className="flex flex-col">
                                <TriggerDisabledBanner />
                                <div className="flex-1">
                                    <SidebarLayoutWrapper isNonSidebarPage={isNonSidebarPage}>
                                        <Component {...pageProps} />
                                    </SidebarLayoutWrapper>
                                    <div id="login-prompt"></div>
                                    <div id="modal-root"></div>
                                    <div id="portal-root"></div>
                                </div>
                            </div>
                            <Toaster />
                        </QueryClientProvider>
                        <AppLevelScripts />
                    </PostHogProvider>
                </Suspense>
            </ErrorBoundary>
        </SSRWrapper>
    );
};

const CommonHead = ({ isRootPath, isSEOPath }: { isRootPath: boolean; isSEOPath: boolean }) => (
    <>
        <title>Composio.dev</title>
        <meta name="theme-color" content="#000000" />
        <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />
        {!isRootPath && !isSEOPath && <meta name="robots" content="noindex" />}
        <script
            dangerouslySetInnerHTML={{
                __html: setThemeHTMLFunction,
            }}
        />
    </>
);

const AppLevelScripts = () => {
    const isDev = process.env.NODE_ENV === "development";
    if (isDev) {
        return null;
    }
    return (
        <>
            <Script
                id="intercom"
                dangerouslySetInnerHTML={{
                    __html: INTERCOM_SCRIPT,
                }}
            />
            <Script
                defer
                data-domain="composio.dev"
                data-api="https://pa.composio.dev/api/event"
                src="https://pa.composio.dev/js/script.tagged-events.js"
            />
            <Script src="https://www.googletagmanager.com/gtag/js?id=G-CWE0CCJVF2" crossOrigin="anonymous" />
        </>
    );
};

export default MyApp;
