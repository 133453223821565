import { useQueryClient } from "@tanstack/react-query";
import { useAtom } from "jotai";
import { useRouter } from "next/router";
import { useEffect, useMemo } from "react";
import { QUERY_KEYS } from "~/constants/keys";
import { useToast } from "~/design-system/atom/use-toast";
import { useGetAllIntegrations } from "~/hooks/query/tools";
import { useAppInfo } from "../../appScreen";
import { createConnector, createConnectorPayload, updateConnector } from "./api";
import { DEFAULT_CONNECTOR_SETUP_CONFIG, connectorSetupConfigAtom } from "./atom";

export const useSetupFormWizard = ({
    appKey,
    connectorId,
    initAuthSchemeType,
    initAuthConfig,
    afterConnectorBEChange,
    defaultConnectorId = "",
    viaSettingsPage = false,
}: {
    appKey: string;
    connectorId?: string;
    initAuthSchemeType?: string;
    initAuthConfig?: Record<string, any>;
    afterConnectorBEChange?: (status: { integrationId: string | undefined | null; status: string }) => void;
    defaultConnectorId?: string | null;
    viaSettingsPage?: boolean;
}) => {
    // Hooks and utilities
    const queryClient = useQueryClient();
    const toast = useToast();
    const { query } = useRouter();
    const [newFormState, setNewFormState] = useAtom(connectorSetupConfigAtom);
    const { isUpdating } = newFormState;
    // Helper functions for state updates
    const setLoading = (isUpdating: boolean) => setNewFormState((prev) => ({ ...prev, isUpdating }));

    // Fetch app integration data
    const { appInfo, isAppInfoLoading, isError } = useAppInfo(appKey);

    // Extract relevant data from the query result
    const schemes = useMemo(() => appInfo?.auth_schemes?.map((schema: any) => schema.auth_mode) || [], [appInfo]);

    const formFields = useMemo(
        () => appInfo?.auth_schemes?.find((e: any) => e.auth_mode === newFormState.selectedAuthScheme)?.fields,
        [appInfo, newFormState.selectedAuthScheme],
    );
    const testConnectors = useMemo(() => appInfo?.testConnectors || [], [appInfo]);

    const isComposioAuthAvailable = useMemo(() => {
        return testConnectors?.some((item: any) => item.authScheme === newFormState.selectedAuthScheme);
    }, [testConnectors, newFormState.selectedAuthScheme]);

    const isExistingConnector = useMemo(() => {
        return !!connectorId;
    }, [connectorId]);

    // Initialize form state
    useEffect(() => {
        if (isAppInfoLoading) return;
        setNewFormState((prev) => ({
            ...prev,
            selectedAuthScheme: initAuthSchemeType ?? prev.selectedAuthScheme ?? schemes?.[0] ?? "",
            usingComposioAuth: defaultConnectorId === null ? false : isComposioAuthAvailable,
        }));
    }, [appInfo, isComposioAuthAvailable]);

    // Form submission handler
    const updateForm = async () => {
        // Validate form fields
        let hasError = false;

        const newFormValues = Object.entries(newFormState.connectorConfiguration).reduce(
            (acc, [key, field]) => {
                if (field.required && !field.value && !field.expected_from_customer && !newFormState.usingComposioAuth) {
                    acc[key] = { ...field, error: "This field is required" };
                    hasError = true;
                } else {
                    acc[key] = field;
                }

                return acc;
            },
            { ...newFormState.connectorConfiguration },
        );

        setNewFormState((prev) => ({ ...prev, connectorConfiguration: newFormValues }));

        if (hasError) {
            toast.toast({
                title: "Invalid input",
                description: "Please enter a valid input",
                variant: "destructive",
            });
            return;
        }

        // Create payload
        let payload;
        try {
            payload = createConnectorPayload({
                appId: appInfo?.appId || "",
                authSchemeType: newFormState.selectedAuthScheme || "",
                authConfig: newFormState.connectorConfiguration,
                name: newFormState.name,
                useComposioAuth: newFormState.usingComposioAuth,
                forceNewIntegration: true,
            });
        } catch (e: any) {
            toast.toast({
                variant: "destructive",
                title: "Error",
                description: e.message,
            });
            return;
        }

        // Handle form submission based on whether it's a new or existing connector
        if (!isExistingConnector) {
            await handleNewConnector(payload);
        } else {
            await handleExistingConnector(payload);
        }
    };

    // Handler for creating a new connector
    const handleNewConnector = async (payload: any) => {
        if (!payload.name) {
            toast.toast({
                title: "Invalid name",
                description: "Please enter a valid name",
                variant: "destructive",
            });
            setLoading(false);
            return;
        }

        try {
            setLoading(true);
            const responseData = await createConnector(appKey, payload);
            afterConnectorBEChange?.({
                integrationId: responseData.id,
                status: "success",
            });
            queryClient.invalidateQueries(QUERY_KEYS.getIntegrationQueryKey(query?.appId as string));
        } catch (e: any) {
            setLoading(false);
            const errorMessage = e?.response?.data?.message || "Error creating integration";

            toast.toast({
                title: errorMessage,
                description:
                    errorMessage === "Integration already exists"
                        ? "You can create only one integration with composio creds. Create new integration with your credentials."
                        : e?.message,
                variant: "destructive",
            });
            afterConnectorBEChange?.({
                integrationId: undefined,
                status: "error",
            });
        } finally {
            setLoading(false);
        }
    };

    // Handler for updating an existing connector
    const handleExistingConnector = async (payload: any) => {
        const scopeConfig = payload?.authConfig?.scopes || [];
        const userScopeConfig = payload?.authConfig?.user_scopes || [];
        const clientId = payload?.authConfig?.client_id || "";
        const clientSecret = payload?.authConfig?.client_secret || "";
        const redirectUri = payload?.authConfig?.redirect_uri || "";

        if (!scopeConfig) {
            toast.toast({
                title: "Only scope are allowed to be changed.",
                variant: "destructive",
            });
            return;
        }

        try {
            setLoading(true);
            await updateConnector(connectorId!, {
                authConfig: {
                    scopes: scopeConfig,
                    user_scopes: userScopeConfig,
                    ...(clientId && { client_id: clientId }),
                    ...(clientSecret && { client_secret: clientSecret }),
                    ...(redirectUri && { redirect_uri: redirectUri }),
                },
            });
            toast.toast({
                title: `Updated the connector settings`,
                variant: "success",
            });
            afterConnectorBEChange?.({
                integrationId: connectorId,
                status: "success",
            });
        } catch (e: any) {
            toast.toast({
                title: "Error updating connector",
                description: e?.message,
                variant: "destructive",
            });
            afterConnectorBEChange?.({
                integrationId: connectorId,
                status: "error",
            });
        } finally {
            setLoading(false);
        }
    };

    const { data: allIntegrations } = useGetAllIntegrations(1, appInfo?.name);

    const integrationCount = useMemo(
        // @ts-expect-error
        () => (allIntegrations?.totalPages === 1 ? allIntegrations?.items?.length : allIntegrations?.totalPages * 10),
        [allIntegrations],
    );

    // Update form fields when auth scheme or Composio auth status changes
    useEffect(() => {
        // @ts-ignore
        const newFormValues = formFields?.reduce(
            (acc: any, field: any) => ({
                ...acc,
                [field.name]: {
                    value: initAuthConfig?.[field.name] || field.default || "",
                    required: field.required || false,
                    expected_from_customer: field.expected_from_customer || false,
                    choices: field.choices || [],
                },
            }),
            {},
        );

        if (newFormState.usingComposioAuth && newFormValues) {
            for (const key of Object.keys(newFormValues)) {
                if (key !== "scopes" && key !== "user_scopes") {
                    newFormValues[key] = {
                        value: "*************",
                        required: false,
                        expected_from_customer: false,
                    };
                }
            }
        }
        setNewFormState((prev) => ({
            ...prev,
            connectorConfiguration: newFormValues || prev.connectorConfiguration,
            ...(!isExistingConnector && {
                name: prev.name || `${appKey}_${(integrationCount || 0) + 1}`,
            }),
        }));
    }, [newFormState.selectedAuthScheme, newFormState.usingComposioAuth, appInfo]);

    // Helper functions for updating specific form state
    const setAuthSchemeType = (authSchemeType: string) => {
        setNewFormState((prev) => {
            return {
                ...prev,
                selectedAuthScheme: authSchemeType,
            };
        });
    };

    const setUseComposioAuth = (useComposioAuth: boolean) => {
        setNewFormState((prev) => ({ ...prev, usingComposioAuth: useComposioAuth }));
    };

    useEffect(() => {
        return () => {
            setNewFormState(DEFAULT_CONNECTOR_SETUP_CONFIG);
        };
    }, []);

    // Return object with all necessary data and functions
    return {
        connectorData: appInfo,
        updateInBackend: updateForm,
        setupConfig: {
            composioAuth: {
                value: newFormState.usingComposioAuth,
                setValue: setUseComposioAuth,
            },
            authSchemeType: {
                value: newFormState.selectedAuthScheme,
                setValue: setAuthSchemeType,
            },
            formState: {
                value: newFormState,
                setValue: setNewFormState,
            },
            formFields,
            schemes,
            isComposioAuthAvailable,
            isExistingConnector,
            state: {
                isUpdating,
                isLoading: isAppInfoLoading,
                isError,
            },
        },
    };
};
