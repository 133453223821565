import { motion } from "framer-motion";
import { ChevronLeft, Github, Mail } from "lucide-react";
import { useRouter } from "next/router";
import React, { useEffect, useRef } from "react";
import { getCurrentClientSideHost } from "~/utils/global";
import GoogleLogin from "./components/GoogleLogin";

const noop = () => {};

const isEmailValid = (email: string) => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
};

export function LoginComponent({
    email,
    isSubmitting,
    onEmailChange,
    handleGetMagicLink,
    showVerificationScreen,
    setShowVerificationScreen,
}: {
    email: string;
    isSubmitting: boolean;
    onEmailChange: (email: string) => void;

    //input element event
    handleGetMagicLink: (e: React.SyntheticEvent) => void;
    showVerificationScreen: boolean;
    setShowVerificationScreen: (showVerificationScreen: boolean) => void;
}) {
    const inputRef = useRef<HTMLInputElement>(null);

    const router = useRouter();
    // on mount, focus on the input element
    useEffect(() => {
        if (inputRef.current) inputRef.current.focus();
    }, []);

    return (
        <motion.div
            transition={{
                duration: 0.4,
            }}
            animate={{
                opacity: 1,
            }}
            initial={{
                opacity: 0,
            }}
            className="absolute relative left-[50%] top-[50%] z-20 flex h-[600px] w-[900px] max-w-[96%] translate-x-[-50%] translate-y-[-55%]  flex-col overflow-hidden"
        >
            <div className="flex h-full">
                {/* New Login Screen */}
                <div className="flex-1 flex items-center justify-center p-4 relative">
                    <div className="w-full max-w-sm  backdrop-blur-sm bg-white/80 p-8 rounded-2xl shadow-xl ">
                        <div className="text-center">
                            <p className="text-xl text-[#000000] font-gilroy font-[500]">Sign in to continue</p>
                        </div>

                        {showVerificationScreen ? (
                            <div className="mt-10 flex w-full flex-col items-center">
                                <div className="text-[17px] font-semibold text-gray-900">Verify your email</div>
                                <div className="mt-3 text-center text-[14px] leading-[170%] text-gray-600">
                                    We have sent a magic link to your email address. Please open it to continue.
                                </div>
                                <button
                                    onClick={() => setShowVerificationScreen(false)}
                                    className="mt-6 flex cursor-pointer items-center gap-1 text-[13px] font-medium text-gray-600 hover:text-gray-900"
                                >
                                    <ChevronLeft className="h-4 w-4" />
                                    Go back
                                </button>
                            </div>
                        ) : (
                            <div className="space-y-4 mt-6">
                                <button
                                    className="font-gilroy font-[600] flex items-center justify-center gap-2 bg-[#24292F] text-white w-full py-3 rounded-lg hover:bg-[#24292F]/90 transition-colors text-[14px]"
                                    onClick={() => {
                                        const currentHost = getCurrentClientSideHost();
                                        const url = `${currentHost}/provider_login/github`;

                                        router.push(
                                            `${process.env.NEXT_PUBLIC_API_URL}/api/v1/login/github/auth?redirectUri=${encodeURIComponent(url)}`,
                                        );
                                    }}
                                >
                                    <Github className="w-5 h-5" />
                                    Continue with Github
                                </button>

                                <GoogleLogin />

                                <div className="relative">
                                    <div className="absolute inset-0 flex items-center">
                                        <div className="w-full border-t border-gray-300"></div>
                                    </div>
                                    <div className="relative flex justify-center text-sm">
                                        <span className="px-2 bg-white text-gray-500">or</span>
                                    </div>
                                </div>

                                <div className="space-y-4">
                                    <div className="relative">
                                        <input
                                            type="email"
                                            value={email}
                                            ref={inputRef}
                                            onChange={(e) => onEmailChange(e.target.value)}
                                            onKeyDown={(e) => {
                                                if (e.key === "Enter" || e.key === "ENTER") {
                                                    handleGetMagicLink(e);
                                                }
                                            }}
                                            placeholder="name@company.com"
                                            className="font-[14px] font-gilroy font-[500] w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-purple-600 focus:border-transparent outline-none"
                                        />
                                        <Mail className="absolute right-3 top-1/2 -translate-y-1/2 w-4 h-4 text-gray-400" />
                                    </div>

                                    <button
                                        className={`font-gilroy font-[600] w-full py-3 rounded-lg transition-colors text-[14px] flex justify-center gap-2 align-center ${!isEmailValid(email) ? "bg-purple-400 cursor-not-allowed" : "bg-purple-600 hover:bg-purple-700"} text-white`}
                                        onClick={handleGetMagicLink}
                                        disabled={!isEmailValid(email) || isSubmitting}
                                    >
                                        {isSubmitting ? (
                                            <>
                                                <svg xmlns="http://www.w3.org/2000/svg" className="w-5 h-5" viewBox="0 0 24 24">
                                                    <path
                                                        fill="currentColor"
                                                        d="M12,4a8,8,0,0,1,7.89,6.7A1.53,1.53,0,0,0,21.38,12h0a1.5,1.5,0,0,0,1.48-1.75,11,11,0,0,0-21.72,0A1.5,1.5,0,0,0,2.62,12h0a1.53,1.53,0,0,0,1.49-1.3A8,8,0,0,1,12,4Z"
                                                    >
                                                        <animateTransform
                                                            attributeName="transform"
                                                            dur="0.75s"
                                                            repeatCount="indefinite"
                                                            type="rotate"
                                                            values="0 12 12;360 12 12"
                                                        />
                                                    </path>
                                                </svg>
                                            </>
                                        ) : (
                                            <>
                                                <Mail className="w-5 h-5 text-white" />
                                                Continue with email
                                            </>
                                        )}
                                    </button>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </motion.div>
    );
}
