import * as Sentry from "@sentry/react";
import axios from "axios";
import { client } from "~/client";
import { eraseCookie } from "~/utils/cookie";
import { createCustomError } from "~/utils/error";

export const axiosInstance = axios.create();

axiosInstance.defaults.withCredentials = true;
// Add a response interceptor
axiosInstance.interceptors.response.use(function (response) {
    return response;
}, errorHandler);

client.setConfig({
    throwOnError: true,
});
client.instance.defaults.withCredentials = true;

client.instance.interceptors.response.use((response) => {
    return response;
}, errorHandler);

function errorHandler(error: any) {
    if (error?.response?.status > 400) {
        Sentry.captureException(
            createCustomError("API Handling", "🚨 " + " URL: " + error.config.url + " Status: " + error.response.status, error),
            {
                tags: {
                    request_id: error?.response?.headers["x-request-id"],
                    backend_api_failing: error?.config?.url,
                    label: "backend_api_error",
                    status: error.response.status,
                    statusText: error.response.statusText,
                },
            },
        );
    }

    // Get the URL from the error response config
    const apiUrl = error?.response?.config?.url;
    // @TODO: Future: Change this to /api/v1/client_info after it's returning 401
    // Check if this is a metadata request by looking for /api/v1/metadata in the URL
    const isClientInfoRequest = apiUrl?.endsWith("/api/v1/metadata") || apiUrl?.endsWith("/api/v1/metadata/");

    // Check if we're currently on the homepage
    const isHomePage = window.location.pathname === "/";

    const isUnauthorized = error?.response?.status === 401;
    // If we get a 401 unauthorized error on a metadata request and we're not on homepage
    // This is safety check.
    if (isUnauthorized && isClientInfoRequest && !isHomePage) {
        console.error("Unauthorized access detected, redirecting to homepage", { url: apiUrl, isHomePage, isClientInfoRequest });
        // Redirect user to homepage/login page
        window.location.href = `/`;
        // Clear all authentication cookies
        eraseCookie("isLoggedIn");
        eraseCookie("skipOnboarding");
        eraseCookie("composio_email");
    }

    throw error;
}
