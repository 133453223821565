import { css } from "@emotion/css";
import axios from "axios";
import clsx from "clsx";
// @ts-ignore
import { cloneDeep } from "lodash";
import { CodeIcon, CopyIcon, PlusIcon } from "lucide-react";
import { useRouter } from "next/router";
import { useEffect, useMemo, useState } from "react";
import { ZIntegrationItemSchema } from "~/dataProcessor/api/api";
import { useApiKey } from "~/dataProcessor/hooks/user";
import { Button } from "~/design-system/atom/newButton";
import { Switch } from "~/design-system/atom/switch";
import { ToolTipBox } from "~/design-system/atom/tooltip";
import { useToast } from "~/design-system/atom/use-toast";
import { useGetTriggerData, useGetTriggerInstanceData } from "~/hooks/query/triggers";
import { useGetConnectorInfo } from "~/pages/connector/[connectorId]";
import { ErrorBoundary } from "~/ui/components/base/errorBoundary";
import { LoadingState } from "~/ui/containers/base/emptyStates";
import { CodeWInstructionModal } from "~/ui/containers/codeModal";
import { LogsRenderer } from "~/ui/screens/action_logs/logs";
import { ActionRenderer } from "~/ui/screens/actions";
import { SetupFormWizard } from "~/ui/screens/appScreen/modules/createIntegration/CreateIntegrationPopover";
import { TriggerRenderer } from "~/ui/screens/triggers";
import { BACKEND_URL } from "~/utils/baseUrl";
import { copyText } from "~/utils/dom";
import { TabBarStrip } from "../../components/custom/TabBarStrip";
import { RightSideContent } from "../../containers/base/base";
import { AddAccountButton } from "../../containers/common/AddAccountButton";
import { ConnectedAccountsRenderer } from "../connectedAccountList";
import { DeleteConnectorAction } from "../integrations/index";
import { TriggerInstanceRenderer } from "../triggers/triggerPage";

export const setIsActiveStatus = (connectorId: string, enabled: boolean) => {
    return axios
        .post(
            `${BACKEND_URL}/api/v1/integrations/${connectorId}/status`,
            {
                enabled,
            },
            {
                withCredentials: true,
            },
        )
        .then((res) => res.data);
};

export default function IntegrationPage() {
    const [isCopied, setIsCopied] = useState(false);
    const { query } = useRouter();

    const { data: connectorInfo } = useGetConnectorInfo(query.connectorId as string);

    const [sections, setSections] = useState<string[]>([
        "connected accounts",
        "actions",
        "triggers",
        "trigger instances",
        "logs",
        "settings",
    ]);
    const { triggerData, isLoading: isTriggerLoading } = useGetTriggerData(connectorInfo?.appName!);
    const { triggerData: triggerInstanceData, isLoading: isTriggerInstanceLoading } = useGetTriggerInstanceData();

    useEffect(() => {
        if (!isTriggerLoading && !triggerData?.length) {
            setSections((prev) => prev.filter((section) => section !== "triggers"));
        }

        if (!isTriggerInstanceLoading && !triggerInstanceData?.triggers?.length) {
            setSections((prev) => prev.filter((section) => section !== "trigger instances"));
        }
    }, [isTriggerLoading, isTriggerInstanceLoading]);

    const toast = useToast();

    const [isChecked, setIsChecked] = useState<boolean>(connectorInfo?.enabled || false);

    useEffect(() => {
        setIsChecked(connectorInfo?.enabled || false);
    }, [connectorInfo]);

    const [selectedSection, setSelectedSection] = useState(sections[0]);

    const isLoading = !connectorInfo || isTriggerLoading || isTriggerInstanceLoading;

    return (
        <RightSideContent
            header={
                !!connectorInfo
                    ? `${connectorInfo?.appName?.charAt(0)?.toUpperCase() + connectorInfo?.appName?.slice(1)} live tool`
                    : "Fetching live tool"
            }
        >
            {isLoading ? (
                <div
                    className={css`
                        height: calc(100vh - 200px);
                    `}
                >
                    <LoadingState height={window.innerHeight - 200} />
                </div>
            ) : (
                connectorInfo && (
                    <div className="h-full px-8 pt-7">
                        <div className="flex w-full items-center justify-between">
                            <div className="flex items-center gap-3 ">
                                <div
                                    className={clsx(
                                        "flex h-9 w-9 items-center justify-center",
                                        css`
                                            background-image: url("${connectorInfo?.logo}");

                                            background-position: center;
                                            background-repeat: no-repeat;
                                            background-size: cover;
                                        `,
                                    )}
                                ></div>
                                <div className="ml-2">
                                    <div className="mb-1 font-avenirn text-[16px] font-[600]  text-black-400">{connectorInfo?.name}</div>
                                    <div className="flex gap-1  text-black-900">
                                        {" "}
                                        <span className="text-[12px] font-[400] ">{connectorInfo?.id}</span>{" "}
                                        <ToolTipBox
                                            side="right"
                                            align="center"
                                            content={isCopied ? "✅ Copied!" : "Copy ID"}
                                            onOpenChange={(e: boolean) => {
                                                if (e === false) {
                                                    setIsCopied(false);
                                                }
                                            }}
                                        >
                                            <CopyIcon
                                                height={12}
                                                width={12}
                                                className="ml-1 mt-[2px] cursor-pointer"
                                                onClick={() => {
                                                    copyText(connectorInfo?.id || "");
                                                    setIsCopied(true);
                                                }}
                                            />
                                        </ToolTipBox>
                                    </div>
                                </div>
                            </div>
                            <div
                                className="flex items-center gap-3"
                                onClick={(e) => {
                                    e.stopPropagation();
                                }}
                            >
                                <ShowIntegrationCode integrationId={connectorInfo?.id!} />
                                {!!connectorInfo ? (
                                    <AddAccountButton connectorData={ZIntegrationItemSchema.parse(connectorInfo)} isMountedAtTop={true} />
                                ) : null}
                                <DeleteConnectorAction connectorId={connectorInfo?.id! as unknown as string} />
                                <Switch
                                    checked={isChecked}
                                    id="airplane-mode"
                                    onCheckedChange={(e) => {
                                        setIsChecked(e);
                                        setIsActiveStatus(query.connectorId as string, e)
                                            .then(() => {
                                                toast.toast({
                                                    title: "Status updated",
                                                    description: `Connector ${e ? "enabled" : "disabled"}`,
                                                });
                                            })
                                            .catch(() => {
                                                setIsChecked(!e);
                                                toast.toast({
                                                    title: "Error",
                                                    description: `Error in updating status`,
                                                    variant: "destructive",
                                                });
                                            });
                                    }}
                                />
                            </div>
                        </div>
                        <div className="mt-8 flex w-full items-center font-avenirn text-[16px] font-[600] tracking-[.35px] text-[#e0e0e0]">
                            <TabBarStrip sections={sections} selectedSection={selectedSection!} setSelectedSection={setSelectedSection} />
                        </div>
                        {selectedSection === "connected accounts" && (
                            <div className={clsx(" pt-2", bottomTabElementCSS)}>
                                <ErrorBoundary fallback={<div>{`Something went wrong. We'll work on getting this fixed.`}</div>}>
                                    <ConnectedAccountsRenderer />
                                </ErrorBoundary>
                            </div>
                        )}
                        {selectedSection === "actions" && (
                            <div className={clsx(" pt-", bottomTabElementCSS)}>
                                <ErrorBoundary fallback={<div>{`Something went wrong. We'll work on getting this fixed.`}</div>}>
                                    <ActionRenderer />
                                </ErrorBoundary>
                            </div>
                        )}
                        {selectedSection === "triggers" && (
                            <div className={clsx("pt-2", bottomTabElementCSS)}>
                                <ErrorBoundary fallback={<div>{`Something went wrong. We'll work on getting this fixed.`}</div>}>
                                    <TriggerRenderer />
                                </ErrorBoundary>
                            </div>
                        )}

                        {selectedSection === "trigger instances" && (
                            <div className={clsx("pt-2", bottomTabElementCSS)}>
                                <ErrorBoundary fallback={<div>{`Something went wrong. We'll work on getting this fixed.`}</div>}>
                                    <TriggerInstanceRenderer />
                                </ErrorBoundary>
                            </div>
                        )}
                        {selectedSection === "logs" && (
                            <ErrorBoundary fallback={<div>{`Something went wrong. We'll work on getting this fixed.`}</div>}>
                                <LogsRenderer />
                            </ErrorBoundary>
                        )}
                        {selectedSection === "settings" && (
                            <div className="ml-2 mt-6 max-w-[550px]">
                                <ErrorBoundary fallback={<div>{`Something went wrong. We'll work on getting this fixed.`}</div>}>
                                    <SetupFormWizard
                                        appKey={connectorInfo?.appName! || ""}
                                        initAuthConfig={connectorInfo?.authConfig || {}}
                                        connectorId={connectorInfo?.id}
                                        initAuthSchemeType={connectorInfo?.authScheme}
                                        defaultConnectorId={connectorInfo?.defaultConnectorId}
                                        viaSettingsPage={true}
                                    />
                                </ErrorBoundary>
                            </div>
                        )}
                    </div>
                )
            )}
        </RightSideContent>
    );
}

const bottomTabElementCSS = css`
    .minHeightWrapper {
        height: calc(100vh - 400px);
    }
`;

export const sampleCodeBlockData = {
    content: {
        python: [
            {
                type: "blockWizard",
                content: {
                    heading: "Code for creating a connection for a user",
                    blocks: [
                        {
                            heading: "Get integration",
                            text: `from composio import ComposioToolSet, App

# Example usage
toolset = ComposioToolSet(api_key={{api_key}})
integration_id = {{integration_id}}
integration = toolset.get_integration(id=integration_id)
print(integration) # Integration object
`,
                            language: "python",
                        },
                    ],
                },
            },
        ],
        javascript: [
            {
                type: "blockWizard",
                content: {
                    heading: "Get integration",
                    blocks: [
                        {
                            heading: "Get integration",
                            text: `import {Composio} from "composio-core";
// get composio client
const composio = new Composio({apiKey: {{api_key}}});

const integration = await composio.integrations.get({
    integrationId: {{integration_id}}
})
`,
                            language: "javascript",
                        },
                    ],
                },
            },
        ],
    },
};

const ShowIntegrationCode = ({ integrationId }: { integrationId: string }) => {
    const [showCode, setShowCode] = useState(false);

    const apiKey = useApiKey();

    const transformedData = useMemo(() => {
        // transform data of sampleCodeBlockData
        const data = cloneDeep(sampleCodeBlockData);

        for (const language of Object.keys(data.content)) {
            // @ts-ignore
            for (const languaeItem of data.content[language]) {
                //replace blocks with api key and integration id
                const { blocks } = languaeItem.content;

                if (!blocks) continue;

                for (const block of blocks) {
                    block.text = block.text.replace("{{api_key}}", `"${apiKey}"`).replace("{{integration_id}}", `"${integrationId}"`);
                }
            }
        }

        return data;
    }, [apiKey, integrationId]);

    return (
        <div>
            <CodeButton setShowCode={setShowCode} />
            {showCode && (
                <CodeWInstructionModal title="Get integration with Our SDKs" data={transformedData} setOpen={() => setShowCode(false)} />
            )}
        </div>
    );
};

export const CodeButton = ({
    setShowCode,
    text = "Code",
    variant = "white",
}: {
    setShowCode: (value: boolean) => void;
    text?: string;
    variant?: "white" | "black";
}) => {
    return (
        <Button
            size={32}
            variant={variant}
            onClick={() => {
                setShowCode(true);
            }}
            className="text-black gap-1 !px-[10px]"
        >
            {text.includes("Add") ? (
                <PlusIcon height={16} width={16} strokeWidth={2.2} color={variant === "black" ? "#fff" : "#BA24FF"} />
            ) : (
                <CodeIcon height={16} width={16} strokeWidth={2.2} color={variant === "black" ? "#fff" : "#BA24FF"} />
            )}
            <span className="ml-1 mt-[2px] text-[13px] font-[500]">{text}</span>
        </Button>
    );
};
